import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist({ key: "state" });
const showsScheduleAtom = atom({
  key: "showsSchedule",
  default: [],
});
const listShowAtom = atom({
  key: "listShow",
  default: [],
});
const venueListAtom = atom({
  key: "venueList",
  default: [],
});

const zoneListAtom = atom({
  key: "zoneList",
  default: [],
});

const listUnitAtom = atom({
  key: "listUnit",
  default: [],
});
const listUnitForAddingAtom = atom({
  key: "listUnitForAdding",
  default: [],
});
const listChannelAtom = atom({
  key: "listChannel",
  default: [],
});
const toolTypesAtom = atom({
  key: "toolTypes",
  default: [],
});
const selectedShowAtom = atom({
  key: "selectedShow",
  default: {},
  effects_UNSTABLE: [persistAtom],
});
const locationListAtom = atom({
  key: "locationList",
  default: [],
});
const showsScheduleListAtom = atom({
  key: "showsScheduleList",
  default: [],
});
const showTimeListAtom = atom({
  key: "showTimeList",
  default: [],
});
const paymentMethodListAtom = atom({
  key: "paymentMethodList",
  default: [],
});
const currencyListAtom = atom({
  key: "currencyList",
  default: [],
});
const paymentStatusListAtom = atom({
  key: "paymentStatusList",
  default: [],
});
const timeToReleaseListAtom = atom({
  key: "timeToReleaseList",
  default: [],
});
const seatingPlanListAtom = atom({
  key: "seatingPlanList",
  default: [],
});
const assignSeatingPlanListAtom = atom({
  key: "assignSeatingPlanList",
  default: [],
});
const showsScheduleDetailAtom = atom({
  key: "assignShowsScheduleDetail",
  default: [],
});

const showsScheduleBookingDetailAtom = atom({
  key: "showsScheduleBookingDetail",
  default: [],
});
const listInvoiceAtom = atom({
  key: "listInvoice",
  default: [],
});
const ticketPriceListAtom = atom({
  key: "ticketPriceList",
  default: [],
});
const ticketPriceListByShowVenueAtom = atom({
  key: "ticketPriceListByShowVenue",
  default: [],
});
const monthCurrentCalendarAtom = atom({
  key: "monthCurrentCalendar",
  default: "",
})
export {
  monthCurrentCalendarAtom,
  showsScheduleAtom,
  listShowAtom,
  venueListAtom,
  listChannelAtom,
  listUnitAtom,
  listUnitForAddingAtom,
  selectedShowAtom,
  toolTypesAtom,
  zoneListAtom,
  locationListAtom,
  showsScheduleListAtom,
  showTimeListAtom,
  paymentMethodListAtom,
  currencyListAtom,
  paymentStatusListAtom,
  timeToReleaseListAtom,
  seatingPlanListAtom,
  assignSeatingPlanListAtom,
  showsScheduleDetailAtom,
  showsScheduleBookingDetailAtom,
  listInvoiceAtom,
  ticketPriceListAtom,
  ticketPriceListByShowVenueAtom,
};
