import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  useToast,
  Switch,
  Stack,
  Select,
  Grid,
  Tab,
  TabList,
  Tabs,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SearchTable2 from "components/Tables/SearchTable2";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { totalRow } from "state/roles-permissions";
import { AddIcon } from "@chakra-ui/icons";
import { checkForbidden } from "helpers/checkForbidden";
import { useCMSActions } from "hooks/useCMS";
import { faqGroupAtom, faqAtom } from "state/cms";
import { columnsDataFaq } from "variables/columnsData";
import Editor2 from "components/Editor/Editor2";

function DataTableFAQ() {
  const textColor = useColorModeValue("gray.700", "white");
  const listLocationWeb = useRecoilValue(faqAtom);
  const listFaqGroup = useRecoilValue(faqGroupAtom);
  const totalRows = useRecoilValue(totalRow);
  const cmsActions = useCMSActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCreate, setIsCreate] = useState(true);
  const [currentVenueWeb, setCurrentVenueWeb] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [newVenueWeb, setNewVenueWeb] = useState([]);

  const validationSchema = Yup.object().shape({
    question: Yup.string().required("This field is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    control,
    watch
  } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const toast = useToast();

  useEffect(() => {
    if (listLocationWeb) {
      const toolTypes = listLocationWeb.map((i) => ({
        ...i,
        typeSwitch: "faq",
      }));
      setNewVenueWeb(toolTypes);
    }
  }, [listLocationWeb]);
  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    await cmsActions.getListFaq(
      {
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
    setIsFetching(false);
  };
  useEffect(() => {
    cmsActions.getListFaqGroup();
  }, []);
  async function onSubmit({ question, answer, isActive, faq_group_id }) {
    const params = {
      question,
      answer,
      faq_group_id: parseInt(faq_group_id),
      status: isActive ? 1 : 2,
    };
    const listKeys = ["question", "answer"];
    const languages = listKeys
      .map((i) => {
        if (watch(`${i}_vn`)) {
          return {
            code: "vi",
            locale_key: i,
            locale_value: watch(`${i}_vn`),
          };
        } else {
          return undefined;
        }
      })
      .filter(Boolean);
    params.languages = languages;
    try {
      let res;
      if (isCreate) {
        res = await cmsActions.createFaq(params);
      } else {
        res = await cmsActions.updateFaq(params, currentVenueWeb);
      }

      if (res.status === 200) {
        onClose();
        reset();
        const message = isCreate ? "Create FAQ success" : "Update FAQ success";
        toast({
          description: message,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        await cmsActions.getListFaq(
          {},
          0,
          localStorage && localStorage.getItem("currentPageSizeStore")
        );
      }
    } catch (err) {
      console.warn(err);
      toast({
        question: err.response,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  async function editNews(newsID) {
    const newsData = await cmsActions.getDetailFaq(newsID);
    if (newsData) {
      const {
        question,
        status,
        faq_group_id,
        answer,
        id,
        languages,
      } = newsData.data.data;
      setValue("question", question);
      setValue("answer", answer);
      setValue("faq_group_id", faq_group_id);
      setValue("isActive", status === 1);
      setCurrentVenueWeb(id);
      if (languages) {
        languages.forEach((e) => {
          setValue(`${e.locale_key}_vn`, e.locale_value);
        });
      }
    }
    setIsCreate(false);
    onOpen();
  }

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex direction="column" display="block">
            <Text
              float="left"
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              mb="6px"
            >
              FAQ Management
            </Text>
            <Stack float="right" direction="row" spacing={4}>
              {checkForbidden(42) && (
                <Button
                  size="sm"
                  onClick={() => {
                    onOpen();
                    setIsCreate(true);
                  }}
                  leftIcon={<AddIcon />}
                  colorScheme="green"
                  variant="outline"
                >
                  Add FAQ
                </Button>
              )}
            </Stack>
            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpen}
              onClose={() => {
                onClose();
                reset();
              }}
              size="4xl"
            >
              <ModalOverlay />
              <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                  <ModalHeader>{isCreate ? "Add FAQ" : "Edit FAQ"}</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <Tabs isFitted variant="enclosed">
                      <TabList>
                        <Tab
                          _focus={{ outline: "none" }}
                          _selected={{ color: "white", bg: "blue.500" }}
                          fontWeight={"bold"}
                          fontSize={"18px"}
                        >
                          English
                        </Tab>
                        <Tab
                          _focus={{ outline: "none" }}
                          _selected={{ color: "white", bg: "blue.500" }}
                          fontWeight={"bold"}
                          fontSize={"18px"}
                        >
                          Viet Nam
                        </Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl isRequired>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                FAQ Group
                              </FormLabel>
                              <Select
                                ms="4px"
                                type="text"
                                defaultValue="0"
                                size="sm"
                                name="faq_group_id"
                                {...register("faq_group_id")}
                                width="300px"
                              >
                                {listFaqGroup
                                  .filter((item) => item.status === 1)
                                  .map((i) => (
                                    <option key={i.id} value={i.id}>
                                      {i.name}
                                    </option>
                                  ))}
                              </Select>
                            </FormControl>
                          </Grid>

                          <FormControl mt={3}>
                            <FormLabel
                              color={textColor}
                              fontWeight="bold"
                              fontSize="xs"
                            >
                              Question
                            </FormLabel>
                            <Editor2 name="question" control={control} />
                          </FormControl>
                          <FormControl mt={3}>
                            <FormLabel
                              color={textColor}
                              fontWeight="bold"
                              fontSize="xs"
                            >
                              Answer
                            </FormLabel>
                            <Editor2 name="answer" control={control} />
                          </FormControl>
                        </TabPanel>
                        <TabPanel>
                          <FormControl mt={3}>
                            <FormLabel
                              color={textColor}
                              fontWeight="bold"
                              fontSize="xs"
                            >
                              Question
                            </FormLabel>
                            <Editor2 name="question_vn" control={control} />
                          </FormControl>
                          <FormControl mt={3}>
                            <FormLabel
                              color={textColor}
                              fontWeight="bold"
                              fontSize="xs"
                            >
                              Answer
                            </FormLabel>
                            <Editor2 name="answer_vn" control={control} />
                          </FormControl>
                        </TabPanel>
                      </TabPanels>
                    </Tabs>

                    <FormControl>
                      <Switch
                        id="isChecked"
                        defaultChecked={isCreate && true}
                        name="isActive"
                        {...register("isActive")}
                        size="sm"
                        ms="4px"
                      >
                        Active
                      </Switch>
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      {isCreate ? "Create" : "Update"}
                    </Button>
                    <Button
                      onClick={() => {
                        onClose();
                        reset();
                      }}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </form>
            </Modal>
          </Flex>
        </CardHeader>
        <CardBody>
          <SearchTable2
            tableData={newVenueWeb}
            columnsData={columnsDataFaq}
            totalRow={totalRows.faq}
            edit={editNews}
            actionsActive={["edit"]}
            listPermissions={[43]}
            isFetching={isFetching}
            loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTableFAQ;
