import React from "react";
import ReactQuill, { Quill } from "react-quill";
import { Controller } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import "./editor.css";
import "highlight.js/styles/github.css"; // Import a Highlight.js theme
import hljs from "highlight.js"; // Import Highlight.js

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ size: [] }],
    [{ font: [] }],
    [{ script: "sub" }, { script: "super" }],
    [{ align: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    [{ color: [] }],
    ["clean"],
    ["code-block"],
  ],
  syntax: {
    highlight: (text) => hljs.highlightAuto(text).value,
  },
};
const Editor = ({ name, control, defaultValue = "" }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <ReactQuill
          theme="snow"
          modules={modules}
          value={field.value || ""}
          onChange={field.onChange}
        />
      )}
    />
  );
};

export default Editor;
