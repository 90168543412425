import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Switch,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useUserActions } from "hooks/useUserActions";
import { useRolePermissionActions } from "hooks/useRolePermissionActions";
import { useRatePlanActions } from "hooks/useRatePlanActions";
import { useVoucherActions } from "hooks/useVoucherActions";
import { useBookingActions } from "hooks/useBookingActions";
import { useCMSActions } from "hooks/useCMS";

function ConfirmSwitchMessage({
  row,
  getValue,
  isLocalUpdate = false,
  isCMS = false,
  ...rest
}) {
  const userAction = useUserActions();
  const toast = useToast();
  const rolePermissionActions = useRolePermissionActions();
  const ratePlanActions = useRatePlanActions();
  const voucherAction = useVoucherActions();
  const bookingActions = useBookingActions();
  const cmsActions = useCMSActions();

  const [localValue, setLocalValue] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    setLocalValue(row.original.status);
  }, [row]);
  const handleConfirm = () => {
    let passValue = "";
    if (localValue === 2) {
      setLocalValue(1);
      passValue = 1;
    } else {
      passValue = 2;
      setLocalValue(2);
    }
    if (!isLocalUpdate) {
      updateStatusRow(row, passValue);
    } else {
      getValue(passValue, row.original.rateplanID);
    }
    onClose();
  };
  const updateStatusRow = async (currentValue, target) => {
    const updateActions = {
      user: {
        updateStatus: userAction.updateUserStatus,
        getAll: () =>
          userAction.getAllUsers(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "User is active",
          inactive: "User is inactive",
        },
      },
      role: {
        updateStatus: rolePermissionActions.updateRoleStatus,
        getAll: () =>
          rolePermissionActions.getAllRoles(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Role is active",
          inactive: "Role is inactive",
        },
      },
      rateplan: {
        updateStatus: ratePlanActions.updateRatePlanStatus,
        getAll: () =>
          ratePlanActions.getAllRatePlans(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Rateplan is active",
          inactive: "Rateplan is inactive",
        },
      },
      voucher: {
        updateStatus: voucherAction.updateVoucherStatus,
        getAll: () =>
          voucherAction.getAllVouchers(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Voucher is active",
          inactive: "Voucher is inactive",
        },
      },
      voucherTemplate: {
        updateStatus: voucherAction.updateVoucherTemplateStatus,
        getAll: () =>
          voucherAction.getAllVouchersTemplate(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Voucher Template is active",
          inactive: "Voucher Template is inactive",
        },
      },
      rateplanUnit: {
        updateStatus: ratePlanActions.updateUnitsStatus,
        getAll: () =>
          ratePlanActions.getListRatePlansUnits(
            row.original.filters,
            row.original.rateplan_id,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "RateplanUnit is active",
          inactive: "RateplanUnit is inactive",
        },
      },
      rateplanAddUnit: {
        updateStatus: ratePlanActions.updateUnitsStatus,
        getAll: () => bookingActions.getUnitDetail(row.original.unitID),
        statusMessages: {
          active: "RateplanUnit is active",
          inactive: "RateplanUnit is inactive",
        },
      },
      unitUser: {
        updateStatus: userAction.updateUserUnitStatus,
        getAll: () =>
          userAction.getUnitsOfUser(
            row.original.filters,
            row.original.userID,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Unit User is active",
          inactive: "Unit User is inactive",
        },
      },
      channel: {
        updateStatus: bookingActions.updateChannelStatus,
        getAll: () =>
          bookingActions.getListChannel(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Channel is active",
          inactive: "Channel is inactive",
        },
      },
      location: {
        updateStatus: bookingActions.updateLocationStatus,
        getAll: () =>
          bookingActions.getListLocations(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Location is active",
          inactive: "Location is inactive",
        },
      },
      zone: {
        updateStatus: bookingActions.updateZoneStatus,
        getAll: () =>
          bookingActions.getListZones(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Zone is active",
          inactive: "Zone is inactive",
        },
      },
      show: {
        updateStatus: bookingActions.updateShowStatus,
        getAll: () =>
          bookingActions.getListShow(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Show is active",
          inactive: "Show is inactive",
        },
      },
      venue: {
        updateStatus: bookingActions.updateVenueStatus,
        getAll: () =>
          bookingActions.getListVenue(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Venue is active",
          inactive: "Venue is inactive",
        },
      },
      paymentStatus: {
        updateStatus: bookingActions.updateStatusOfPaymentStatus,
        getAll: () =>
          bookingActions.getListPaymentStatus(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Payment Status is active",
          inactive: "Payment Status is inactive",
        },
      },
      paymentMethod: {
        updateStatus: bookingActions.updatePaymentMethodStatus,
        getAll: () =>
          bookingActions.getListPaymentMethod(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Payment Method Status is active",
          inactive: "Payment Method Status is inactive",
        },
      },
      showTime: {
        updateStatus: bookingActions.updateShowTimeStatus,
        getAll: () =>
          bookingActions.getListShowTime(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Show Time Status is active",
          inactive: "Show Time Status is inactive",
        },
      },
      timeToRelease: {
        updateStatus: bookingActions.updateTimeToReleaseStatus,
        getAll: () =>
          bookingActions.getListTimeToRelease(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Time To Release Status is active",
          inactive: "Time To Release is inactive",
        },
      },
      toolType: {
        updateStatus: bookingActions.updateToolTypeStatus,
        getAll: () =>
          bookingActions.getListToolTypes(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Tool Type Status is active",
          inactive: "Tool Type Status is inactive",
        },
      },
      subAccount: {
        updateStatus: userAction.updateSubAccountStatus,
        getAll: () =>
          userAction.getListSubAccount(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Sub Account Status is active",
          inactive: "Sub Account Status is inactive",
        },
      },
      unit: {
        updateStatus: bookingActions.updateUnitStatus,
        getAll: () =>
          bookingActions.getListUnits(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Unit Status is active",
          inactive: "Unit Status is inactive",
        },
      },
      seatingPlan: {
        updateStatus: bookingActions.updateSeatingPlanStatus,
        getAll: () =>
          bookingActions.getListSeatingPlan(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Seating Plan Status is active",
          inactive: "Seating Plan Status is inactive",
        },
      },
      showsSchedule: {
        updateStatus: bookingActions.updateShowScheduleStatus,
        getAll: () =>
          bookingActions.getListShowSchedule(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Show Schedule Status is active",
          inactive: "Show Schedule Status is inactive",
        },
      },
      news: {
        updateStatus:
          target === 1 ? cmsActions.activeNews : cmsActions.inactiveNews,
        getAll: () =>
          cmsActions.getListNews(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "New Status is active",
          inactive: "New Status is inactive",
        },
      },
      newsGroup: {
        updateStatus:
          target === 1
            ? cmsActions.activeNewGroup
            : cmsActions.inactiveNewGroup,
        getAll: () =>
          cmsActions.getListNewsGroup(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "New Group Status is active",
          inactive: "New Group Status is inactive",
        },
      },
      menu: {
        updateStatus:
          target === 1 ? cmsActions.activeMenu : cmsActions.inactiveMenu,
        getAll: () =>
          cmsActions.getListMenu(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Menu status is active",
          inactive: "Menu Status is inactive",
        },
      },
      showWeb: {
        updateStatus:
          target === 1 ? cmsActions.activeShow : cmsActions.inactiveShow,
        getAll: () =>
          cmsActions.getListShow(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Show Status is active",
          inactive: "Show Status is inactive",
        },
      },
      recruitment: {
        updateStatus:
          target === 1
            ? cmsActions.activeRecruitment
            : cmsActions.inactiveRecruitment,
        getAll: () =>
          cmsActions.getListRecruitment(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Recruitment Status is active",
          inactive: "Recruitment Status is inactive",
        },
      },
      partner: {
        updateStatus:
          target === 1
            ? cmsActions.activePartners
            : cmsActions.inactivePartners,
        getAll: () =>
          cmsActions.getListPartners(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Partner Status is active",
          inactive: "Partner Status is inactive",
        },
      },
      venueWeb: {
        updateStatus:
          target === 1 ? cmsActions.activeVenues : cmsActions.inactiveVenues,
        getAll: () =>
          cmsActions.getListVenues(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Venue Web Status is active",
          inactive: "Venue Web Status is inactive",
        },
      },
      offers: {
        updateStatus:
          target === 1 ? cmsActions.activeOffers : cmsActions.inactiveOffers,
        getAll: () =>
          cmsActions.getListOffers(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Offer Status is active",
          inactive: "Offer Status is inactive",
        },
      },
      offersGroup: {
        updateStatus:
          target === 1
            ? cmsActions.activeOfferGroups
            : cmsActions.inactiveOfferGroups,
        getAll: () =>
          cmsActions.getListOfferGroups(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Offer Group Status is active",
          inactive: "Offer Group Status is inactive",
        },
      },
      locationWeb: {
        updateStatus:
          target === 1
            ? cmsActions.activeLocations
            : cmsActions.inactiveLocations,
        getAll: () =>
          cmsActions.getListLocations(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Location Status is active",
          inactive: "Location Status is inactive",
        },
      },

      faq: {
        updateStatus:
          target === 1 ? cmsActions.activeFaq : cmsActions.inactiveFaq,
        getAll: () =>
          cmsActions.getListFaq(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "FAQ Status is active",
          inactive: "FAQ Status is inactive",
        },
      },
      faqGroup: {
        updateStatus:
          target === 1
            ? cmsActions.activeFaqGroup
            : cmsActions.inactiveFaqGroup,
        getAll: () =>
          cmsActions.getListFaqGroup(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "FAQ Group Status is active",
          inactive: "FAQ Group Status is inactive",
        },
      },
      showReview: {
        updateStatus:
          target === 1
            ? cmsActions.activeShowReview
            : cmsActions.inactiveShowReview,
        getAll: () =>
          cmsActions.getListShowReview(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Show Review Status is active",
          inactive: "Show Review Status is inactive",
        },
      },
      showKeyFeature: {
        updateStatus:
          target === 1
            ? cmsActions.activeShowKeyReview
            : cmsActions.inactiveShowKeyReview,
        getAll: () =>
          cmsActions.getListShowReview(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Show Key Feature Status is active",
          inactive: "Show Key Feature Status is inactive",
        },
      },
      banner: {
        updateStatus:
          target === 1 ? cmsActions.activeBanner : cmsActions.inactiveBanner,
        getAll: () =>
          cmsActions.getListBanner(
            row.original.filters,
            0,
            localStorage && localStorage.getItem("currentPageSizeStore")
          ),
        statusMessages: {
          active: "Banner is active",
          inactive: "Banner is inactive",
        },
      },
    };
    const { updateStatus, getAll, statusMessages } = updateActions[
      row.original.typeSwitch
    ];
    try {
      const res = await updateStatus(
        target,
        currentValue.original.id,
        currentValue.original
      );
      if (res.status === 200) {
        toast({
          description:
            statusMessages[
              currentValue.original.status === 1 ? "inactive" : "active"
            ],
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        getAll();
      }
    } catch (err) {
      console.warn(err?.response);
      getAll();
      toast({
        description: err?.response?.data?.meta?.message,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
    }
  };
  const handleCancel = () => {
    onClose();
  };
  return (
    <>
      <Switch
        isChecked={localValue === 1 ? true : false}
        onChange={() => onOpen()}
        size="sm"
      />
      <Modal isOpen={isOpen} onClose={() => handleCancel()}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Action</ModalHeader>
          <ModalBody>Are you sure you want to perform this action?</ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={handleCancel}>
              Cancel
            </Button>
            <Button colorScheme="red" ml={3} onClick={() => handleConfirm()}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default ConfirmSwitchMessage;
