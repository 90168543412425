import { Flex, Heading, Text, Button } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

const NotFound = () => {
  const history = useHistory()
  return (
    <Flex
      align="center"
      justify="center"
      h="100vh"
      direction="column"
      color={"white"}
    >
      <Heading>403 - Forbidden</Heading>
      <Text>Sorry, you can not visit this page.</Text>
      <Button
        color={"black"}
        mt={4}
        onClick={() => history.push("/admin/setting/profile")}
      >
        Back to Dashboard
      </Button>
    </Flex>
  );
};

export default NotFound;