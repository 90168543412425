import { useRecoilState } from "recoil";
import { authAtom } from "state/recoil-root";
import instance from "helpers/instance";
import { totalRow } from "state/roles-permissions";
import { vouchersAtom, voucherCodesAtom, vouchersTemplateAtom } from "state/vouchers";

const useVoucherActions = () => {
  const [auth, setAuth] = useRecoilState(authAtom);
  const [vouchers, setVouchers] = useRecoilState(vouchersAtom);
  const [vouchersTemplate, setVouchersTemplate] = useRecoilState(vouchersTemplateAtom);

  const [voucherCodes, setVoucherCodes] = useRecoilState(voucherCodesAtom);
  const [totalRows, setTotalRows] = useRecoilState(totalRow);

  const headers = {
    Authorization: `Bearer ${auth?.access_token}`,
  };
  return {
    getAllVouchers,
    exportVoucherList,
    createVoucher,
    getVoucherDetail,
    getVoucherCodesByVoucherID,
    updateVoucher,
    getVoucherCodePDF,
    confirmPrintVoucherCode,
    updateVoucherStatus,
    getAllVouchersTemplate,
    getVoucherTemplateDetail,
    createVoucherTemplate,
    updateVoucherTemplateStatus
  };

  function getAllVouchers(filter, page = 0, pageSize = 10) {
    const offset = page * pageSize;
    const params = {
      ...filter,
      offset,
      limit: pageSize,
    };
    return instance({
      url: "voucher/v1/vouchers",
      method: "GET",
      params,
      headers,
    })
      .then((res) => {
        if (res.status === 200) {
          setVouchers(res.data.data ?? []);
          setTotalRows((preState) => ({
            ...preState,
            vouchers: res.data.meta.total,
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function getAllVouchersTemplate(filter, page = 0, pageSize = 10) {
    const offset = page * pageSize;
    const params = {
      ...filter,
      offset,
      limit: pageSize,
    };
    return instance({
      url: "voucher/v1/voucher-templates",
      method: "GET",
      params,
      headers,
    })
      .then((res) => {
        if (res.status === 200) {
          setVouchersTemplate(res.data.data ?? []);
          setTotalRows((preState) => ({
            ...preState,
            vouchersTemplate: res.data.meta.total,
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function exportVoucherList(filter, page = 0, pageSize = 10) {
    const offset = page * pageSize;
    const params = {
      ...filter,
      offset,
      limit: pageSize,
    };
    headers.responseType = "blob";
    return instance({
      url: "voucher/v1/vouchers/export",
      method: "GET",
      params,
      responseType: "blob",
      headers,
    });
  }
  function getVoucherDetail(voucherID) {
    return instance({
      url: `voucher/v1/vouchers/${voucherID}`,
      method: "GET",
      headers,
    });
  }
  function getVoucherTemplateDetail(voucherID) {
    return instance({
      url: `voucher/v1/voucher-templates/${voucherID}`,
      method: "GET",
      headers,
    });
  }
  function getVoucherCodePDF(voucherID, voucherCodeIDS) {
    headers.responseType = "blob";
    return instance({
      url: `voucher/v1/vouchers/${voucherID}/voucher-code-pdf?voucher_code_ids=${voucherCodeIDS}`,
      method: "GET",
      responseType: "blob",
      headers,
    });
  }

  function getVoucherCodesByVoucherID(page = 0, pageSize = 10, voucherID) {
    const offset = page * pageSize;
    const params = {
      offset,
      limit: pageSize,
    };
    return instance({
      url: `voucher/v1/vouchers/${voucherID}/voucher-codes`,
      method: "GET",
      params,
      headers,
    }).then((res) => {
      setVoucherCodes(res.data.data);
      setTotalRows((preS) => ({ ...preS, codesVoucher: res.data.meta.total }));
    });
  }
  function updateVoucher(data, voucherID) {
    return instance({
      url: `voucher/v1/vouchers/${voucherID}`,
      method: "PUT",
      data,
      headers,
    });
  }
  function createVoucherTemplate(data) {
    return instance({
      url: "voucher/v1/voucher-templates",
      method: "POST",
      data,
      headers,
    });
  }
  function createVoucher(data) {
    return instance({
      url: "voucher/v1/vouchers",
      method: "POST",
      data,
      headers,
    });
  }
  function confirmPrintVoucherCode(voucherID, voucherCodeIDS) {
    return instance({
      url: `voucher/v1/vouchers/${voucherID}/confirm-printed-voucher?voucher_code_ids=${voucherCodeIDS}`,
      method: "POST",
      headers,
    });
  }
  function updateVoucherStatus(status, voucherID) {
    return instance({
      url: `voucher/v1/vouchers/${voucherID}/status`,
      method: "PUT",
      headers,
      data: {
        status,
      },
    });
  }
  function updateVoucherTemplateStatus(status, voucherID) {
    return instance({
      url: `voucher/v1/voucher-templates/${voucherID}/status`,
      method: "PUT",
      headers,
      data: {
        status,
      },
    });
  }
};
export { useVoucherActions };
