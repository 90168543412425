import React, { useEffect } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authAtom } from "../state/recoil-root";
import routesArray from "routes";

function PrivateRoute({ component: Component, ...rest }) {
  const auth = useRecoilValue(authAtom);
  const location = useLocation();
  const routes = routesArray();
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName.path);
  };
  const mappingPathtoID = (path) => {
    const pathToId = {
      "/admin/booking/create": 6,
      "/admin/information": 6,
      "/admin/payment": 6,
      "/admin/booking/create/detail": 6,
      "/admin/payment": 6,
      "/admin/setting/user": 37,
      "/admin/setting/role": 41,
      "/admin/management/rate-plan": 31,
      "/admin/management/voucher": 27,
    };

    return pathToId[path] || -1;
  };
  const checkPermissions = (permissions) => {
    if (!permissions) {
      return false;
    }
    if (location.pathname === "/admin/authentication/403") {
      return true;
    }
    const pathId = mappingPathtoID(location.pathname);
    return pathId === -1 ? true : permissions.some((p) => p === pathId);
  };
  const isAuthorized = checkPermissions(auth?.permission);
  useEffect(() => {
    if (routes && routes.length > 0) {
      const filteredItems = routes
        .map((route) => route.items.filter((item) => activeRoute(item)))
        .find((items) => items.length > 0);
  
      if (filteredItems && filteredItems.length > 0) {
        const firstItem = filteredItems[0];
        document.title = firstItem.name || "Default Title";
      } else {
        // Handle the case when no matching items are found
        document.title = "Default Title";
      }
    }
  }, [location]);
  return (
    <Route
      exact
      {...rest}
      render={(props) => {
        if (!auth) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{
                pathname: "/auth/authentication/sign-in",
                state: { from: props.location },
              }}
            />
          );
        }
        if (isAuthorized) {
          return <Component {...props} />;
        } else {
          return props.history.push("/auth/unauthorized/403");
        }
      }}
    />
  );
}
export { PrivateRoute };
