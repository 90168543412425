import instance from "helpers/instance";
import { useRecoilState } from "recoil";
import { authAtom } from "state/auth";

export default function MyCustomUploadAdapterPlugin(editor, auth) {

  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader, auth);
  };
}

class MyUploadAdapter {
  constructor(loader, auth) {
    this.loader = loader;
    this.auth = auth
  }
   upload() {
    return new Promise(async (resolve, reject) => {
      const data = new FormData();
      const file = await this.loader.file; // Wait for the file promise to resolve

    //   console.log(this.loader, 'this.loader')
      data.append('file', file);

      instance({
        url: `media/v1/files`,
        method: "POST",
        data,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.auth?.access_token}`,

        },
      })
        .then((result) => {
          resolve({
            default: result.data.data.url,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  abort() {
    // Handle abort
  }
}
