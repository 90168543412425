import {
  Flex,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Stack,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { columnsDataVoucherCode } from "variables/columnsData";
import { totalRow } from "state/roles-permissions";
import { useVoucherActions } from "hooks/useVoucherActions";
import { voucherCodesAtom } from "state/vouchers";
import { useParams } from "react-router-dom";
import moment from "moment";
import { IoMdPrint } from "react-icons/io";
import SearchTable2 from "components/Tables/SearchTable2";

function DataTableVoucherCode() {
  const { voucher_id } = useParams();
  const voucherActions = useVoucherActions();
  const totalRows = useRecoilValue(totalRow);
  const listCodes = useRecoilValue(voucherCodesAtom);
  const [voucherCodeIDs, setVoucherCodeIDs] = useState([]);
  const [voucherDetail, setVoucherDetail] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [clearAllCheckBox, setClearAllCheckBox] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const SellVoucher = 2;
  const toast = useToast();

  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    await voucherActions.getVoucherCodesByVoucherID(
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize,
      voucher_id
    );
    const {
      data: { data },
    } = await voucherActions.getVoucherDetail(voucher_id);
    setVoucherDetail(data);
    setIsFetching(false);
  };
  const getValueChecked = (val) => {
    if (val) {
      const checkedIDs = Object.keys(val);
      setVoucherCodeIDs(checkedIDs);
    }
  };
  const showPDFPrintCode = async () => {
    const ids = voucherCodeIDs.map((i) => parseInt(i)).join(",");
    const response = await voucherActions.getVoucherCodePDF(voucher_id, ids);
    const url = URL.createObjectURL(response.data);
    setPdfUrl(url);
    onOpen();
  };
  const showAllPDFPrintCode = async () => {
    const response = await voucherActions.getVoucherCodePDF(voucher_id, "");
    const url = URL.createObjectURL(response.data);
    setPdfUrl(url);
    onOpen();
  };
  const confirmPrint = async () => {
    try {
      const ids = voucherCodeIDs.map((i) => parseInt(i)).join(",");
      const res = await voucherActions.confirmPrintVoucherCode(voucher_id, ids);
      if (res.status === 200) {
        setIsFetching(true);
        toast({
          description: `Print successful`,
          status: "success",
          position: "top-right",
          duration: 2000,
        });
        await voucherActions.getVoucherCodesByVoucherID(
          0,
          10,
          voucher_id
        );
        setIsFetching(false);
        onClose();
        setVoucherCodeIDs([]);
        setClearAllCheckBox(Math.random());
      }
    } catch (error) {
      toast({
        description: `${error?.response?.data?.meta?.message}`,
        status: "error",
        position: "top-right",
        duration: 2000,
      });
    }
  };

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px">
          <Flex direction="column">
            <Text float="left" fontSize="lg" fontWeight="bold" mb="6px">
              Voucher - View Code
            </Text>
            {voucherDetail && (
              <Flex mb="20px" gap="40px">
                <Flex direction="column" fontSize="small">
                  <Text fontSize="sm" fontWeight="bold">
                    VOUCHER NAME
                  </Text>
                  <Text>{voucherDetail?.name}</Text>
                </Flex>
                <Flex direction="column" fontSize="small">
                  <Text fontSize="sm" fontWeight="bold">
                    VOUCHER TYPE
                  </Text>
                  <Text>
                    {voucherDetail?.voucher_type == SellVoucher
                      ? "Sell Voucher"
                      : "Issue Voucher"}
                  </Text>
                </Flex>
                <Flex direction="column" fontSize="small">
                  <Text fontSize="sm" fontWeight="bold">
                    TOOL TYPE
                  </Text>
                  <Text>{voucherDetail?.ToolType.name}</Text>
                </Flex>

                {voucherDetail.voucher_type == SellVoucher && (
                  <Flex direction="column" fontSize="small">
                    <Text fontSize="sm" fontWeight="bold">
                      CUSTOMER NAME
                    </Text>
                    <Text>{voucherDetail?.customer_name}</Text>
                  </Flex>
                )}
                <Flex direction="column" fontSize="small">
                  <Text fontSize="sm" fontWeight="bold">
                    UNIT
                  </Text>
                  <Text>{voucherDetail?.Unit.name}</Text>
                </Flex>
                <Flex direction="column" fontSize="small">
                  <Text fontSize="sm" fontWeight="bold">
                    START - END DATE
                  </Text>
                  <Text>
                    {moment(voucherDetail?.from_date).format("DD/MM/YYYY")} -{" "}
                    {moment(voucherDetail?.to_date).format("DD/MM/YYYY")}
                  </Text>
                </Flex>
              </Flex>
            )}
            <Stack direction="row" spacing={4}>
              {voucherCodeIDs.length > 0 && (
                <Stack direction="row" spacing={4}>
                  <Button
                    size="sm"
                    onClick={() => {
                      showPDFPrintCode();
                    }}
                    variant="primary"
                    minW={"100px"}
                  >
                    Print
                  </Button>
                </Stack>
              )}
              <Button
                size="sm"
                onClick={() => {
                  showAllPDFPrintCode();
                }}
                variant="primary"
                minW={"100px"}
                leftIcon={<IoMdPrint />}
              >
                Print All
              </Button>
            </Stack>

            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpen}
              onClose={() => {
                onClose();
              }}
              size="6xl"
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Print Review</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  {pdfUrl && (
                    <iframe
                      title="PDF Viewer"
                      src={pdfUrl}
                      width="100%"
                      height="600px"
                    />
                  )}
                </ModalBody>
                <ModalFooter pt="0">
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => confirmPrint()}
                  >
                    Confirm Print
                  </Button>
                  <Button
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
        </CardHeader>
        <CardBody>
          {listCodes && (
            <SearchTable2
              tableData={listCodes}
              columnsData={columnsDataVoucherCode}
              totalRow={totalRows.codesVoucher}
              enableRowSelection
              enableRowActions={false}
              loadData={(fetchDataOptions) =>
                recallApi(fetchDataOptions)
              }
              updateCheckbox={(val) => getValueChecked(val)}
              isFetching={isFetching}
              clearAllCheckBox={clearAllCheckBox}
              enableRowNumbers
              conditionShowSelect={`(row) => row.original.status !== 1 || row.original.status !== 2 `}
            />
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTableVoucherCode;
