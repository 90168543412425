import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Stack,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  Spinner,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { checkForbidden } from "helpers/checkForbidden";
import { useBookingActions } from "hooks/useBookingActions";
import { Controller } from "react-hook-form";
function ReleaseTime() {
  const textColor = useColorModeValue("gray.700", "white");
  const [listReleaseTime, setListReleaseTime] = useState([]);
  const bookingActions = useBookingActions();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({});
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    control,
    watch,
  } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const toast = useToast();

  useEffect(async () => {
    setIsLoading(true);
    const {
      data: { data },
    } = await bookingActions.getListReleaseTime();
    setListReleaseTime(data);
    if (data) {
      data.forEach((i) => {
        setValue(`hours[${i.id}]`, getHour(i.value));
        setValue(`minutes[${i.id}]`, getMinute(i.value));
      });
    }
    setIsLoading(false);
  }, []);

  const recallApi = async () => {
    setIsLoading(true);
    const {
      data: { data },
    } = await bookingActions.getListReleaseTime();
    setListReleaseTime(data);
    setIsLoading(false);
  };
  async function onSubmit() {
    if (listReleaseTime != undefined) {
      const releaseTimes = listReleaseTime.map((i) => {
        const hour = watch(`hours[${i.id}]`)?.replace(/,/g, "") || "";
        const minute = watch(`minutes[${i.id}]`)?.replace(/,/g, "") || "";
        return {
          id: i.id,
          value: hour + ":" + minute,
        };
      });
      const params = {
        data: releaseTimes,
      };
      try {
        let res = await bookingActions.updateReleaseTime(params);
        if (res.status === 200) {
          const message = "Update Release Time success";
          toast({
            description: message,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      } catch (err) {
        console.warn(err);
        toast({
          description: err.response.data.meta.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
  }

  function getHour(timeItem) {
    const times = timeItem.split(":");
    return times[0];
  }
  function getMinute(timeItem) {
    const times = timeItem.split(":");
    return times[1];
  }

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px">
        <CardHeader px="12px" mb="12px">
          <Flex direction="column" display="block">
            <Text
              float="left"
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              mb="6px"
            >
              Release Time
            </Text>
            <Stack float="right" direction="row" spacing={4}></Stack>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex direction="column" w="90%">
            {!isLoading ? (
              <Stack direction="column" spacing="50px">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Table variant="simple" color={textColor}>
                    <Thead></Thead>
                    <Tbody>
                      {listReleaseTime &&
                        listReleaseTime?.map((i, index) => (
                          <Tr key={index}>
                            <Td p="16px 12px">
                              <FormControl>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="normal"
                                >
                                  &nbsp;
                                </FormLabel>
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="normal"
                                >
                                  {i.key}
                                </FormLabel>
                              </FormControl>
                            </Td>
                            <Td p="16px 12px">
                              <Text fontSize="xs" color={textColor}>
                                <Flex direction="column">
                                  <FormControl isRequired>
                                    <FormLabel
                                      ms="4px"
                                      fontSize="sm"
                                      fontWeight="normal"
                                    >
                                      Hours
                                    </FormLabel>
                                    <>
                                      <Controller
                                        control={control}
                                        name={`hours[${i.id}]`}
                                        render={({ field }) => (
                                          <Input
                                            type="number"
                                            fontSize="sm"
                                            ms="4px"
                                            placeholder="Hours"
                                            size="sm"
                                            {...field}
                                            errorBorderColor="red.300"
                                            defaultValue={getHour(i.value)}
                                            onKeyDown={(e) => {
                                              if (e.key === "e") {
                                                e.preventDefault();
                                              }
                                              if (e.target.value.length > 2) {
                                                e.target.value = e.target.value.slice(
                                                  0,
                                                  2
                                                );
                                              }
                                            }}
                                          />
                                        )}
                                      />
                                    </>
                                    <Text
                                      color="red"
                                      fontSize={14}
                                      mb="10px"
                                      ml="5px"
                                    >
                                      {errors.min_los?.message}
                                    </Text>
                                  </FormControl>
                                </Flex>
                              </Text>
                            </Td>
                            <Td p="16px 12px">
                              <Text fontSize="xs" color={textColor}>
                                <Flex direction="column">
                                  <FormControl isRequired>
                                    <FormLabel
                                      ms="4px"
                                      fontSize="sm"
                                      fontWeight="normal"
                                    >
                                      Minutes
                                    </FormLabel>
                                    <>
                                      <Controller
                                        control={control}
                                        name={`minutes[${i.id}]`}
                                        render={({ field }) => (
                                          <Input
                                            type="number"
                                            fontSize="sm"
                                            ms="4px"
                                            placeholder="Minutes"
                                            size="sm"
                                            {...field}
                                            errorBorderColor="red.300"
                                            defaultValue={getMinute(i.value)}
                                            onKeyDown={(e) => {
                                              if (e.key === "e") {
                                                e.preventDefault();
                                              }
                                              if (e.target.value.length > 2) {
                                                e.target.value = e.target.value.slice(
                                                  0,
                                                  2
                                                );
                                              }
                                            }}
                                          />
                                        )}
                                      />
                                    </>
                                    <Text
                                      color="red"
                                      fontSize={14}
                                      mb="10px"
                                      ml="5px"
                                    >
                                      {errors.min_los?.message}
                                    </Text>
                                  </FormControl>
                                </Flex>
                              </Text>
                            </Td>
                          </Tr>
                        ))}
                      <Tr>
                        <Td p="16px 12px"></Td>
                        <Td>
                          <Button
                            variant="primary"
                            w="150px"
                            h="35px"
                            isLoading={isSubmitting}
                            type="submit"
                          >
                            UPDATE
                          </Button>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </form>
              </Stack>
            ) : (
              <Flex justify="center" align="center" my="20px">
                <Spinner
                  textAlign="center"
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </Flex>
            )}
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default ReleaseTime;
