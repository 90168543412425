import { atom } from "recoil";

const listTheaterPassReportAtom = atom({
    key: 'theaterPassReport',
    default: []
});

const listTicketboxReportAtom = atom({
    key: 'ticketboxReport',
    default: {
        list: "",
        dataTable: []
    }
});

export {
    listTheaterPassReportAtom,
    listTicketboxReportAtom,
};