import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import CoverImage from "assets/img/CoverImage.png";
import React, { useState } from "react";
import AuthCover from "layouts/AuthCover";
import { useUserActions } from "hooks/useUserActions";

function ResetCover() {
  const [emailAddress, setEmailAddress] = useState("");
  const textColor = useColorModeValue("gray.700", "white");
  const bgForm = useColorModeValue("white", "navy.800");
  const userActions = useUserActions();
  function sendRecoveryEmail() {
    userActions.forgotPassword({ email: emailAddress });
  }

  return (
    <AuthCover image={CoverImage}>
      <Flex
        w="100%"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb="60px"
        mt={{ base: "60px", md: "30vh" }}
      >
        <Flex
          zIndex="2"
          direction="column"
          w="445px"
          background="transparent"
          borderRadius="15px"
          p="40px"
          mx={{ base: "20px", md: "100px" }}
          mb={{ base: "20px", md: "auto" }}
          bg={bgForm}
          boxShadow={useColorModeValue(
            "0px 5px 14px rgba(0, 0, 0, 0.05)",
            "unset"
          )}
        >
          <Text
            fontWeight="bold"
            color={textColor}
            textAlign="center"
            mb="10px"
            fontSize={{ base: "3xl", md: "4xl" }}
          >
            Recovery password
          </Text>
          <Text
            fontWeight="regular"
            textAlign="center"
            color="gray.400"
            mb="35px"
          >
            You will receive an e-mail have code to recovery password
          </Text>
          <FormControl>
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Email
            </FormLabel>
            <Input
              fontSize="sm"
              ms="4px"
              type="text"
              placeholder="Your email address"
              mb="24px"
              size="lg"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            />
            <Button
              fontSize="10px"
              variant="primary"
              fontWeight="bold"
              w="100%"
              h="45"
              mb="24px"
              onClick={sendRecoveryEmail}
            >
              SEND
            </Button>
          </FormControl>
        </Flex>
      </Flex>
    </AuthCover>
  );
}

export default ResetCover;
