import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Switch,
  Stack,
  Grid,
  Image,
  Select,
  Tab,
  TabList,
  Tabs,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SearchTable2 from "components/Tables/SearchTable2";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { totalRow } from "state/roles-permissions";
import { AddIcon } from "@chakra-ui/icons";
import { checkForbidden } from "helpers/checkForbidden";
import { useCMSActions } from "hooks/useCMS";
import Editor from "components/Editor/Editor2";
import { useRatePlanActions } from "hooks/useRatePlanActions";
import { columnsDataShowWeb } from "variables/columnsData";
import { showWebAtom, bannerAtom, locationWebAtom } from "state/cms";
import { findValue } from "helpers/helpers";

function DataTableShowWeb() {
  const textColor = useColorModeValue("gray.700", "white");
  const listNews = useRecoilValue(showWebAtom);
  const listBanner = useRecoilValue(bannerAtom);
  const listLocation = useRecoilValue(locationWebAtom);
  const totalRows = useRecoilValue(totalRow);
  const cmsActions = useCMSActions();
  const ratePlanActions = useRatePlanActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCreate, setIsCreate] = useState(true);
  const [currentNewsID, setCurrentNewsID] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [newListNews, setNewListNews] = useState([]);
  const [previewThumbnail, setPreviewThumbnail] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [previewThumbnailVN, setPreviewThumbnailVN] = useState("");
  const [previewImageVN, setPreviewImageVN] = useState("");
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    control,
    watch,
  } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const toast = useToast();
  const thumbnailURL = watch("thumbnail");
  const imageURL = watch("logo");
  const thumbnailURLVN = watch("thumbnail_vn");
  const imageURLVN = watch("logo_vn");
  useEffect(async () => {
    if (thumbnailURL && thumbnailURL?.length > 0) {
      const formData = new FormData();
      formData.append("file", thumbnailURL[0]);
      const {
        data: { data },
      } = await ratePlanActions.uploadImageRatePlanConfig(formData);
      setPreviewThumbnail(data.url);
    }
  }, [thumbnailURL]);
  useEffect(async () => {
    if (imageURL && imageURL?.length > 0) {
      const formData = new FormData();
      formData.append("file", imageURL[0]);
      const {
        data: { data },
      } = await ratePlanActions.uploadImageRatePlanConfig(formData);
      setPreviewImage(data.url);
    }
  }, [imageURL]);
  useEffect(async () => {
    if (thumbnailURLVN && thumbnailURLVN?.length > 0) {
      const formData = new FormData();
      formData.append("file", thumbnailURLVN[0]);
      const {
        data: { data },
      } = await ratePlanActions.uploadImageRatePlanConfig(formData);
      setPreviewThumbnailVN(data.url);
    }
  }, [thumbnailURLVN]);
  useEffect(async () => {
    if (imageURLVN && imageURLVN?.length > 0) {
      const formData = new FormData();
      formData.append("file", imageURLVN[0]);
      const {
        data: { data },
      } = await ratePlanActions.uploadImageRatePlanConfig(formData);
      setPreviewImageVN(data.url);
    }
  }, [imageURLVN]);
  useEffect(() => {
    cmsActions.getListBanner();
    cmsActions.getListLocations();
  }, []);
  useEffect(() => {
    if (listNews) {
      const toolTypes = listNews.map((i) => ({
        ...i,
        filters: { status: 1 },
        typeSwitch: "showWeb",
      }));
      setNewListNews(toolTypes);
    }
  }, [listNews]);
  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    await cmsActions.getListShow(
      {
        name: "",
        status: 1,
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
    setIsFetching(false);
  };
  async function onSubmit({
    name,
    description,
    quote,
    id,
    meta_description,
    meta_keywords,
    meta_title,
    og_type,
    og_url,
    og_title,
    og_description,
    og_image,
    isActive,
    slug,
    quote_author,
    trailer,
    banner_id
  }) {
    const listKeys = [
      "name",
      "description",
      "quote",
      "quote_author",
      "meta_description",
      "meta_keywords",
      "meta_title",
      "og_type",
      "og_url",
      "og_title",
      "og_description",
      "og_image",
      "thumbnail",
      "logo",
    ];
    const languages = listKeys
      .map((i) => {
        if (watch(`${i}_vn`)) {
          if (i === "thumbnail") {
            return {
              code: "vi",
              locale_key: i,
              locale_value: previewThumbnailVN,
            };
          } else if (i === "logo") {
            return {
              code: "vi",
              locale_key: i,
              locale_value: previewImageVN,
            };
          }
          return {
            code: "vi",
            locale_key: i,
            locale_value: watch(`${i}_vn`),
          };
        } else {
          return undefined;
        }
      })
      .filter(Boolean);
    const params = {
      name,
      description,
      quote,
      quote_author,
      id,
      thumbnail: previewThumbnail,
      logo: previewImage,
      meta_description,
      meta_keywords,
      meta_title,
      og_type,
      og_url,
      og_title,
      og_description,
      og_image,
      status: isActive ? 1 : 2,
      slug,
      trailer,
      banner_id: parseInt(banner_id),
    };
    params.languages = languages;

    try {
      let res;
      if (isCreate) {
        res = await cmsActions.createShow(params);
      } else {
        res = await cmsActions.updateShow(params, currentNewsID);
      }

      if (res.status === 200) {
        onClose();
        reset();
        setPreviewImage("");
        setPreviewThumbnail("");
        setPreviewThumbnailVN("");
        setPreviewImageVN("");
        const message = isCreate
          ? "Create Show Web success"
          : "Update Show Web success";
        toast({
          description: message,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        await cmsActions.getListShow(
          {
            name: "",
            status: 1,
          },
          0,
          localStorage && localStorage.getItem("currentPageSizeStore")
        );
      }
    } catch (err) {
      console.warn(err);
      toast({
        description: err.response,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  async function editNews(newsID) {
    const newsData = await cmsActions.getDetailShow(newsID);
    if (newsData) {
      const {
        name,
        description,
        id,
        thumbnail,
        logo,
        status,
        meta_description,
        meta_keywords,
        meta_title,
        og_type,
        og_url,
        og_title,
        og_description,
        og_image,
        slug,
        quote,
        quote_author,
        trailer,
        banner_id,
        languages,
      } = newsData.data.data;
      setValue("name", name);
      setValue("banner_id", banner_id);
      setValue("trailer", trailer);
      setValue("description", description);
      setValue("quote", quote);
      setValue("quote_author", quote_author);
      setPreviewImage(logo);
      setPreviewThumbnail(thumbnail);
      setValue("meta_description", meta_description);
      setValue("meta_keywords", meta_keywords);
      setValue("meta_title", meta_title);
      setValue("og_type", og_type);
      setValue("og_url", og_url);
      setValue("og_title", og_title);
      setValue("og_description", og_description);
      setValue("og_image", og_image);
      setValue("slug", slug);
      setValue("isActive", status === 1 ? true : false);
      setCurrentNewsID(id);

      if (languages) {
        languages.forEach((e) => {
          if (e.locale_key === "image_url" || e.locale_key === "thumbnail_url")
            return;
          setValue(`${e.locale_key}_vn`, e.locale_value);
        });
        setPreviewThumbnailVN(findValue(languages, "thumbnail"));
        setPreviewImageVN(findValue(languages, "logo"));
      }
    }
    setIsCreate(false);
    onOpen();
  }

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex direction="column" display="block">
            <Text
              float="left"
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              mb="6px"
            >
              Show Web Management
            </Text>
            <Stack float="right" direction="row" spacing={4}>
              {checkForbidden(42) && (
                <Button
                  size="sm"
                  onClick={() => {
                    onOpen();
                    setIsCreate(true);
                    setValue("description", "")
                    setValue("description_vn", "")
                    setValue("quote", "")
                    setValue("quote_vn", "")
                    setValue("quote_author", "")
                    setValue("quote_author_vn", "")
                  }}
                  leftIcon={<AddIcon />}
                  colorScheme="green"
                  variant="outline"
                >
                  Add Show Web
                </Button>
              )}
            </Stack>
            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpen}
              onClose={() => {
                onClose();
                reset();
                setPreviewImage("");
                setPreviewThumbnail("");
                setPreviewThumbnailVN("");
                setPreviewImageVN("");
              }}
              size="6xl"
            >
              <ModalOverlay />
              <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                  <ModalHeader>
                    {isCreate ? "Add Show Web" : "Edit Show Web"}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <Tabs isFitted variant="enclosed">
                      <TabList>
                        <Tab
                          _focus={{ outline: "none" }}
                          _selected={{ color: "white", bg: "blue.500" }}
                          fontWeight={"bold"}
                          fontSize={"18px"}
                        >
                          English
                        </Tab>
                        <Tab
                          _focus={{ outline: "none" }}
                          _selected={{ color: "white", bg: "blue.500" }}
                          fontWeight={"bold"}
                          fontSize={"18px"}
                        >
                          Viet Nam
                        </Tab>
                      </TabList>

                      <TabPanels>
                        <TabPanel>
                          <Grid
                            templateColumns={{
                              sm: "1fr",
                              md: "repeat(2, 1fr)",
                            }}
                            gap="12px"
                            mt={2}
                          >
                            <FormControl isRequired>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Name
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Name"
                                maxLength={250}
                                size="md"
                                name="name"
                                {...register("name")}
                              />
                            </FormControl>
                            <FormControl isRequired>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Slug
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="SLug"
                                maxLength={250}
                                size="md"
                                name="slug"
                                {...register("slug")}
                                disabled={!isCreate}
                              />
                            </FormControl>
                            </Grid>
                            <Grid
                            templateColumns={{
                              sm: "1fr",
                              md: "repeat(2, 1fr)",
                            }}
                            gap="12px"
                            mt={2}
                          >
                            <FormControl isRequired>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Banner
                              </FormLabel>
                              <Select
                                ms="4px"
                                type="text"
                                defaultValue="0"
                                size="sm"
                                name="banner_id"
                                {...register("banner_id")}
                                width="250px"
                              >
                                {listBanner
                                  .filter((item) => item.status === 1)
                                  .map((i) => (
                                    <option key={i.id} value={i.id}>
                                      {i.name}
                                    </option>
                                  ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <FormControl mt={3}>
                            <FormLabel
                              color={textColor}
                              fontWeight="bold"
                              fontSize="xs"
                            >
                              Description
                            </FormLabel>
                            <Editor name="description" control={control} />
                          </FormControl>

                          <FormControl mt={3}>
                            <FormLabel
                              color={textColor}
                              fontWeight="bold"
                              fontSize="xs"
                            >
                              Quote
                            </FormLabel>
                            <Editor name="quote" control={control} />
                          </FormControl>
                          <FormControl mt={3}>
                            <FormLabel
                              color={textColor}
                              fontWeight="bold"
                              fontSize="xs"
                            >
                              Quote Author
                            </FormLabel>
                            <Editor name="quote_author" control={control} />
                          </FormControl>
                          <FormControl isRequired>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="normal"
                            >
                              Trailer
                            </FormLabel>
                            <Input
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Trailer"
                              maxLength={250}
                              size="md"
                              name="trailer"
                              maxW={250}
                              {...register("trailer")}
                            />
                          </FormControl>
                          <Grid
                            templateColumns={{
                              sm: "1fr",
                              md: "repeat(2, 1fr)",
                            }}
                            gap="12px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                color={textColor}
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                Thumbnail
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="file"
                                placeholder="Thumbnail"
                                size="sm"
                                name="thumbnail"
                                {...register("thumbnail")}
                              />
                              {previewThumbnail && (
                                <Image
                                  src={previewThumbnail}
   
                                />
                              )}
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                color={textColor}
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                Logo
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="file"
                                placeholder="Logo"
                                size="sm"
                                name="logo"
                                {...register("logo")}
                              />
                              {previewImage && (
                                <Image
                                  src={previewImage}
                                />
                              )}
                            </FormControl>
                          </Grid>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Meta Description
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Meta Description"
                                maxLength={250}
                                size="md"
                                name="meta_description"
                                {...register("meta_description")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Meta KeyWord
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Meta KeyWord"
                                maxLength={250}
                                size="md"
                                name="meta_keywords"
                                {...register("meta_keywords")}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Meta Title
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Meta Title"
                                maxLength={250}
                                size="md"
                                name="meta_title"
                                {...register("meta_title")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG Type
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG Type"
                                maxLength={250}
                                size="md"
                                name="og_type"
                                {...register("og_type")}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG URL
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG URL"
                                maxLength={250}
                                size="md"
                                name="og_url"
                                {...register("og_url")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG Title
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG Title"
                                maxLength={250}
                                size="md"
                                name="og_title"
                                {...register("og_title")}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG Description
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG Description"
                                maxLength={250}
                                size="md"
                                name="og_description"
                                {...register("og_description")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG Image
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG Image"
                                maxLength={250}
                                size="md"
                                name="og_image"
                                {...register("og_image")}
                              />
                            </FormControl>
                          </Grid>
                        </TabPanel>
                        <TabPanel>
                          <Grid
                            templateColumns={{
                              sm: "1fr",
                              md: "repeat(3, 1fr)",
                            }}
                            gap="12px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Name
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Name"
                                maxLength={250}
                                size="md"
                                name="name_vn"
                                {...register("name_vn")}
                              />
                            </FormControl>
                          </Grid>
                          <FormControl mt={3}>
                            <FormLabel
                              color={textColor}
                              fontWeight="bold"
                              fontSize="xs"
                            >
                              Description
                            </FormLabel>
                            <Editor name="description_vn" control={control} />
                          </FormControl>

                          <FormControl mt={3}>
                            <FormLabel
                              color={textColor}
                              fontWeight="bold"
                              fontSize="xs"
                            >
                              Quote
                            </FormLabel>
                            <Editor name="quote_vn" control={control} />
                          </FormControl>
                          <FormControl mt={3}>
                            <FormLabel
                              color={textColor}
                              fontWeight="bold"
                              fontSize="xs"
                            >
                              Quote Author
                            </FormLabel>
                            <Editor name="quote_author_vn" control={control} />
                          </FormControl>
                          <Grid
                            templateColumns={{
                              sm: "1fr",
                              md: "repeat(2, 1fr)",
                            }}
                            gap="12px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                color={textColor}
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                Thumbnail
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="file"
                                placeholder="Thumbnail"
                                size="sm"
                                name="thumbnail_vn"
                                {...register("thumbnail_vn")}
                              />
                              {previewThumbnailVN && (
                                <Image
                                  src={previewThumbnailVN}

                                />
                              )}
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                color={textColor}
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                Logo
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="file"
                                placeholder="Image"
                                size="sm"
                                name="logo_vn"
                                {...register("logo_vn")}
                              />
                              {previewImageVN && (
                                <Image
                                  src={previewImageVN}
                                />
                              )}
                            </FormControl>
                          </Grid>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Meta Description
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Meta Description"
                                maxLength={250}
                                size="md"
                                name="meta_description_vn"
                                {...register("meta_description_vn")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Meta KeyWord
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Meta KeyWord"
                                maxLength={250}
                                size="md"
                                name="meta_keywords_vn"
                                {...register("meta_keywords_vn")}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Meta Title
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Meta Title"
                                maxLength={250}
                                size="md"
                                name="meta_title_vn"
                                {...register("meta_title_vn")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG Type
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG Type"
                                maxLength={250}
                                size="md"
                                name="og_type_vn"
                                {...register("og_type_vn")}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG URL
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG URL"
                                maxLength={250}
                                size="md"
                                name="og_url_vn"
                                {...register("og_url_vn")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG Title
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG Title"
                                maxLength={250}
                                size="md"
                                name="og_title_vn"
                                {...register("og_title_vn")}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG Description
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG Description"
                                maxLength={250}
                                size="md"
                                name="og_description_vn"
                                {...register("og_description_vn")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG Image
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG Image"
                                maxLength={250}
                                size="md"
                                name="og_image_vn"
                                {...register("og_image_vn")}
                              />
                            </FormControl>
                          </Grid>
                        </TabPanel>
                      </TabPanels>
                    </Tabs>

                    <FormControl>
                      <Switch
                        id="isChecked"
                        defaultChecked={isCreate && true}
                        name="isActive"
                        {...register("isActive")}
                        size="sm"
                        ms="4px"
                      >
                        Active
                      </Switch>
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      {isCreate ? "Create" : "Update"}
                    </Button>
                    <Button
                      onClick={() => {
                        onClose();
                        reset();
                        setPreviewImage("");
                        setPreviewThumbnail("");
                        setPreviewThumbnailVN("");
                        setPreviewImageVN("");
                      }}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </form>
            </Modal>
          </Flex>
        </CardHeader>
        <CardBody>
          <SearchTable2
            tableData={newListNews}
            columnsData={columnsDataShowWeb}
            totalRow={totalRows.showWeb}
            edit={editNews}
            actionsActive={["edit"]}
            listPermissions={[43]}
            isFetching={isFetching}
            loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTableShowWeb;
