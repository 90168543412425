import {
    Box,
    Button,
    Flex,
    FormLabel,
    Select,
    Text,
    useColorModeValue,
    Table,
    Thead,
    Tbody,
    Td,
    Tr,
    Th,
    Spinner
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useForm, Controller } from "react-hook-form";
import { useBookingActions } from "hooks/useBookingActions";
import { BsSearch } from "react-icons/bs";
import { RxReset } from "react-icons/rx";
import DatePickerInput from "components/CustomDateInput/CustomDateInput";
import { RiFileExcel2Fill } from "react-icons/ri";
import {
    listTheaterPassReportAtom
} from "../../state/report";
import { useReportActions } from "hooks/useReportActions";

import {
    listShowAtom,
    venueListAtom,
} from "../../state/show";

function DataTableTheaterPassReport() {
    const listTheaterPassReport = useRecoilValue(listTheaterPassReportAtom);
    const reportActions = useReportActions();
    const textColor = useColorModeValue("gray.700", "white");
    const bookingActions = useBookingActions();
    const listShow = useRecoilValue(listShowAtom);
    const venueList = useRecoilValue(venueListAtom);
    const [isFetching, setIsFetching] = useState(true);

    const {
        register: registerSearch,
        handleSubmit: handleSubmitSearch,
        control: controlSearch,
        watch: watchSearch,
        reset: resetSearch,
        setValue: setValueSearch,
        formState,
    } = useForm();
    const { errors, isSubmitting } = formState;

    useEffect(async () => {
        setIsFetching(true);

        bookingActions.getListShow({ status: 1 }, 0, "");
        bookingActions.getListVenue({ status: 1 }, 0, "");
        setValueSearch("from_showdate", new Date());
        setValueSearch("to_showdate", new Date());
        reportActions.getListTheaterPassReport(
            {
                show_date_from: new Date().toISOString(),
                show_date_to: new Date().toISOString(),
            }
        );
        setIsFetching(false);
    }, []);

    async function onSubmitSearch({
        showSearch,
        venueSearch,
    }) {
        setIsFetching(true);
        await reportActions.getListTheaterPassReport(
            {
                show_id: showSearch,
                venue_id: venueSearch,
                show_date_from: new Date(
                    watchSearch("from_showdate").setHours(0, 0, 0, 0)
                ).toISOString(),
                show_date_to: new Date(
                    watchSearch("to_showdate").setHours(23, 59, 59, 999)
                ).toISOString(),
            }
        );
        setIsFetching(false);
    }

    return (
        <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
            <Card px="0px">
                <CardHeader px="22px" mb="12px">
                    <Flex direction="column" display="block">
                        <Text
                            float="left"
                            color={textColor}
                            fontSize="lg"
                            fontWeight="bold"
                            mb="6px"
                        >
                            Theater Pass Report
                        </Text>
                    </Flex>
                </CardHeader>
                <CardBody>
                    <Flex ml="22px"></Flex>
                    <Card>
                        <form
                            id="booking-search-form"
                            onSubmit={handleSubmitSearch(onSubmitSearch)}
                        >
                            <Flex
                                direction="row"
                                alignItems="center"
                                mb={3}
                                gap={4}
                                flexWrap={"wrap"}
                            >
                                <Box direction="row" alignItems="center" display="block">
                                    <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                                        Show
                                    </FormLabel>
                                    <Select
                                        ms="4px"
                                        type="text"
                                        defaultValue="0"
                                        size="sm"
                                        id="showSearch"
                                        name="showSearch"
                                        {...registerSearch("showSearch")}
                                        width="100px"
                                    >
                                        <option value="">All</option>
                                        {listShow.map((item, index) => (
                                            <option key={index} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </Select>
                                </Box>
                                <Box direction="row" alignItems="center" display="block">
                                    <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                                        Venue
                                    </FormLabel>
                                    <Select
                                        ms="4px"
                                        type="text"
                                        size="sm"
                                        id={"venueSearch"}
                                        name="venueSearch"
                                        {...registerSearch("venueSearch")}
                                    >
                                        <option value="">All</option>
                                        {venueList.map((i) => (
                                            <option key={i.id} value={i.id}>
                                                {i.name}
                                            </option>
                                        ))}
                                    </Select>
                                </Box>
                                <Box direction="row" alignItems="center" display="block">
                                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                                        Show Date From
                                    </FormLabel>
                                    <Controller
                                        name="from_showdate"
                                        control={controlSearch}
                                        render={({ field }) => (
                                            <DatePickerInput
                                                onChange={(value) => field.onChange(value)}
                                                mask="99/99/9999"
                                                selected={watchSearch("from_showdate")}
                                                selectsStart
                                                endDate={watchSearch("to_showdate")}
                                                minDate={new Date("01/01/2020")}
                                            />
                                        )}
                                    />
                                </Box>
                                <Box direction="row" alignItems="center" display="block">
                                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                                        Show Date To
                                    </FormLabel>
                                    <Controller
                                        name="to_showdate"
                                        control={controlSearch}
                                        render={({ field }) => (
                                            <DatePickerInput
                                                onChange={(value) => field.onChange(value)}
                                                selected={field.value}
                                                mask="99/99/9999"
                                                selectsEnd
                                                endDate={watchSearch("to_showdate")}
                                                minDate={watchSearch("from_showdate")}
                                            />
                                        )}
                                    />
                                </Box>
                            </Flex>
                            <Text color="red" fontSize={14}>
                                {Object.values(errors)[0]?.message}
                            </Text>
                            <Flex justify="center" align="center" gap="8px" mt={3}>
                                <Box direction="row" alignItems="center" display="block">
                                    <Button
                                        leftIcon={<RxReset />}
                                        variant="primary"
                                        w="88px"
                                        h="33px"
                                        onClick={() => {
                                            resetSearch();
                                            setValueSearch("from_showdate", new Date());
                                            setValueSearch("to_showdate", new Date());
                                        }}
                                    >
                                        Reset
                                    </Button>
                                </Box>
                                <Box direction="row" alignItems="center" display="block">
                                    <Button
                                        leftIcon={<RiFileExcel2Fill />}
                                        variant="primary"
                                        w="100px"
                                        h="33px"
                                        type="submit"
                                        disabled
                                    >
                                        Export Excel
                                    </Button>
                                </Box>
                                <Box direction="row" alignItems="center" display="block">
                                    <Button
                                        leftIcon={<BsSearch />}
                                        variant="primary"
                                        w="88px"
                                        h="33px"
                                        type="submit"
                                    >
                                        Search
                                    </Button>
                                </Box>
                            </Flex>
                        </form>
                    </Card>

                    {isFetching ? (
                        <Flex justify="center" align="center" my="20px">
                            <Spinner
                                textAlign="center"
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="blue.500"
                                size="xl"
                            />
                        </Flex>
                    ) : listTheaterPassReport.length === 0 ? (
                        <Table size="sm">
                            <Thead>
                                <Tr>
                                    <Th color="white" colSpan="5" backgroundColor="blue.600">
                                        No data
                                    </Th>
                                </Tr>
                            </Thead>
                            <Thead>
                                <Tr>
                                    <Th w={"450px"}></Th>
                                    <Th textAlign="center">aah![A]</Th>
                                    <Th textAlign="center">ooh![O]</Th>
                                    <Th textAlign="center">wow![W]</Th>
                                    <Th textAlign="center">Total</Th>
                                </Tr>
                            </Thead>
                        </Table>
                    ) : (
                        listTheaterPassReport.map((report, reportIndex) => (
                            <Table size="sm" key={reportIndex}>
                                <Thead>
                                    <Tr>
                                        <Th color="white" colSpan="5" backgroundColor="blue.600">
                                            {`${report.show_date} ${report.show_time}`}
                                        </Th>
                                    </Tr>
                                    <Tr>
                                        <Th w={"450px"}></Th>
                                        <Th textAlign="center">aah![A]</Th>
                                        <Th textAlign="center">ooh![O]</Th>
                                        <Th textAlign="center">wow![W]</Th>
                                        <Th textAlign="center">Total</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {report.data.map((row, index) => (
                                        <Tr key={index}>
                                            <Td>{row.title}</Td>
                                            <Td textAlign="center">{row.aah}</Td>
                                            <Td textAlign="center">{row.ooh}</Td>
                                            <Td textAlign="center">{row.wow}</Td>
                                            <Td textAlign="center">{row.total}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        ))
                    )}

                </CardBody>
            </Card>
        </Flex>
    );
}

export default DataTableTheaterPassReport;
