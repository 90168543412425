import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Switch,
  Stack,
  Box,
  Select,
  Textarea,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Grid,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { totalRow } from "state/roles-permissions";
import { AddIcon } from "@chakra-ui/icons";
import { checkForbidden } from "helpers/checkForbidden";
import { BsSearch } from "react-icons/bs";
import { RxReset } from "react-icons/rx";
import { useBookingActions } from "hooks/useBookingActions";
import { listChannelAtom } from "state/show";
import { listUnitAtom } from "state/show";
import {
  columnsDataUnit,
  columnsAddUnitWithRateplanUnit,
  columnsDataSubAccount,
} from "variables/columnsData";
import SearchTable2 from "components/Tables/SearchTable2";
import { Select as ReactSelect } from "chakra-react-select";
import { usersAtom, subAccountAtom } from "state/users";
import { useUserActions } from "hooks/useUserActions";
import { ratePlansAtom } from "state/ratePlans";
import { useRatePlanActions } from "hooks/useRatePlanActions";
import { zoneListAtom } from "state/show";
import ZoneItem from "components/ZoneItem/ZoneItem";
import { STATUS } from "../../variables/general";
import { debounce } from "lodash";
function DataTableUnits() {
  const textColor = useColorModeValue("gray.700", "white");
  const ratePlans = useRecoilValue(ratePlansAtom);
  const listUnit = useRecoilValue(listUnitAtom);
  const listChannel = useRecoilValue(listChannelAtom);
  const listSubAccount = useRecoilValue(subAccountAtom);
  const zoneList = useRecoilValue(zoneListAtom);
  const users = useRecoilValue(usersAtom);
  const totalRows = useRecoilValue(totalRow);
  const bookingActions = useBookingActions();
  const userActions = useUserActions();
  const ratePlanActions = useRatePlanActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCreate, setIsCreate] = useState(false);
  const [currentUnitID, setCurrentUnitID] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [newListUnit, setNewListUnit] = useState([]);
  const [rateplanUnitSelected, setRateplanUnitSelected] = useState([]);
  const [subAccountsSelected, setSubAccountsSelected] = useState([]);
  const [ratePlanFilter, setRatePlanFilter] = useState([]);
  const [zoneListActive, setZoneListActive] = useState([]);
  const [activeButtons, setActiveButtons] = useState({
    messages: true,
    social: false,
  });
  const chakraStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      p: 0,
      w: "20px",
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: "200px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 15,
    }),
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    channel: Yup.object().required("This field is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    watch,
    control,
    resetField,
    setError,
    clearErrors,
  } = useForm(formOptions);
  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    watch: watchSearch,
    reset: resetSearch,
  } = useForm();
  const { errors, isSubmitting } = formState;
  const toast = useToast();
  const nameSearch = watchSearch("nameSearch");
  const statusSearch = watchSearch("statusSearch");
  const channelSearch = watchSearch("channelSearch");
  const isLiabilitySearch = watchSearch("isLiabilitySearch");
  const codeSearch = watchSearch("codeSearch");
  const commissions = watch("commissions");
  const rateplan = watch("rateplan");
  const subAccounts = watch("subAccounts");
  useEffect(async () => {
    setIsFetching(true);
    await bookingActions.getListChannel({ status: 1 }, 0, "");
    await userActions.getAllUsers({ status: 1 }, 0, "");
    await ratePlanActions.getAllRatePlans({ status: 1 }, 0, "");
    bookingActions.getListZones({ status: 1 }, 0, "");
    setIsFetching(false);
  }, []);
  useEffect(() => {
    setRatePlanFilter(ratePlans);
  }, [ratePlans]);
  useEffect(() => {
    if (rateplan?.rateplan_configs) {
      const test = rateplan.rateplan_configs;
      const uniqueZoneIds = [...new Set(test.map((item) => item.zone_id))];
      setZoneListActive(zoneList.filter((i) => uniqueZoneIds.includes(i.id)));
    }
  }, [rateplan]);
  useEffect(async () => {
    try {
      if (isCreate) {
        if (watch("name")) {
          clearErrors();
          await bookingActions.checkUnitName({
            name: watch("name"),
            id: currentUnitID ? currentUnitID : 0,
          });
        }
      } else {
        if (watch("name") && currentUnitID) {
          clearErrors();
          if (currentUnitID) {
            await bookingActions.checkUnitName({
              name: watch("name"),
              id: currentUnitID ? currentUnitID : 0,
            });
          }
        }
      }
    } catch (error) {
      if (error.response.status === 400) {
        setError("checkName", { message: "Unit name is exist" });
      }
      console.log(error);
    }
  }, [currentUnitID, isCreate]);

  useEffect(() => {
    if (listUnit) {
      const newListUnits = listUnit.map((i) => ({
        ...i,
        typeSwitch: "unit",
        filters: {
          name: nameSearch,
          status: statusSearch,
          channel_id: channelSearch,
          is_liability: isLiabilitySearch,
          code: codeSearch,
        },
      }));
      setNewListUnit(newListUnits);
    }
  }, [listUnit]);
  async function onSubmitSearch({
    nameSearch,
    statusSearch,
    channelSearch,
    isLiabilitySearch,
    codeSearch,
  }) {
    setIsFetching(true);
    await bookingActions.getListUnits(
      {
        name: nameSearch,
        status: statusSearch,
        channel_id: channelSearch,
        is_liability: isLiabilitySearch,
        code: codeSearch,
      },
      0,
      localStorage && localStorage.getItem("currentPageSizeStore")
        ? localStorage.getItem("currentPageSizeStore")
        : 10
    );
    setIsFetching(false);
  }
  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    await bookingActions.getListUnits(
      {
        name: nameSearch,
        status: statusSearch ? statusSearch : 1,
        channel_id: channelSearch,
        is_liability: isLiabilitySearch ? isLiabilitySearch : 0,
        code: codeSearch,
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
    setIsFetching(false);
  };

  async function onSubmit({
    name,
    description,
    isActive,
    channel,
    users,
    unitCode,
    liability,
    shortTermLiability,
  }) {
    // const sub_accounts = subAccountsSelected.map((i) => parseInt(i.id));
    const rateplan_units = rateplanUnitSelected.map((i) => ({
      rateplan_id: i.rateplanID,
      commissions: i.commissions1,
      id: !isCreate ? i.id || 0 : undefined,
      status: i.status,
    }));
    const params = {
      name,
      description,
      status: isActive ? 1 : 2,
      channel_id: channel.id,
      user_id: users.id,
      code: unitCode,
      is_liability: parseInt(liability),
      is_short_term_liability: parseInt(shortTermLiability),
      rateplan_units,
      // sub_accounts,
    };
    try {
      let res;
      if (isCreate) {
        res = await bookingActions.createUnit(params);
      } else {
        res = await bookingActions.updateUnit(params, currentUnitID);
      }

      if (res.status === 200) {
        onClose();
        reset();
        setRateplanUnitSelected([]);
        setSubAccountsSelected([]);
        const message = isCreate
          ? "Create Unit success"
          : "Update Unit success";
        toast({
          description: message,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        setRatePlanFilter(ratePlans);
      }
    } catch (err) {
      console.warn(err);
      toast({
        description: err?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
    await bookingActions.getListUnits(
      {
        name: nameSearch,
        status: statusSearch,
        channel_id: channelSearch,
        is_liability: isLiabilitySearch,
        code: codeSearch,
      },
      0,
      10
    );
    // setCurrentUnitID(undefined);
  }

  async function editUnit(unitID) {
    const unitDetails = await bookingActions.getUnitDetail(unitID);
    if (unitDetails) {
      const {
        id,
        name,
        description,
        status,
        channel,
        pic,
        code,
        release_time,
        is_liability,
        is_short_term_liability,
        rateplan_units,
        sub_accounts,
      } = unitDetails.data.data;
      setValue("name", name);
      setValue("description", description);
      setValue("channel", channel);
      setValue("users", pic.user);
      setValue("shortTermLiability", is_short_term_liability);
      setValue("liability", is_liability);
      setValue("unitCode", code);
      setValue("isActive", status === 1 ? true : false);
      const rateplanItem = [...rateplan_units];
      let transformedArray;
      if (rateplanItem) {
        transformedArray = rateplanItem.map((item) => {
          let commissions1 = [];
          if (item.commissions) {
            commissions1 = item?.commissions.map((commission) => ({
              zone_id: commission.zone_id,
              com1: commission.com1,
              com2: commission.com2,
            }));
          }

          return {
            unitID: id,
            commissions1,
            commissions2: commissions1,
            program_applied: item.rateplan_program_applied,
            rateplanCode: item.rateplan_code,
            rateplan_id: item.rateplan_id,
            rateplanID: item.rateplan_id,
            rateplanName: item.rateplan_name,
            status: item.status,
            typeSwitch: "rateplanAddUnit",
            id: item.id,
            isLocalUpdateStatus: true,
            reCallValue: (status, rateplanID) =>
              getUpdateValue(status, rateplanID),
          };
        });
      }

      setRateplanUnitSelected(transformedArray);
      localStorage.setItem(
        "rateplanUnitSelected",
        JSON.stringify(transformedArray)
      );
      // const transformedArrayID = transformedArray?.map((i) => i.rateplanID);
      // const ratePlanChoose = ratePlans.filter(
      //   (i) => !transformedArrayID.includes(i.id)
      // );
      // setRatePlanFilter(ratePlanChoose);
      setSubAccountsSelected(sub_accounts);
      setCurrentUnitID(id);
    }
    setIsCreate(false);
    onOpen();
  }
  const confirmAddRatePlanUnit = () => {
    let newCommissions = commissions;
    if (!commissions || commissions?.length === 0) {
      toast({
        description: "Please add commissions",
        status: "error",
        duration: 2000,
      });
      return;
    }
    if (typeof commissions === "string") {
      newCommissions = [commissions];
    }
    const commissionsID = newCommissions.map((i) => ({
      zone_id: parseInt(i),
      com1:
        typeof watch(`commission1[${parseInt(i)}]`) === "number"
          ? watch(`commission1[${parseInt(i)}]`)
          : parseInt(
            watch(`commission1[${parseInt(i)}]`)?.replace(/,/g, "") || 0
          ),
      com2:
        typeof watch(`commission2[${parseInt(i)}]`) === "number"
          ? watch(`commission2[${parseInt(i)}]`)
          : parseInt(
            watch(`commission2[${parseInt(i)}]`)?.replace(/,/g, "") || 0
          ),
    }));
    const rateplanSelect = [
      {
        rateplanCode: rateplan.code,
        rateplanName: rateplan.name,
        program_applied: rateplan.program_applied,
        status: rateplan.status,
        commissions1: commissionsID,
        commissions2: commissionsID,
        rateplanID: rateplan.id,
        isLocalUpdateStatus: true,
        reCallValue: (status, rateplanID) => getUpdateValue(status, rateplanID),
      },
    ];
    // const newRatePlanFilter = ratePlanFilter.filter(
    //   (i) => i.id !== rateplan.id
    // );
    // localStorage.setItem(
    //   "rateplanUnitSelected",
    //   JSON.stringify([...rateplanUnitSelected, ...rateplanSelect])
    // );
    setRateplanUnitSelected((pres) => [...pres, ...rateplanSelect]);
    // setRatePlanFilter(newRatePlanFilter);
    setValue("rateplan", "");
    resetField("commissions");
  };
  const getUpdateValue = (status, rateplanID) => {
    const rateplanUnitSelect = JSON.parse(
      localStorage.getItem("rateplanUnitSelected")
    );
    const rateplanUpdate = rateplanUnitSelect.find(
      (i) => i.rateplanID === rateplanID
    );
    rateplanUpdate.status = status;
    const indexToUpdate = rateplanUnitSelect.findIndex(
      (item) => item.rateplanID === rateplanUpdate.rateplanID
    );
    if (indexToUpdate !== -1) {
      rateplanUnitSelect[indexToUpdate] = rateplanUpdate;
    }
    const addRatePlanUnitFunc = rateplanUnitSelect.map((i) => ({
      ...i,
      reCallValue: (status, rateplanID) => getUpdateValue(status, rateplanID),
    }));
    localStorage.setItem(
      "rateplanUnitSelected",
      JSON.stringify(addRatePlanUnitFunc)
    );
    setRateplanUnitSelected(addRatePlanUnitFunc);
  };

  const confirmAddSubAccount = () => {
    const subAccountSelect = subAccounts.map((i) => {
      const item = {
        fullname: i.fullname,
        email: i.email,
        phone: i.phone,
        status: i.status,
        id: i.id,
      };
      return item;
    });
    setSubAccountsSelected((pres) => [...pres, ...subAccountSelect]);
    setValue("subAccounts", "");
  };
  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex direction="column" display="block">
            <Text
              float="left"
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              mb="6px"
            >
              Unit Management
            </Text>
            <Stack float="right" direction="row" spacing={4}>
              {checkForbidden(16) && (
                <Button
                  size="sm"
                  onClick={async () => {
                    onOpen();
                    setIsCreate(true);
                    // await userActions.getListSubAccount({ status: 1 }, 0, "");
                  }}
                  leftIcon={<AddIcon />}
                  colorScheme="green"
                  variant="outline"
                >
                  Add Unit
                </Button>
              )}
            </Stack>
            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpen}
              onClose={() => {
                onClose();
                reset();
                setRateplanUnitSelected([]);
                setValue("channel", []);
                setValue("users", []);
                setCurrentUnitID(undefined);
                setIsCreate(false);
              }}
              size="5xl"
            >
              <ModalOverlay />
              <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                  <ModalHeader>
                    {isCreate ? "Add New Unit" : "Edit Unit"}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <Flex
                      direction={{ sm: "column", lg: "row" }}
                      justifyContent="space-between"
                      gap={2}
                    >
                      <FormControl isRequired>
                        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                          Channel
                        </FormLabel>
                        <Controller
                          name="channel"
                          control={control}
                          render={({ field }) => (
                            <ReactSelect
                              {...field}
                              focusBorderColor="blue.500"
                              chakraStyles={chakraStyles}
                              options={listChannel}
                              placeholder="Select Channel"
                              getOptionLabel={(i) => i.name}
                              getOptionValue={(option) => option.id}
                              size="sm"
                              closeMenuOnSelect={true}
                            />
                          )}
                        />
                        <Text color="red" fontSize={14} mb="10px" ml="5px">
                          {errors.channel?.message}
                        </Text>
                      </FormControl>

                      <FormControl isRequired>
                        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                          PIC/Account
                        </FormLabel>
                        <Controller
                          name="users"
                          control={control}
                          render={({ field }) => (
                            <ReactSelect
                              {...field}
                              focusBorderColor="blue.500"
                              chakraStyles={chakraStyles}
                              options={users}
                              placeholder="Select Account"
                              getOptionLabel={(i) => i.fullname}
                              getOptionValue={(option) => option.id}
                              size="sm"
                              closeMenuOnSelect={true}
                            />
                          )}
                        />
                        <Text color="red" fontSize={14} mb="10px" ml="5px">
                          {errors.users?.message}
                        </Text>
                      </FormControl>
                    </Flex>
                    <Flex
                      direction={{ sm: "column", lg: "row" }}
                      justifyContent="space-between"
                      gap={2}
                    >
                      <FormControl isRequired>
                        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                          Unit Name
                        </FormLabel>
                        <Input
                          fontSize="sm"
                          ms="4px"
                          type="text"
                          placeholder="Name"
                          maxLength={250}
                          size="md"
                          name="name"
                          {...register("name")}
                          isInvalid={errors.name}
                          errorBorderColor="red.300"
                          onChange={debounce(async () => {
                            try {
                              if (isCreate) {
                                if (watch("name")) {
                                  clearErrors();
                                  await bookingActions.checkUnitName({
                                    name: watch("name"),
                                    id: currentUnitID ? currentUnitID : 0,
                                  });
                                }
                              } else {
                                if (watch("name") && currentUnitID) {
                                  clearErrors();
                                  if (currentUnitID) {
                                    await bookingActions.checkUnitName({
                                      name: watch("name"),
                                      id: currentUnitID ? currentUnitID : 0,
                                    });
                                  }
                                }
                              }
                            } catch (error) {
                              if (error.response.status === 400) {
                                setError("checkName", {
                                  message: "Unit name is exist",
                                });
                              }
                              console.log(error);
                            }
                          }, 500)}
                        />
                        <Text color="red" fontSize={14} mb="10px" ml="5px">
                          {errors.name?.message}
                        </Text>
                        <Text color="red" fontSize={14} mb="10px" ml="5px">
                          {errors.checkName?.message}
                        </Text>
                      </FormControl>
                      <FormControl>
                        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                          Unit Code
                        </FormLabel>
                        <Input
                          fontSize="sm"
                          ms="4px"
                          type="text"
                          placeholder="Unit Code"
                          size="md"
                          {...register("unitCode")}
                          name="unitCode"
                        />
                        <Text color="red" ml="5px">
                          {errors.apiError && errors.apiError?.message}
                        </Text>
                      </FormControl>
                    </Flex>
                    <Flex
                      direction={{ sm: "column", lg: "row" }}
                      justifyContent="space-between"
                      gap={2}
                    >
                      <FormControl>
                        <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                          Liability
                        </FormLabel>
                        <Select
                          ms="4px"
                          type="text"
                          size="sm"
                          name="liability"
                          {...register("liability")}
                          width="200px"
                        >
                          <option value="1">Yes</option>
                          <option value="2">No</option>
                        </Select>
                      </FormControl>
                      <FormControl>
                        <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                          Short-term Liability
                        </FormLabel>
                        <Select
                          ms="4px"
                          type="text"
                          size="sm"
                          name="liability"
                          {...register("shortTermLiability")}
                          width="200px"
                        >
                          <option value="1">Yes</option>
                          <option value="2">No</option>
                        </Select>
                      </FormControl>
                    </Flex>
                    <FormControl mt={2}>
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Description
                      </FormLabel>
                      <Textarea
                        rows={4}
                        {...register("description")}
                        maxLength={1000}
                        name="description"
                        mb={
                          errors.description_vi?.message
                            ? "5px"
                            : { lg: "24px", sm: "0" }
                        }
                      />
                      <Text color="red" ml="5px">
                        {errors.apiError && errors.apiError?.message}
                      </Text>
                    </FormControl>
                    <FormControl>
                      <Switch
                        id="isChecked"
                        defaultChecked={isCreate && true}
                        name="isActive"
                        {...register("isActive")}
                        size="sm"
                        ms="4px"
                      >
                        Active
                      </Switch>
                    </FormControl>
                    <Tabs variant="enclosed-colored" mt={2}>
                      <Box w="100%">
                        <Stack
                          direction={{ sm: "column", lg: "row" }}
                          spacing={{ sm: "8px", lg: "38px" }}
                          w={{ sm: "100%", lg: null }}
                        >
                          <TabList>
                            <Tab _focus={{}}>
                              <Text
                                onClick={() =>
                                  setActiveButtons({
                                    messages: true,
                                    social: false,
                                  })
                                }
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                RatePlan of Unit
                              </Text>
                            </Tab>
                            {/* <Tab _focus={{}}>
                              <Text
                                onClick={() =>
                                  setActiveButtons({
                                    messages: false,
                                    social: true,
                                  })
                                }
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                Sub Account of Unit
                              </Text>
                            </Tab> */}
                          </TabList>
                        </Stack>
                      </Box>
                      <TabPanels>
                        <TabPanel>
                          <FormControl maxW="500px">
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="normal"
                            >
                              Rate Plan
                            </FormLabel>
                            <Controller
                              name="rateplan"
                              control={control}
                              render={({ field }) => (
                                <ReactSelect
                                  {...field}
                                  focusBorderColor="blue.500"
                                  chakraStyles={chakraStyles}
                                  options={ratePlanFilter}
                                  placeholder="Select Rate Plan"
                                  getOptionLabel={(i) => i.name}
                                  getOptionValue={(option) => option}
                                  size="sm"
                                  closeMenuOnSelect={true}
                                />
                              )}
                            />
                            <Text color="red" fontSize={14} mb="10px" ml="5px">
                              {errors.rateplan?.message}
                            </Text>
                          </FormControl>
                          {rateplan && (
                            <>
                              <Flex direction="column" gap="12px" mb={4}>
                                <Grid
                                  templateColumns={{
                                    lg: "1fr 1fr 1fr",
                                  }}
                                  alignItems="flex-start"
                                  gap="40px"
                                >
                                  <Text>Zone</Text>
                                  <Text>Commission1</Text>
                                  <Text>Commission2</Text>
                                </Grid>
                                {zoneListActive.map((i, index) => (
                                  <ZoneItem
                                    control={control}
                                    key={index}
                                    item={i}
                                    type="commissions"
                                    register={register}
                                    name="commissions"
                                    isChecked={
                                      commissions &&
                                      commissions.includes(i.id.toString())
                                    }
                                  />
                                ))}
                              </Flex>
                              <Flex
                                justify="center"
                                align="center"
                                gap="8px"
                                mt={3}
                                mb={3}
                              >
                                <Box
                                  direction="row"
                                  alignItems="center"
                                  display="block"
                                >
                                  <Button
                                    leftIcon={<RxReset />}
                                    variant="primary"
                                    w="88px"
                                    h="33px"
                                    onClick={() => {
                                      reset();
                                    }}
                                  >
                                    Reset
                                  </Button>
                                </Box>
                                <Box
                                  direction="row"
                                  alignItems="center"
                                  display="block"
                                >
                                  <Button
                                    variant="primary"
                                    w="88px"
                                    h="33px"
                                    onClick={() => confirmAddRatePlanUnit()}
                                  >
                                    Confirm Add
                                  </Button>
                                </Box>
                              </Flex>
                            </>
                          )}
                          {rateplanUnitSelected?.length > 0 && (
                            <SearchTable2
                              tableData={rateplanUnitSelected}
                              columnsData={columnsAddUnitWithRateplanUnit}
                              totalRow={rateplanUnitSelected.length}
                              enableRowActions={false}
                              manualPagination={false}
                              manualSorting={false}
                            />
                          )}
                        </TabPanel>
                        {/* <TabPanel>
                          <Flex gap={5} alignItems="center">
                            <FormControl maxW="400px">
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Sub Account
                              </FormLabel>
                              <Controller
                                name="subAccounts"
                                control={control}
                                render={({ field }) => (
                                  <ReactSelect
                                    {...field}
                                    focusBorderColor="blue.500"
                                    chakraStyles={chakraStyles}
                                    options={listSubAccount}
                                    placeholder="Select Sub Account"
                                    getOptionLabel={(i) => i.email}
                                    getOptionValue={(option) => option}
                                    size="sm"
                                    isMulti
                                  />
                                )}
                              />
                              <Text
                                color="red"
                                fontSize={14}
                                mb="10px"
                                ml="5px"
                              >
                                {errors.rateplan?.message}
                              </Text>
                            </FormControl>
                            <Box
                              direction="row"
                              alignItems="center"
                              display="block"
                            >
                              <Button
                                variant="primary"
                                w="88px"
                                h="33px"
                                onClick={() => confirmAddSubAccount()}
                              >
                                Confirm Add
                              </Button>
                            </Box>
                          </Flex>
                          {subAccountsSelected?.length > 0 && (
                            <SearchTable2
                              tableData={subAccountsSelected}
                              columnsData={columnsDataSubAccount}
                              totalRow={subAccountsSelected.length}
                              listPermissions={[39]}
                              enableRowActions={false}
                              manualPagination={false}
                            />
                          )}
                        </TabPanel> */}
                      </TabPanels>
                    </Tabs>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      type="submit"
                    // isLoading={isSubmitting}
                    >
                      {isCreate ? "Create" : "Update"}
                    </Button>
                    <Button
                      onClick={() => {
                        onClose();
                        reset();
                        setRateplanUnitSelected([]);
                        setValue("channel", []);
                        setValue("users", []);
                        setCurrentUnitID(undefined);
                      }}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </form>
            </Modal>
          </Flex>
        </CardHeader>
        <CardBody>
          <Card>
            <form
              id="user-search-form"
              onSubmit={handleSubmitSearch(onSubmitSearch)}
            >
              <Flex
                direction="row"
                alignItems="center"
                mb={3}
                gap={4}
                flexWrap={"wrap"}
              >
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                    Unit Name
                  </FormLabel>
                  <Input
                    _placeholder={{ color: "gray.400" }}
                    variant="main"
                    type="text"
                    placeholder="Name"
                    minW="80px"
                    maxW="185px"
                    size="sm"
                    fontSize="sm"
                    name="nameSearch"
                    {...registerSearch("nameSearch")}
                    _focus={{ borderColor: "blue.500" }}
                  />
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                    Unit Code
                  </FormLabel>
                  <Input
                    _placeholder={{ color: "gray.400" }}
                    variant="main"
                    type="text"
                    placeholder="Name"
                    minW="80px"
                    maxW="185px"
                    size="sm"
                    fontSize="sm"
                    name="codeSearch"
                    {...registerSearch("codeSearch")}
                    _focus={{ borderColor: "blue.500" }}
                  />
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Channel
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    size="sm"
                    name="channelSearch"
                    {...registerSearch("channelSearch")}
                    width="200px"
                  >
                    <option value="">All</option>
                    {listChannel.map((i) => (
                      <option key={i.id} value={i.id}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Liability
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    defaultValue="0"
                    size="sm"
                    name="isLiabilitySearch"
                    {...registerSearch("isLiabilitySearch")}
                    width="100px"
                  >
                    <option value="0">All</option>
                    <option value="1">Yes</option>
                    <option value="2">No</option>
                  </Select>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Status
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    defaultValue="1"
                    size="sm"
                    name="statusSearch"
                    {...registerSearch("statusSearch")}
                    width="100px"
                  >
                    {STATUS.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Flex>
              <Flex justify="center" align="center" gap="8px" mt={3}>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<RxReset />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    onClick={() => {
                      resetSearch();
                    }}
                  >
                    Reset
                  </Button>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<BsSearch />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    type="submit"
                  >
                    Search
                  </Button>
                </Box>
              </Flex>
            </form>
          </Card>
          <SearchTable2
            tableData={newListUnit}
            columnsData={columnsDataUnit}
            totalRow={totalRows.units}
            edit={editUnit}
            listPermissions={[17]}
            actionsActive={["edit"]}
            loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
            isFetching={isFetching}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTableUnits;
