import {
  Portal,
  Box,
  Stack,
  useDisclosure,
  useColorModeValue,
  Image,
  Modal,
  ModalBody,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Flex,
  Input,
  ModalOverlay,
  ModalContent,
  Text,
  ModalHeader,
} from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";
import "assets/css/plugin-styles.css";
import Configurator from "components/Configurator/Configurator";
import Footer from "components/Footer/Footer.js";
// Custom components
import MainPanel from "components/Layout/MainPanel";
import PanelContainer from "components/Layout/PanelContainer";
import PanelContent from "components/Layout/PanelContent";
// Layout components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { SidebarContext } from "contexts/SidebarContext";
import React, { useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css"; // ES6
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import routesArray from "routes.js";
import logo from "assets/img/logo.png";
import { authAtom } from "state/recoil-root";
import { useUserActions } from "hooks/useUserActions";
import { useRecoilValue } from "recoil";
import { QrCodeICon } from "components/Icons/Icons";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { useBookingActions } from "hooks/useBookingActions";
import { debounce } from "lodash";

export default function Dashboard(props) {
  const routes = routesArray();
  const userActions = useUserActions();
  const bookingActions = useBookingActions();
  const user = JSON.parse(localStorage.getItem("user"));
  const [searchValue, setSearchValue] = useState("");
  const [bookingsSearch, setBookingsSearch] = useState([]);
  const [notHaveValue, setNotHaveValue] = useState("");
  const [done, setDone] = useState(false);
  const {
    isOpen: isOpenSearch,
    onOpen: onOpenSearch,
    onClose: onCloseSearch,
  } = useDisclosure();
  const {
    isOpen: isOpenScanQR,
    onOpen: onOpenScanQR,
    onClose: onCloseScanQR,
  } = useDisclosure();
  const history = useHistory();
  const checkTokenExpiration = async () => {
    try {
      if (user) {
        const expTime = parseInt(user.exp * 1000);
        const now = parseInt(Date.now());
        if (now > expTime) {
          await userActions.refreshToken();
        }
      }
    } catch (error) {
      console.log(error, "error");
      if (error) {
        localStorage.removeItem("user");
        history.push("/auth/authentication/sign-in");
      }
    }
  };
  useEffect(async () => {
    setDone(false);
    await checkTokenExpiration();
    setDone(true);
    function handleKeyDown(event) {
      if (event.keyCode === 113) {
        onOpenSearch();
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const debouncedSearch = debounce((query) => {
    search(query);
  }, 500);
  useEffect(() => {
    if (searchValue) {
      setNotHaveValue("");
      setBookingsSearch([]);
      debouncedSearch(searchValue);
    }
    return debouncedSearch.cancel;
  }, [searchValue]);
  const { ...rest } = props;
  // states and functions
  const [fixed, setFixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(275);
  // ref for main panel div
  const mainPanel = React.createRef();
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveRoutePath = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoutePath(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoutePath(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].path;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondaryNavbar;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };
  let bgBoxHeight = "100vh";
  let bgBoxColor = useColorModeValue("blue.500", "navy.900");

  const { isOpen, onOpen, onClose } = useDisclosure();
  document.documentElement.dir = "ltr";
  document.documentElement.layout = "admin";
  function handleClearSearch() {
    setSearchValue("");
    setNotHaveValue("");
  }
  async function search(val) {
    const {
      data: { booking: bookingsSearch },
    } = await bookingActions.quickSearchBooking(val, 0, 10);
    if (bookingsSearch) {
      setBookingsSearch(bookingsSearch);
      if (bookingsSearch.length === 1) {
        history.push(`/admin/booking/create/detail/${bookingsSearch[0].id}`);
        onCloseSearch();
        setBookingsSearch([]);
        handleClearSearch();
      }
    } else {
      setNotHaveValue("No Value");
    }
  }
  const scanQRCode = (val) => {
    try {
      setSearchValue(val);
      search(val);
    } catch (error) {
      console.warn(error);
      setBookingsSearch([]);
    }
    onCloseScanQR();
  };
  return (
    <Box>
      <SidebarContext.Provider
        value={{
          sidebarWidth,
          setSidebarWidth,
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Box
          minH={bgBoxHeight}
          h="100% !important"
          w="100%"
          position="absolute"
          bg={bgBoxColor}
          top="0"
        />
        <Sidebar
          routes={routes}
          logo={
            <Stack
              direction="row"
              spacing="12px"
              align="center"
              justify="center"
            >
              <Image src={logo} alt="logo" width={100} height={70} />
            </Stack>
          }
          display="none"
          {...rest}
        />
        {done && (
          <MainPanel
            w={{
              base: "100%",
              xl: `calc(100% - ${sidebarWidth === 275 ? "250" : "0"}px)`,
            }}
          >
            <Portal>
              <Box>
                <AdminNavbar
                  onOpen={onOpen}
                  logoText={"Argon Dashboard Chakra PRO"}
                  brandText={getActiveRoute(routes)}
                  secondary={getActiveNavbar(routes)}
                  path={getActiveRoutePath(routes)}
                  fixed={fixed}
                  {...rest}
                  onOpenSearch={onOpenSearch}
                />
              </Box>
            </Portal>

            {getRoute() ? (
              <PanelContent>
                <PanelContainer>
                  <Switch>
                    {getRoutes(routes)}
                    <Redirect from="/admin" to="/admin/setting/profile" />
                  </Switch>
                </PanelContainer>
              </PanelContent>
            ) : null}
            <Box>
              <Footer />
            </Box>
            <Configurator
              secondary={getActiveNavbar(routes)}
              isOpen={isOpen}
              onClose={onClose}
              isChecked={fixed}
              onSwitch={(value) => {
                setFixed(value);
              }}
            />
          </MainPanel>
        )}
      </SidebarContext.Provider>
      <Modal isOpen={isOpenSearch} onClose={onCloseSearch} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <InputGroup maxW="600px">
              <InputLeftElement pointerEvents="none">
                <SearchIcon />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Code/Special Request"
                value={searchValue}
                borderRadius={10}
                border="1px solid #C4C4C4"
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <InputRightElement right={3}>
                {searchValue && (
                  <CloseIcon
                    cursor="pointer"
                    onClick={handleClearSearch}
                    w="12px"
                    h="12px"
                  />
                )}
                <QrCodeICon
                  style={{
                    cursor: "pointer",
                    borderLeft: "1px solid black",
                  }}
                  pl="0"
                  ml="10px"
                  w={{ sm: "24px", lg: "48px" }}
                  h={{ sm: "24px", lg: "28px" }}
                  onClick={() => {
                    onOpenScanQR();
                  }}
                />
              </InputRightElement>
            </InputGroup>

            {searchValue && bookingsSearch.length > 0 ? (
              <Flex
                border={"1px solid #E2E8F0"}
                direction="column"
                w={"100%"}
                gap="20px"
              >
                <Flex direction="column" maxH="55vh" overflowY="auto">
                  {bookingsSearch.map((i) => (
                    <Flex
                      borderBottom="1px solid #E2E8F0"
                      p={"8px 22px"}
                      cursor="pointer"
                      direction="column"
                      gap={2}
                      _hover={{ background: "blue.300" }}
                      key={i.code}
                      onClick={() => {
                        history.push(`/admin/booking/create/detail/${i.id}`);
                        setSearchValue("");
                        onCloseSearch();
                      }}
                    >
                      <Text fontSize={12} fontWeight={"bold"}>
                        {i.code} - {i.zone.name && `${i.zone.name} -`}{" "}
                        {i.unit.name}
                      </Text>
                      <Text fontSize={11} fontWeight={"300"}>
                        Quanlity: {parseInt(i.adult) + parseInt(i.child)} -
                        Booker: {i.booker_name} - Audience: {i.guest_name}
                      </Text>
                      <Text fontSize={11} fontWeight={"300"}>
                        Special Request:{" "}
                        <div
                          dangerouslySetInnerHTML={{ __html: i.notes }}
                        ></div>
                      </Text>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            ) : (
              <Flex>{notHaveValue}</Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenScanQR} onClose={onCloseScanQR}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Scan QR</ModalHeader>
          <ModalBody>
            <QrScanner
              onDecode={(result) => scanQRCode(result)}
              onError={(error) => alert(error?.message)}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
