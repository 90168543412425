import {
  Checkbox,
  Input,
  Flex,
  Text,
  Grid,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { NumericFormat } from "react-number-format";
import { Controller } from "react-hook-form";

export default function ZoneItem({
  item,
  register,
  type,
  name,
  showItemChoose,
  venueItemChoose,
  isChecked,
  control,
  disabledAndChecked,
  activeTab,
  disabledItem = false,
}) {
  const [isDisabledCheckBox, setIsDisabledCheckbox] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      if (disabledItem) {
        setIsDisabledCheckbox(true);
      }
    }, 500);
  }, []);
  return (
    <Grid
      templateColumns={{
        lg: "1fr 6fr",
      }}
      alignItems="flex-start"
      gap="40px"
      w={"100%"}
    >
      {name === "voucher" ? (
        <Flex align="center">
          {disabledAndChecked ? (
            <Checkbox
              disabled={disabledAndChecked && true}
              defaultChecked={disabledAndChecked && true}
              value={`${showItemChoose.id}_${venueItemChoose.id}_${item.Zone.id}`}
              {...register(name)}
            />
          ) : (
            <Checkbox
              value={`${showItemChoose.id}_${venueItemChoose.id}_${item.id}`}
              {...register(name)}
            />
          )}

          {disabledAndChecked ? (
            <Text ml={2}>{item.Zone.name}</Text>
          ) : (
            <Text ml={2}>{item.name}</Text>
          )}
        </Flex>
      ) : (
        <Flex align="center">
          {disabledItem ? (
            <Controller
              control={control}
              name={name}
              render={({ field }) => (
                <Checkbox
                  value={item.id.toString()}
                  isChecked={isChecked}
                  {...field}
                  disabled={isDisabledCheckBox}
                />
              )}
            />
          ) : type === "VOUCHER" && activeTab === "2" ? (
            <Radio value={item.id.toString()} isChecked={isChecked}></Radio>
          ) : (
            <Checkbox
              value={item.id.toString()}
              isChecked={isChecked}
              {...register(name)}
              disabled={isDisabledCheckBox}
            />
          )}

          <Text ml={2}>{item.name}</Text>
        </Flex>
      )}
      <Flex gap={4}>
        {name === "voucher" && disabledAndChecked && (
          <>
            <Controller
              control={control}
              name={`salePrice[${showItemChoose.id}_${venueItemChoose.id}_${item.Zone.id}]`}
              render={({ field }) => (
                <NumericFormat
                  customInput={Input}
                  thousandSeparator=","
                  fontSize="sm"
                  ms="4px"
                  placeholder="Policy Price"
                  size="sm"
                  {...field}
                  defaultValue={item.amount}
                  disabled={disabledAndChecked}
                />
              )}
            />
          </>
        )}
        {name === "voucher" && !disabledAndChecked && (
          <>
            <Controller
              control={control}
              name={`salePrice[${showItemChoose.id}_${venueItemChoose.id}_${item.id}]`}
              render={({ field }) => (
                <NumericFormat
                  customInput={Input}
                  thousandSeparator=","
                  fontSize="sm"
                  ms="4px"
                  placeholder="Policy Price"
                  size="sm"
                  disabled={!isChecked}
                  {...field}
                />
              )}
            />
          </>
        )}
        {name === "commissions" && (
          <>
            <Controller
              control={control}
              name={`commission1[${item.id}]`}
              render={({ field }) => (
                <NumericFormat
                  customInput={Input}
                  thousandSeparator=","
                  fontSize="sm"
                  ms="4px"
                  placeholder="Commissions 1"
                  size="sm"
                  {...field}
                  disabled={!isChecked}
                  defaultValue={0}
                />
              )}
            />
            <Controller
              control={control}
              name={`commission2[${item.id}]`}
              render={({ field }) => (
                <NumericFormat
                  customInput={Input}
                  thousandSeparator=","
                  fontSize="sm"
                  ms="4px"
                  placeholder="Commissions 2"
                  size="sm"
                  {...field}
                  disabled={!isChecked}
                  defaultValue={0}
                />
              )}
            />
          </>
        )}
        {name === "zone_id" && (
          <>
            {type !== "PROMOTION" && (
              <Controller
                control={control}
                name={`adult_amount[${item.id}]`}
                render={({ field }) => (
                  <NumericFormat
                    customInput={Input}
                    thousandSeparator=","
                    fontSize="sm"
                    ms="4px"
                    maxW="400px"
                    placeholder={`${
                      type === "COMBO" ? "Sale Price" : "Adult Price"
                    } `}
                    size="sm"
                    {...field}
                    defaultValue={0}
                    disabled={!isChecked || disabledItem}
                  />
                )}
              />
            )}
            {type !== "COMBO" ? (
              type !== "PROMOTION" ? (
                <Controller
                  control={control}
                  name={`child_amount[${item.id}]`}
                  render={({ field }) => (
                    <NumericFormat
                      customInput={Input}
                      thousandSeparator=","
                      fontSize="sm"
                      ms="4px"
                      placeholder="Child Price"
                      size="sm"
                      {...field}
                      defaultValue={0}
                      disabled={!isChecked || disabledItem}
                    />
                  )}
                />
              ) : null
            ) : (
              <Controller
                control={control}
                name={`adult_extra_cost[${item.id}]`}
                render={({ field }) => (
                  <NumericFormat
                    customInput={Input}
                    thousandSeparator=","
                    fontSize="sm"
                    ms="4px"
                    placeholder="Extra Cost"
                    size="sm"
                    {...field}
                    defaultValue={0}
                    disabled={!isChecked || disabledItem}
                  />
                )}
              />
            )}
            {type === "FAMILY PACKAGE" && (
              <>
                <Controller
                  control={control}
                  name={`extra_adult_amount[${item.id}]`}
                  render={({ field }) => (
                    <NumericFormat
                      customInput={Input}
                      thousandSeparator=","
                      fontSize="sm"
                      ms="4px"
                      placeholder="Extra Adult Amount"
                      size="sm"
                      {...field}
                      defaultValue={0}
                      disabled={!isChecked || disabledItem}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`extra_child_amount[${item.id}]`}
                  render={({ field }) => (
                    <NumericFormat
                      customInput={Input}
                      thousandSeparator=","
                      fontSize="sm"
                      ms="4px"
                      placeholder="Extra Child Amount"
                      size="sm"
                      {...field}
                      defaultValue={0}
                      disabled={!isChecked || disabledItem}
                    />
                  )}
                />
              </>
            )}
          </>
        )}
      </Flex>
    </Grid>
  );
}
