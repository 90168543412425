import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
  Text,
  useToast,
} from "@chakra-ui/react";
import CoverImage from "assets/img/CoverImage.png";
import React, { useEffect } from "react";
import AuthCover from "layouts/AuthCover";
import { useRecoilValue } from "recoil";
import { authAtom } from "state/auth";
import { useUserActions } from "hooks/useUserActions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

function RecoveryPassword() {
  const bgForm = useColorModeValue("white", "navy.800");
  const location = useLocation();
  const code = new URLSearchParams(location.search).get("code");
  const auth = useRecoilValue(authAtom);
  const userActions = useUserActions();
  const validationSchema = Yup.object().shape({
    code: Yup.string().required("This field is required"),
    new_password: Yup.string().required("This field is required"),
    confirm_password: Yup.string()
      .required("This field is required")
      .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, setError, formState, setValue } =
    useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const toast = useToast();
  const history = useHistory();

  useEffect(() => {
    if (auth) history.push("/admin/dashboard");
    setValue("code", code);
  }, [code]);
  function onSubmit({ code, confirm_password, new_password }) {
    try {
      userActions
        .recoverPassword({ code, confirm_password, new_password })
        .then((res) => {
          if (res.status === 200) {
            toast({
              description: "Password updated success",
              status: "success",
              duration: 2000,
              position: "top-right",
            });
            history.push("/auth/authentication/sign-in");
          }
        })
        .catch((err) => {
          setError("apiError", { message: err.response.data.meta.message });
        });
    } catch (error) {}
  }
  return (
    <AuthCover image={CoverImage}>
      <Flex
        w="100%"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb="60px"
        mt={{ base: "60px", md: "160px" }}
      >
        <Flex
          zIndex="2"
          direction="column"
          w="445px"
          background="transparent"
          borderRadius="15px"
          p="40px"
          mx={{ base: "100px" }}
          mb={{ base: "20px", md: "auto" }}
          m="20px"
          bg={bgForm}
          boxShadow={useColorModeValue(
            "0px 5px 14px rgba(0, 0, 0, 0.05)",
            "unset"
          )}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Text color={"green.300"} mb={4}>
              Check your email! Instructions to reset your password will be
              arriving there
            </Text>
            <FormControl isRequired>
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Code
              </FormLabel>
              <Input
                fontSize="sm"
                ms="4px"
                type="text"
                placeholder="Your Code"
                mb={errors.code?.message ? "5px" : "24px"}
                size="lg"
                name="code"
                {...register("code")}
                isInvalid={errors.code}
                errorBorderColor="red.300"
              />
              <Text color="red" fontSize={14} mb="10px" ml="5px">
                {errors.code?.message}
              </Text>
            </FormControl>
            <FormControl isRequired>
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                New Password
              </FormLabel>
              <Input
                fontSize="sm"
                ms="4px"
                type="password"
                placeholder="Your New Password"
                mb={errors.new_password?.message ? "5px" : "24px"}
                size="lg"
                name="new_password"
                {...register("new_password")}
                isInvalid={errors.new_password}
                errorBorderColor="red.300"
              />
              <Text color="red" fontSize={14} mb="10px" ml="5px">
                {errors.new_password?.message}
              </Text>
            </FormControl>
            <FormControl isRequired>
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Confirm New Password
              </FormLabel>
              <Input
                fontSize="sm"
                ms="4px"
                type="password"
                placeholder="Confirm your password"
                mb={errors.confirm_password?.message ? "5px" : "24px"}
                size="lg"
                {...register("confirm_password")}
                name="confirm_password"
                isInvalid={errors.confirm_password?.message}
                errorBorderColor="red.300"
              />
              <Text color="red" mb="10px" ml="5px">
                {errors.confirm_password?.message}
              </Text>
            </FormControl>
            <Text color="red" ml="5px">
              {errors.apiError && errors.apiError?.message}
            </Text>
            <Button
              fontSize="10px"
              variant="primary"
              fontWeight="bold"
              w="100%"
              h="45"
              mb="24px"
              type="submit"
              isLoading={isSubmitting}
            >
              RECOVERY PASSWORD
            </Button>
          </form>
        </Flex>
      </Flex>
    </AuthCover>
  );
}

export default RecoveryPassword;