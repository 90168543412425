import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useUserActions } from "hooks/useUserActions";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { columnsDataSubAccount } from "variables/columnsData";
import { totalRow } from "state/roles-permissions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { AddIcon } from "@chakra-ui/icons";
import { checkForbidden } from "helpers/checkForbidden";
import { BsSearch } from "react-icons/bs";
import { RxReset } from "react-icons/rx";
import SearchTable2 from "components/Tables/SearchTable2";
import { subAccountAtom } from "state/users";

function DataTableSubAccounts() {
  const textColor = useColorModeValue("gray.700", "white");
  const userActions = useUserActions();
  const subAccounts = useRecoilValue(subAccountAtom);
  const totalRows = useRecoilValue(totalRow);
  const [isCreate, setIsCreate] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentID, setCurrentID] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [newSubAccounts, setNewSubAccounts] = useState([]);
  const UserStatus = [
    { Id: "0", Name: "All" },
    { Id: "1", Name: "Active" },
    { Id: "2", Name: "InActive" },
  ];

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("This field is required")
      .email("Invalid Email"),
    fullname: Yup.string().required("This field is required"),
    phone: Yup.string().required("This field is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    setError,
    formState,
    reset,
    setValue,
  } = useForm(formOptions);
  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    reset: resetSearch,
    setValue: setValueSearch,
    watch,
  } = useForm();
  const { errors, isSubmitting } = formState;
  const toast = useToast();
  const fullNameSearch = watch("fullnameSearch");
  const emailSearch = watch("emailSearch");
  const statusSearch = watch("statusSearch");
  const phoneSearch = watch("phoneSearch");

  useEffect(() => {
    if (subAccounts) {
      const newSubAccount = subAccounts.map((i) => ({
        ...i,
        typeSwitch: "subAccount",
        filters: {
          fullname: fullNameSearch,
          status: statusSearch,
          phone: phoneSearch,
          email: emailSearch,
        },
      }));
      setNewSubAccounts(newSubAccount);
    }
  }, [subAccounts]);

  async function edit(val) {
    await userActions.getSubAccountById(val).then((res) => {
      if (res.status === 200) {
        setValue("email", res.data.data.email);
        setValue("fullname", res.data.data.fullname);
        setValue("phone", res.data.data.phone);
        setValue("isActive", res.data.data.status === 1 ? true : false);
        setCurrentID(res.data.data.id);
      }
    });
    setIsCreate(false);
    onOpen();
  }

  async function onSubmitSearch({
    fullnameSearch,
    emailSearch,
    phoneSearch,
    statusSearch,
  }) {
    setIsFetching(true);
    await userActions.getListSubAccount(
      {
        fullname: fullnameSearch,
        email: emailSearch,
        status: statusSearch,
        phone: phoneSearch,
      },
      0,
      localStorage && localStorage.getItem("currentPageSizeStore")
      ? localStorage.getItem("currentPageSizeStore")
      : 10
    );
    setIsFetching(false);
  }

  async function onSubmit({ email, fullname, phone, isActive, password }) {
    try {
      if (isCreate) {
        await userActions.createSubAccount({
          email,
          fullname,
          phone,
          status: isActive ? 1 : 2,
          password,
        });
        toast({
          description: `Create Sub Account successful`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      } else {
        await userActions.updateSubAccount(
          {
            email,
            fullname,
            phone,
            status: isActive ? 1 : 2,
          },
          currentID
        );
        toast({
          description: `Edit Sub Account successful`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
      reset();
      setIsFetching(true);
      await userActions.getListSubAccount(
        {
          fullname: fullNameSearch,
          email: emailSearch,
          status: statusSearch,
        },
        0,
        10
      );
      setIsFetching(false);
      onClose();
    } catch (err) {
      setError("apiError", {
        message: err.response?.data?.meta?.message ?? err.message,
      });
    }
  }

  const recallApi = async (fetchDataOptions) => {
    await userActions.getListSubAccount(
      {
        status: statusSearch ? statusSearch : 1,
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
  };

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex direction="column" display="block">
            <Text
              float="left"
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              mb="6px"
            >
              Sub Account Management
            </Text>
            <Stack float="right" direction="row" spacing={4}>
              {checkForbidden(38) && (
                <Button
                  size="sm"
                  onClick={() => {
                    onOpen();
                    setIsCreate(true);
                  }}
                  leftIcon={<AddIcon />}
                  colorScheme="green"
                  variant="outline"
                >
                  Add Sub Account
                </Button>
              )}
            </Stack>
            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpen}
              onClose={() => {
                onClose();
                reset();
              }}
              size="3xl"
            >
              <ModalOverlay />
              <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                  <ModalHeader>
                    {isCreate ? "Create New Sub Account" : "Edit Sub Account"}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <Grid
                      gap={{ lg: "16px", sm: "8px" }}
                      templateColumns={{ lg: "repeat(2, 1fr)", sm: "1fr" }}
                    >
                      <Flex direction="column">
                        <FormControl isRequired>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Email
                          </FormLabel>
                          <Input
                            fontSize="sm"
                            ms="4px"
                            type="text"
                            placeholder="Email"
                            mb={
                              errors.email?.message
                                ? "5px"
                                : { lg: "24px", sm: "0" }
                            }
                            size="md"
                            name="email"
                            {...register("email")}
                            isInvalid={errors.email}
                            errorBorderColor="red.300"
                            disabled={!isCreate}
                          />
                          <Text color="red" fontSize={14} mb="10px" ml="5px">
                            {errors.email?.message}
                          </Text>
                        </FormControl>
                      </Flex>
                      <Flex direction="column">
                        <FormControl isRequired>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Full name
                          </FormLabel>
                          <Input
                            fontSize="sm"
                            ms="4px"
                            type="text"
                            placeholder="Full Name"
                            mb={
                              errors.fullname?.message
                                ? "5px"
                                : { lg: "24px", sm: "0" }
                            }
                            size="md"
                            name="fullname"
                            {...register("fullname")}
                            isInvalid={errors.fullname}
                            errorBorderColor="red.300"
                          />
                          <Text color="red" fontSize={14} mb="10px" ml="5px">
                            {errors.fullname?.message}
                          </Text>
                        </FormControl>
                      </Flex>
                    </Grid>
                    <Grid
                      gap={{ lg: "16px", sm: "8px" }}
                      templateColumns={{ lg: "repeat(2, 1fr)", sm: "1fr" }}
                    >
                      <Flex direction="column">
                        <FormControl isRequired>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Phone
                          </FormLabel>
                          <Input
                            fontSize="sm"
                            ms="4px"
                            type="number"
                            placeholder="Phone"
                            mb={
                              errors.phone?.message
                                ? "5px"
                                : { lg: "24px", sm: "0" }
                            }
                            size="md"
                            name="phone"
                            {...register("phone")}
                            isInvalid={errors.phone}
                            errorBorderColor="red.300"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <Text color="red" fontSize={14} mb="10px" ml="5px">
                            {errors.phone?.message}
                          </Text>
                        </FormControl>
                      </Flex>
                      {isCreate && (
                        <Flex direction="column">
                          <FormControl isRequired>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="normal"
                            >
                              Password
                            </FormLabel>
                            <Input
                              fontSize="sm"
                              ms="4px"
                              type="password"
                              placeholder="Password"
                              mb={
                                errors.password?.message
                                  ? "5px"
                                  : { lg: "24px", sm: "0" }
                              }
                              size="md"
                              name="password"
                              {...register("password")}
                              isInvalid={errors.password}
                              errorBorderColor="red.300"
                            />
                            <Text color="red" fontSize={14} mb="10px" ml="5px">
                              {errors.password?.message}
                            </Text>
                          </FormControl>
                        </Flex>
                      )}
                    </Grid>
                    <Flex direction="column">
                      <FormControl>
                        <Switch
                          id="isChecked"
                          defaultChecked={isCreate && true}
                          name="isActive"
                          {...register("isActive")}
                          size="sm"
                          ms="4px"
                        >
                          Active
                        </Switch>
                      </FormControl>
                    </Flex>
                    <Text color="red" ml="5px">
                      {errors.apiError && errors.apiError?.message}
                    </Text>
                  </ModalBody>
                  <ModalFooter pt="0">
                    <Button
                      colorScheme="blue"
                      mr={3}
                      type="submit"
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      {isCreate ? "Create" : "Update"}
                    </Button>
                    <Button
                      onClick={() => {
                        onClose();
                        reset();
                      }}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </form>
            </Modal>
          </Flex>
        </CardHeader>
        <CardBody>
          <Card>
            <form
              id="user-search-form"
              onSubmit={handleSubmitSearch(onSubmitSearch)}
            >
              <Flex
                direction="row"
                alignItems="center"
                mb={3}
                gap={4}
                flexWrap={"wrap"}
              >
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                    Full Name
                  </FormLabel>
                  <Input
                    _placeholder={{ color: "gray.400" }}
                    variant="main"
                    type="text"
                    placeholder="Full Name"
                    minW="80px"
                    maxW="185px"
                    size="sm"
                    fontSize="sm"
                    name="fullnameSearch"
                    {...registerSearch("fullnameSearch")}
                    _focus={{ borderColor: "blue.500" }}
                  />
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                    Email
                  </FormLabel>
                  <Input
                    _placeholder={{ color: "gray.400" }}
                    variant="main"
                    type="text"
                    placeholder="Email"
                    minW="80px"
                    maxW="185px"
                    size="sm"
                    fontSize="sm"
                    name="emailSearch"
                    {...registerSearch("emailSearch")}
                    _focus={{ borderColor: "blue.500" }}
                  />
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                    Phone
                  </FormLabel>
                  <Input
                    _placeholder={{ color: "gray.400" }}
                    variant="main"
                    type="text"
                    placeholder="Phone"
                    minW="80px"
                    maxW="185px"
                    size="sm"
                    fontSize="sm"
                    name="emailSearch"
                    {...registerSearch("phoneSearch")}
                    _focus={{ borderColor: "blue.500" }}
                  />
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Status
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    defaultValue="1"
                    size="sm"
                    name="statusSearch"
                    {...registerSearch("statusSearch")}
                    width="100px"
                  >
                    {UserStatus.map((item, index) => (
                      <option key={index} value={item.Id}>
                        {item.Name}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Flex>
              <Flex justify="center" align="center" gap="8px" mt={3}>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<RxReset />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    onClick={() => {
                      resetSearch();
                      setValueSearch("roleSearch", null);
                    }}
                  >
                    Reset
                  </Button>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<BsSearch />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    type="submit"
                  >
                    Search
                  </Button>
                </Box>
              </Flex>
            </form>
          </Card>
          <SearchTable2
            tableData={newSubAccounts}
            columnsData={columnsDataSubAccount}
            totalRow={totalRows.newSubAccounts}
            edit={edit}
            actionsActive={["edit"]}
            listPermissions={[39]}
            loadData={(page) => recallApi(page)}
            isFetching={isFetching}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTableSubAccounts;
