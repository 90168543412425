import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Switch,
  Stack,
  Grid,
  Image,
  Select,
  Tab,
  TabList,
  Tabs,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";
import { parseISO } from "date-fns";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SearchTable2 from "components/Tables/SearchTable2";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { totalRow } from "state/roles-permissions";
import { AddIcon } from "@chakra-ui/icons";
import { checkForbidden } from "helpers/checkForbidden";
import { useCMSActions } from "hooks/useCMS";
import Editor from "components/Editor/Editor2";
import { useRatePlanActions } from "hooks/useRatePlanActions";
import { columnsDataOffer } from "variables/columnsData";
import { offersAtom, offersGroupAtom, locationWebAtom } from "state/cms";
import DatePickerInput from "components/CustomDateInput/CustomDateInput";
import { findValue } from "helpers/helpers";

function DataTableOffers() {
  const textColor = useColorModeValue("gray.700", "white");
  const listNews = useRecoilValue(offersAtom);
  const listOfferGroup = useRecoilValue(offersGroupAtom);
  const listLocation = useRecoilValue(locationWebAtom);

  const totalRows = useRecoilValue(totalRow);
  const cmsActions = useCMSActions();
  const ratePlanActions = useRatePlanActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCreate, setIsCreate] = useState(true);
  const [currentNewsID, setCurrentNewsID] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [newListNews, setNewListNews] = useState([]);
  const [previewThumbnail, setPreviewThumbnail] = useState("");
  const [previewThumbnailVN, setPreviewThumbnailVN] = useState("");

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    control,
    watch,
  } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const toast = useToast();
  const thumbnailURL = watch("thumbnail_url");
  const thumbnailURLVN = watch("thumbnail_url_vn");

  useEffect(async () => {
    if (thumbnailURL && thumbnailURL?.length > 0) {
      const formData = new FormData();
      formData.append("file", thumbnailURL[0]);
      const {
        data: { data },
      } = await ratePlanActions.uploadImageRatePlanConfig(formData);
      setPreviewThumbnail(data.url);
    }
  }, [thumbnailURL]);
  useEffect(() => {
    if (listNews) {
      const toolTypes = listNews.map((i) => ({
        ...i,
        filters: { name: "", status: 1 },
        typeSwitch: "offers",
      }));
      setNewListNews(toolTypes);
    }
  }, [listNews]);
  useEffect(async () => {
    if (thumbnailURLVN && thumbnailURLVN?.length > 0) {
      const formData = new FormData();
      formData.append("file", thumbnailURLVN[0]);
      const {
        data: { data },
      } = await ratePlanActions.uploadImageRatePlanConfig(formData);
      setPreviewThumbnailVN(data.url);
    }
  }, [thumbnailURLVN]);
  useEffect(() => {
    cmsActions.getListOfferGroups();
    cmsActions.getListLocations();
  }, []);
  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    await cmsActions.getListOffers(
      {
        name: "",
        status: 1,
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
    setIsFetching(false);
  };
  async function onSubmit({
    name,
    description,
    short_description,
    id,
    status,
    meta_description,
    meta_keywords,
    meta_title,
    og_type,
    og_url,
    og_title,
    og_description,
    og_image,
    isActive,
    slug,
    policy,
    offer_group_id,
    start_date,
    end_date,
    location_id,
  }) {
    const params = {
      name,
      description,
      short_description,
      id,
      thumbnail_url: previewThumbnail,
      status,
      meta_description,
      meta_keywords,
      meta_title,
      og_type,
      og_url,
      og_title,
      og_description,
      og_image,
      status: isActive ? 1 : 2,
      slug,
      policy,
      start_date,
      end_date,
      offer_group_id: parseInt(offer_group_id),
      location_id: parseInt(location_id),
    };
    const listKeys = [
      "name",
      "description",
      "short_description",
      "thumbnail_url",
      "policy",
      "meta_description",
      "meta_keywords",
      "meta_title",
      "og_type",
      "og_url",
      "og_title",
      "og_description",
      "og_image",
    ];
    const languages = listKeys
      .map((i) => {
        if (watch(`${i}_vn`)) {
          if (i === "thumbnail_url") {
            return {
              code: "vi",
              locale_key: i,
              locale_value: previewThumbnailVN,
            };
          }
          return {
            code: "vi",
            locale_key: i,
            locale_value: watch(`${i}_vn`),
          };
        } else {
          return undefined;
        }
      })
      .filter(Boolean);
    params.languages = languages;
    try {
      let res;
      if (isCreate) {
        res = await cmsActions.createOffers(params);
      } else {
        res = await cmsActions.updateOffers(params, currentNewsID);
      }

      if (res.status === 200) {
        onClose();
        reset();
        setPreviewThumbnail("");
        setPreviewThumbnailVN("");
        const message = isCreate
          ? "Create Show Web success"
          : "Update Show Web success";
        toast({
          description: message,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        await cmsActions.getListOffers(
          {
            name: "",
            status: 1,
          },
          0,
          localStorage && localStorage.getItem("currentPageSizeStore")
        );
      }
    } catch (err) {
      console.warn(err);
      toast({
        description: err.response,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  async function editNews(newsID) {
    const newsData = await cmsActions.getDetailOffers(newsID);
    if (newsData) {
      const {
        name,
        description,
        id,
        thumbnail_url,
        status,
        meta_description,
        meta_keywords,
        meta_title,
        og_type,
        og_url,
        og_title,
        og_description,
        og_image,
        slug,
        offer_group,
        short_description,
        policy,
        start_date,
        end_date,
        location,
        languages
      } = newsData.data.data;
      setValue("name", name);
      setValue("description", description);
      setValue("short_description", short_description);
      setValue("policy", policy);
      setPreviewThumbnail(thumbnail_url);
      setValue("meta_description", meta_description);
      if (end_date) {
        setValue("end_date", parseISO(end_date), 1);
      }
      if (start_date) {
        setValue("start_date", parseISO(start_date), 1);
      }

      setValue("meta_keywords", meta_keywords);
      setValue("meta_title", meta_title);
      setValue("og_type", og_type);
      setValue("og_url", og_url);
      setValue("og_title", og_title);
      setValue("og_description", og_description);
      setValue("og_image", og_image);
      setValue("slug", slug);
      setValue("isActive", status === 1 ? true : false);
      setValue("offer_group_id", offer_group.id);
      setValue("location_id", location.id);

      setCurrentNewsID(id);
      if (languages) {
        languages.forEach((e) => {
          if (e.locale_key === "image_url" || e.locale_key === "thumbnail_url")
            return;
          setValue(`${e.locale_key}_vn`, e.locale_value);
        });
        setPreviewThumbnailVN(findValue(languages, "thumbnail_url"));
      }
    }
    setIsCreate(false);
    onOpen();
  }

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex direction="column" display="block">
            <Text
              float="left"
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              mb="6px"
            >
              Offers Management
            </Text>
            <Stack float="right" direction="row" spacing={4}>
              {checkForbidden(42) && (
                <Button
                  size="sm"
                  onClick={() => {
                    onOpen();
                    setIsCreate(true);
                  }}
                  leftIcon={<AddIcon />}
                  colorScheme="green"
                  variant="outline"
                >
                  Add Offers
                </Button>
              )}
            </Stack>
            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpen}
              onClose={() => {
                onClose();
                reset();
                setPreviewThumbnail("");
              }}
              size="6xl"
            >
              <ModalOverlay />
              <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                  <ModalHeader>
                    {isCreate ? "Add Offer" : "Edit Offer"}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <Tabs isFitted variant="enclosed">
                      <TabList>
                        <Tab
                          _focus={{ outline: "none" }}
                          _selected={{ color: "white", bg: "blue.500" }}
                          fontWeight={"bold"}
                          fontSize={"18px"}
                        >
                          English
                        </Tab>
                        <Tab
                          _focus={{ outline: "none" }}
                          _selected={{ color: "white", bg: "blue.500" }}
                          fontWeight={"bold"}
                          fontSize={"18px"}
                        >
                          Viet Nam
                        </Tab>
                      </TabList>

                      <TabPanels>
                        <TabPanel>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl isRequired>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Name
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Name"
                                maxLength={250}
                                size="md"
                                name="name"
                                {...register("name")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Slug
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="SLug"
                                maxLength={250}
                                size="md"
                                name="slug"
                                {...register("slug")}
                                disabled={!isCreate}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(3, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl isRequired>
                              <FormLabel
                                fontWeight="semibold"
                                fontSize="xs"
                                ms="4px"
                              >
                                Offer Group
                              </FormLabel>
                              <Select
                                ms="4px"
                                type="text"
                                defaultValue="0"
                                size="sm"
                                name="offer_group_id"
                                {...register("offer_group_id")}
                                width="250px"
                              >
                                <option value="">Select Offer Group</option>
                                {listOfferGroup.map((i) => (
                                  <option key={i.id} value={i.id}>
                                    {i.name}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl isRequired>
                              <FormLabel
                                fontWeight="semibold"
                                fontSize="xs"
                                ms="4px"
                              >
                                Location
                              </FormLabel>
                              <Select
                                ms="4px"
                                type="text"
                                defaultValue="0"
                                size="sm"
                                name="location_id"
                                {...register("location_id")}
                                width="200px"
                              >
                                <option value="">Select Location</option>
                                {listLocation.map((i) => (
                                  <option key={i.id} value={i.id}>
                                    {i.name}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <FormControl mt={3}>
                            <FormLabel
                              color={textColor}
                              fontWeight="bold"
                              fontSize="xs"
                            >
                              Description
                            </FormLabel>
                            <Editor name="description" control={control} />
                          </FormControl>

                          <FormControl mt={3}>
                            <FormLabel
                              color={textColor}
                              fontWeight="bold"
                              fontSize="xs"
                            >
                              Policy
                            </FormLabel>
                            <Editor name="policy" control={control} />
                          </FormControl>
                          <FormControl mt={3}>
                            <FormLabel
                              color={textColor}
                              fontWeight="bold"
                              fontSize="xs"
                            >
                              Short Description
                            </FormLabel>
                            <Input
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Short Description"
                              maxLength={500}
                              size="md"
                              name="short_description"
                              maxW={500}
                              {...register("short_description")}
                            />
                          </FormControl>
                          <Grid
                            templateColumns={{
                              sm: "1fr",
                              md: "repeat(2, 1fr)",
                            }}
                            gap="12px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                color={textColor}
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                Thumbnail
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="file"
                                placeholder="Thumbnail"
                                size="sm"
                                name="thumbnail_url"
                                {...register("thumbnail_url")}
                              />
                              {previewThumbnail && (
                                <Image
                                  src={previewThumbnail}
                                />
                              )}
                            </FormControl>
                          </Grid>
                          <Grid
                            templateColumns={{
                              sm: "1fr",
                              md: "repeat(2, 1fr)",
                            }}
                            gap="12px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                color={textColor}
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                Start Date
                              </FormLabel>
                              <Controller
                                name="start_date"
                                control={control}
                                render={({ field }) => (
                                  <DatePickerInput
                                    onChange={(value) => field.onChange(value)}
                                    selected={watch("start_date")}
                                    showTimeSelect
                                    startDate={watch("start_date")}
                                    selectsStart
                                    endDate={watch("end_date")}
                                  />
                                )}
                              />
                              <Text
                                color="red"
                                fontSize={14}
                                mb="10px"
                                ml="5px"
                              >
                                {errors.start_date?.message}
                              </Text>
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                color={textColor}
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                End Date
                              </FormLabel>
                              <Controller
                                name="end_date"
                                control={control}
                                render={({ field }) => (
                                  <DatePickerInput
                                    onChange={(value) => field.onChange(value)}
                                    selected={watch("end_date")}
                                    showTimeSelect
                                    selectsEnd
                                    startDate={watch("start_date")}
                                    endDate={watch("end_date")}
                                    minDate={watch("start_date")}
                                  />
                                )}
                              />
                              <Text
                                color="red"
                                fontSize={14}
                                mb="10px"
                                ml="5px"
                              >
                                {errors.end_date?.message}
                              </Text>
                            </FormControl>
                          </Grid>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Meta Description
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Meta Description"
                                maxLength={250}
                                size="md"
                                name="meta_description"
                                {...register("meta_description")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Meta KeyWord
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Meta KeyWord"
                                maxLength={250}
                                size="md"
                                name="meta_keywords"
                                {...register("meta_keywords")}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Meta Title
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Meta Title"
                                maxLength={250}
                                size="md"
                                name="meta_title"
                                {...register("meta_title")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG Type
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG Type"
                                maxLength={250}
                                size="md"
                                name="og_type"
                                {...register("og_type")}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG URL
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG URL"
                                maxLength={250}
                                size="md"
                                name="og_url"
                                {...register("og_url")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG Title
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG Title"
                                maxLength={250}
                                size="md"
                                name="og_title"
                                {...register("og_title")}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG Description
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG Description"
                                maxLength={250}
                                size="md"
                                name="og_description"
                                {...register("og_description")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG Image
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG Image"
                                maxLength={250}
                                size="md"
                                name="og_image"
                                {...register("og_image")}
                              />
                            </FormControl>
                          </Grid>
                        </TabPanel>
                        <TabPanel>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Name
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Name"
                                maxLength={250}
                                size="md"
                                name="name_vn"
                                {...register("name_vn")}
                              />
                            </FormControl>
                          </Grid>
                          <FormControl mt={3}>
                            <FormLabel
                              color={textColor}
                              fontWeight="bold"
                              fontSize="xs"
                            >
                              Description
                            </FormLabel>
                            <Editor name="description_vn" control={control} />
                          </FormControl>

                          <FormControl mt={3}>
                            <FormLabel
                              color={textColor}
                              fontWeight="bold"
                              fontSize="xs"
                            >
                              Policy
                            </FormLabel>
                            <Editor name="policy_vn" control={control} />
                          </FormControl>
                          <FormControl mt={3}>
                            <FormLabel
                              color={textColor}
                              fontWeight="bold"
                              fontSize="xs"
                            >
                              Short Description
                            </FormLabel>
                            <Input
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Short Description"
                              maxLength={500}
                              size="md"
                              name="short_description_vn"
                              maxW={500}
                              {...register("short_description_vn")}
                            />
                          </FormControl>
                          <Grid
                            templateColumns={{
                              sm: "1fr",
                              md: "repeat(2, 1fr)",
                            }}
                            gap="12px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                color={textColor}
                                fontWeight="bold"
                                fontSize="xs"
                              >
                                Thumbnail
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="file"
                                placeholder="Thumbnail"
                                size="sm"
                                name="thumbnail_url_vn"
                                {...register("thumbnail_url_vn")}
                              />
                              {previewThumbnailVN && (
                                <Image
                                  src={previewThumbnailVN}
                                />
                              )}
                            </FormControl>
                          </Grid>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Meta Description
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Meta Description"
                                maxLength={250}
                                size="md"
                                name="meta_description_vn"
                                {...register("meta_description_vn")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Meta KeyWord
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Meta KeyWord"
                                maxLength={250}
                                size="md"
                                name="meta_keywords_vn"
                                {...register("meta_keywords_vn")}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Meta Title
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Meta Title"
                                maxLength={250}
                                size="md"
                                name="meta_title_vn"
                                {...register("meta_title_vn")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG Type
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG Type"
                                maxLength={250}
                                size="md"
                                name="og_type_vn"
                                {...register("og_type_vn")}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG URL
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG URL"
                                maxLength={250}
                                size="md"
                                name="og_url_vn"
                                {...register("og_url_vn")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG Title
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG Title"
                                maxLength={250}
                                size="md"
                                name="og_title_vn"
                                {...register("og_title_vn")}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG Description
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG Description"
                                maxLength={250}
                                size="md"
                                name="og_description_vn"
                                {...register("og_description_vn")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG Image
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG Image"
                                maxLength={250}
                                size="md"
                                name="og_image_vn"
                                {...register("og_image_vn")}
                              />
                            </FormControl>
                          </Grid>
                        </TabPanel>
                      </TabPanels>
                    </Tabs>

                    <FormControl>
                      <Switch
                        id="isChecked"
                        defaultChecked={isCreate && true}
                        name="isActive"
                        {...register("isActive")}
                        size="sm"
                        ms="4px"
                      >
                        Active
                      </Switch>
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      {isCreate ? "Create" : "Update"}
                    </Button>
                    <Button
                      onClick={() => {
                        onClose();
                        reset();
                        setPreviewThumbnail("");
                      }}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </form>
            </Modal>
          </Flex>
        </CardHeader>
        <CardBody>
          <SearchTable2
            tableData={newListNews}
            columnsData={columnsDataOffer}
            totalRow={totalRows.offers}
            edit={editNews}
            actionsActive={["edit"]}
            listPermissions={[43]}
            isFetching={isFetching}
            loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTableOffers;
