import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
  Text,
  useToast,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";
import CoverImage from "assets/img/CoverImage.png";
import React, { useEffect, useState } from "react";
import AuthCover from "layouts/AuthCover";
import { useRecoilValue } from "recoil";
import { authAtom } from "state/auth";
import { useUserActions } from "hooks/useUserActions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { NavLink } from "react-router-dom";
import { ViewOffIcon, ViewIcon } from "@chakra-ui/icons";

function SignInCover({ history }) {
  const bgForm = useColorModeValue("white", "navy.800");
  const auth = useRecoilValue(authAtom);
  const userActions = useUserActions();
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email field is required"),
    password: Yup.string().required("Password field is required"),
  });
  const toast = useToast();
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const [showPassword, setShowPassword] = useState(false);
  const { errors, isSubmitting } = formState;
  useEffect(() => {
    if (auth) history.push("/admin/dashboard");
  }, []);
  async function onSubmit({ email, password }) {
    try {
      await userActions.login(email, password);
    } catch (error) {
      toast({
        description: "Email/Password are incorrect",
        status: "error",
        duration: 4000,
        position: "top-right",
      });
    }
  }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <AuthCover image={CoverImage}>
      <Flex
        w="100%"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb="60px"
        mt={{ base: "60px", md: "160px" }}
      >
        <Flex
          zIndex="2"
          direction="column"
          w="445px"
          background="transparent"
          borderRadius="15px"
          p="40px"
          mx={{ base: "100px" }}
          mb={{ base: "20px", md: "auto" }}
          m="20px"
          bg={bgForm}
          boxShadow={useColorModeValue(
            "0px 5px 14px rgba(0, 0, 0, 0.05)",
            "unset"
          )}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Email
              </FormLabel>
              <Input
                fontSize="sm"
                ms="4px"
                type="text"
                placeholder="Your Email"
                mb={errors.email?.message ? "5px" : "24px"}
                size="lg"
                name="email"
                {...register("email")}
                isInvalid={errors.email}
                errorBorderColor="red.300"
              />
              <Text color="red" fontSize={14} mb="10px" ml="5px">
                {errors.email?.message}
              </Text>
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Password
              </FormLabel>
              <InputGroup>
                <Input
                  fontSize="sm"
                  ms="4px"
                  type={showPassword ? "text" : "password"}
                  placeholder="Your password"
                  mb={errors.password?.message ? "5px" : "24px"}
                  size="lg"
                  {...register("password")}
                  name="password"
                  isInvalid={errors.password?.message}
                  errorBorderColor="red.300"
                />
                <InputRightElement>
                  <Button
                    bg="transparent"
                    h="1.75rem"
                    mt={2}
                    size="sm"
                    onClick={togglePasswordVisibility}
                    _active={{
                      bg: "transparent",
                    }}
                    _hover={{
                      bg: "transparent",
                      border: "none",
                    }}
                  >
                    {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>

              <Text color="red" mb="10px" ml="5px">
                {errors.password?.message}
              </Text>
              <Button
                fontSize="10px"
                variant="primary"
                fontWeight="bold"
                w="100%"
                h="45"
                mb="24px"
                type="submit"
                isLoading={isSubmitting}
              >
                SIGN IN
              </Button>
              <NavLink to="/auth/authentication/forgot-password">
                <Text color={"green.500"}>Forgot Password?</Text>
              </NavLink>
            </FormControl>
          </form>
        </Flex>
      </Flex>
    </AuthCover>
  );
}

export default SignInCover;
