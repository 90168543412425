import React from "react";
import { Flex, Text, Checkbox } from "@chakra-ui/react";
import { venueListAtom } from "state/show";
import { useRecoilValue } from "recoil";
import ListVenue from "components/ListVenue/ListVenue";

export default function ListShow({
  isCheck,
  item,
  register,
  listVenue,
  voucher,
  control,
  disabledAndChecked = false,
  listCheckedVenue,
  zoneDisplay,
}) {
  const venueList = useRecoilValue(venueListAtom);
  const venueDisplay = disabledAndChecked ? listVenue : venueList;
  const venueCheckedList = disabledAndChecked ? listCheckedVenue : listVenue;

  return (
    <>
      <Flex direction="row" ml="12px">
        <Checkbox
          disabled={disabledAndChecked}
          defaultChecked={disabledAndChecked}
          value={item.id}
          {...register(`listShow`)}
        />
        <Text style={{fontWeight:"bold"}} ml={3}>{item.name} </Text>
      </Flex>
      {isCheck && (
        <Flex direction="column" ml="24px">
          {venueDisplay.map((venue) => (
            <>
              <ListVenue
                key={`${item.id}[${venue.id}]`}
                register={register}
                item={venue}
                showItemChoose={item}
                voucher={voucher}
                control={control}
                isCheck={
                  venueCheckedList &&
                  venueCheckedList.includes(
                    `${item.id.toString()}_${venue.id.toString()}`
                  )
                }
                disabledAndChecked={disabledAndChecked}
                zoneDisplay={zoneDisplay}
              />
            </>
          ))}
        </Flex>
      )}
    </>
  );
}
