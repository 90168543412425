import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/provider";
import theme from "theme/theme.js";
import { RecoilRoot } from "recoil";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import { PrivateRoute } from "auth/PrivateRoute";

ReactDOM.render(
  <RecoilRoot>
    <ChakraProvider theme={theme} resetCss={false} position="relative">
      <HashRouter>
        <Switch>
          <Route path={`/auth`} component={AuthLayout} />
          <PrivateRoute path={`/admin`} component={AdminLayout} />
          <Redirect from={`/`} to="/admin/setting/profile" />
        </Switch>
      </HashRouter>
    </ChakraProvider>
  </RecoilRoot>,
  document.getElementById("root")
);
