import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Box,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import { Select as ReactSelect } from "chakra-react-select";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { columnsDataRatePlan } from "variables/columnsData";
import { totalRow } from "state/roles-permissions";
import { ratePlansAtom, ratePlansUnitAtom } from "state/ratePlans";
import { useRatePlanActions } from "hooks/useRatePlanActions";
import NewRatePlan from "views/Pages/Users/NewRatePlan";
import { AddIcon } from "@chakra-ui/icons";
import { useForm, Controller } from "react-hook-form";
import { listChannelAtom, listShowAtom, venueListAtom } from "state/show";
import { useBookingActions } from "hooks/useBookingActions";
import { checkForbidden } from "helpers/checkForbidden";
import { listUnitAtom } from "state/show";
import { RxReset } from "react-icons/rx";
import { BsSearch } from "react-icons/bs";
import { RiFileExcel2Fill } from "react-icons/ri";
import SearchTable2 from "components/Tables/SearchTable2";

function DataTableRatePlan() {
  const textColor = useColorModeValue("gray.700", "white");
  const chakraStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      p: 0,
      w: "20px",
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: "250px",
      zIndex: 10,
    }),
  };
  const ratePlans = useRecoilValue(ratePlansAtom);
  const totalRows = useRecoilValue(totalRow);
  const ratePlanActions = useRatePlanActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ratePlanItem, setRatePlanItem] = useState();

  const bookingActions = useBookingActions();
  const listChannel = useRecoilValue(listChannelAtom);
  const listShow = useRecoilValue(listShowAtom);
  const venueList = useRecoilValue(venueListAtom);
  const [listUnit, setListUnit] = useRecoilState(listUnitAtom);
  const [ratePlanUnits, setRatePlanUnits] = useRecoilState(ratePlansUnitAtom);
  const [isCopy, setIsCopy] = useState(false);
  const [isExportExcel, setIsExportExcel] = useState(false);
  const [newRatePlans, setNewRatePlans] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const {
    register: registerSearch,
    watch: watchSearch,
    handleSubmit: handleSubmitSearch,
    control: controlSearch,
    reset: resetSearch,
    setValue: setValueSearch,
  } = useForm();

  const showSearch = watchSearch("showSearch");
  const venueSearch = watchSearch("venueSearch");
  const nameSearch = watchSearch("nameSearch");
  const codeSearch = watchSearch("codeSearch");
  const typeSearch = watchSearch("typeSearch");
  const statusSearch = watchSearch("statusSearch");
  const channelSearch = watchSearch("channelSearch");
  const RatePlanStatus = [
    { Id: "0", Name: "All" },
    { Id: "1", Name: "Active" },
    { Id: "2", Name: "InActive" },
  ];
  const RatePlanType = [
    { Id: "0", Name: "All" },
    { Id: 1, Name: "FAMILY PACKAGE" },
    { Id: 2, Name: "COMBO" },
    { Id: 3, Name: "EARLY BIRD" },
    { Id: 4, Name: "STANDARD" },
    { Id: 5, Name: "CAMPAIGN" },
    { Id: 6, Name: "PROMOTION" },
  ];
  useEffect(() => {
    bookingActions.getListChannel({ status: 1 }, 0, "");
    bookingActions.getListShow({ status: 1 }, 0, "");
    bookingActions.getListVenue({ status: 1 }, 0, "");
  }, []);
  useEffect(() => {
    if (channelSearch) {
      setValueSearch("unitSearch", null);
      bookingActions.getListUnits({ channel_id: channelSearch.id }, 0, "");
    }
  }, [channelSearch]);

  const recallApi = async (fetchDataOptions) => {
    await ratePlanActions.getAllRatePlans(
      {
        code: codeSearch,
        name: nameSearch,
        show_id: showSearch,
        venue_id: venueSearch,
        type: typeSearch,
        status: statusSearch ? statusSearch : 1,
        channel_id: channelSearch?.id,
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
  };

  useEffect(() => {
    if (ratePlans) {
      const newRatePlans = ratePlans.map((i) => ({
        ...i,
        typeSwitch: "rateplan",
        filters: {
          code: codeSearch,
          name: nameSearch,
          show_id: showSearch,
          venue_id: venueSearch,
          type: typeSearch,
          status: statusSearch ? statusSearch : 1,
          channel_id: channelSearch,
        },
      }));
      setNewRatePlans(newRatePlans);
    }
  }, [ratePlans]);
  async function onSubmitSearch({
    codeSearch,
    nameSearch,
    showSearch,
    venueSearch,
    typeSearch,
    statusSearch,
    channelSearch,
    unitSearch,
  }) {
    setIsFetching(true);
    const unit_ids = unitSearch?.map((i) => i.id).join(",") ?? "";
    if (isExportExcel) {
      setIsExportExcel(false);
      const response = await ratePlanActions.exportRatePlanList(
        {
          code: codeSearch,
          name: nameSearch,
          show_id: showSearch,
          venue_id: venueSearch,
          type: typeSearch,
          status: statusSearch,
          channel_id: channelSearch?.id || "",
          unit_ids,
        },
        0,
        localStorage && localStorage.getItem("currentPageSizeStore")
          ? localStorage.getItem("currentPageSizeStore")
          : 10
      );
      const outputFilename = `rateplans_${Date.now()}.xlsx`;
      const url = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
    } else {
      await ratePlanActions.getAllRatePlans(
        {
          code: codeSearch,
          name: nameSearch,
          show_id: showSearch,
          venue_id: venueSearch,
          type: typeSearch,
          status: statusSearch,
          channel_id: channelSearch?.id || "",
          unit_ids,
        },
        0,
        localStorage && localStorage.getItem("currentPageSizeStore")
          ? localStorage.getItem("currentPageSizeStore")
          : 10
      );
    }
    setIsFetching(false);
  }
  const editRatePlan = async (val, type) => {
    try {
      const {
        data: { data: ratePlanInfo },
      } = await ratePlanActions.getRatePlanDetail(val);
      setRatePlanItem(ratePlanInfo);
      if (type) {
        setIsCopy(true);
      } else {
        setIsCopy(false);
      }
    } catch (error) {
      console.warn(error);
    }
    onOpen();
  };
  const updateNewRatePlan = async (id) => {
    try {
      const {
        data: { data: ratePlanInfo },
      } = await ratePlanActions.getRatePlanDetail(id);
      setRatePlanItem(ratePlanInfo);
    } catch (error) {
      console.warn(error);
    }
  };
  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex direction="row" align="center" justifyContent="space-between">
            <Text
              float="left"
              color={textColor}
              fontSize={{ lg: "lg", sm: "sm" }}
              fontWeight="bold"
            >
              Rate Plan Management
            </Text>
            <Flex>
              {checkForbidden(42) && (
                <Button
                  size="sm"
                  onClick={() => {
                    onOpen();
                    setRatePlanItem(null);
                  }}
                  leftIcon={<AddIcon />}
                  colorScheme="green"
                  variant="outline"
                >
                  <Text fontSize={{ sm: "xs" }}>Add Rate Plan</Text>
                </Button>
              )}
            </Flex>
            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpen}
              onClose={() => {
                onClose();
                setListUnit([]);
                setRatePlanUnits([]);
              }}
              size="6xl"
            >
              <ModalOverlay />
              <ModalContent>
                <ModalCloseButton />
                <ModalBody pb={{ lg: 6, sm: 0 }} p={{ sm: 0, lg: 6 }}>
                  <NewRatePlan
                    onCloseModal={() => {
                      setListUnit([]);
                      onClose();
                      setRatePlanUnits([]);
                    }}
                    isCopy={isCopy}
                    ratePlanItem={ratePlanItem}
                    updateNewRatePlan={(id) => updateNewRatePlan(id)}
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
          </Flex>
        </CardHeader>
        <CardBody>
          <Card>
            <form
              id="rateplan-search-form"
              onSubmit={handleSubmitSearch(onSubmitSearch)}
            >
              <Flex
                direction="row"
                alignItems="center"
                mb={3}
                gap={4}
                flexWrap={"wrap"}
              >
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                    Code
                  </FormLabel>
                  <Input
                    _placeholder={{ color: "gray.400" }}
                    variant="main"
                    type="text"
                    placeholder="Code"
                    minW="200px"
                    size="sm"
                    fontSize="sm"
                    name="codeSearch"
                    {...registerSearch("codeSearch")}
                    _focus={{ borderColor: "blue.500" }}
                  />
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                    Name
                  </FormLabel>
                  <Input
                    _placeholder={{ color: "gray.400" }}
                    variant="main"
                    type="text"
                    placeholder="Name"
                    minW="200px"
                    size="sm"
                    fontSize="sm"
                    name="nameSearch"
                    {...registerSearch("nameSearch")}
                    _focus={{ borderColor: "blue.500" }}
                  />
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Type
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    defaultValue="0"
                    size="sm"
                    name="typeSearch"
                    {...registerSearch("typeSearch")}
                    width="200px"
                  >
                    {RatePlanType.map((item, index) => (
                      <option key={index} value={item.Id}>
                        {item.Name}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Status
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    defaultValue="1"
                    size="sm"
                    name="statusSearch"
                    {...registerSearch("statusSearch")}
                    width="200px"
                  >
                    {RatePlanStatus.map((item, index) => (
                      <option key={index} value={item.Id}>
                        {item.Name}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Show
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    size="sm"
                    name="showSearch"
                    {...registerSearch("showSearch")}
                    width="200px"
                  >
                    <option value="">All Show</option>
                    {listShow.map((i) => (
                      <option key={i.id} value={i.id}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Venue
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    size="sm"
                    name="venueSearch"
                    {...registerSearch("venueSearch")}
                    width="200px"
                  >
                    <option value="">All Venue</option>
                    {venueList.map((i) => (
                      <option key={i.id} value={i.id}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Channel
                  </FormLabel>
                  <Controller
                    name="channelSearch"
                    control={controlSearch}
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        focusBorderColor="blue.500"
                        chakraStyles={chakraStyles}
                        options={listChannel}
                        placeholder="Select Channel"
                        getOptionLabel={(i) => i.name}
                        getOptionValue={(option) => option.id}
                        size="sm"
                        closeMenuOnSelect={true}
                      />
                    )}
                  />
                </Box>
                {channelSearch && (
                  <Box direction="row" alignItems="center" display="block">
                    <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                      Unit
                    </FormLabel>
                    <Controller
                      name="unitSearch"
                      control={controlSearch}
                      render={({ field }) => (
                        <ReactSelect
                          {...field}
                          focusBorderColor="blue.500"
                          chakraStyles={chakraStyles}
                          options={listUnit}
                          placeholder="Select Unit"
                          getOptionLabel={(i) => i.name}
                          getOptionValue={(option) => option.id}
                          size="sm"
                          closeMenuOnSelect={false}
                          isMulti
                        />
                      )}
                    />
                  </Box>
                )}
              </Flex>
              <Flex justify="center" align="center" gap="8px" mt={3}>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<RxReset />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    onClick={() => {
                      resetSearch();
                      setValueSearch("channelSearch", null);
                      setValueSearch("unitSearch", null);
                      setListUnit([]);
                    }}
                  >
                    Reset
                  </Button>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<RiFileExcel2Fill />}
                    variant="primary"
                    w="100px"
                    h="33px"
                    onClick={() => {
                      setIsExportExcel(true);
                    }}
                  >
                    Export Excel
                  </Button>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<BsSearch />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    type="submit"
                  >
                    Search
                  </Button>
                </Box>
              </Flex>
            </form>
          </Card>
          <SearchTable2
            tableData={newRatePlans}
            columnsData={columnsDataRatePlan}
            totalRow={totalRows.ratePlans}
            edit={editRatePlan}
            copy={(val) => editRatePlan(val, "copy")}
            actionsActive={["copy", "edit"]}
            listPermissions={[33, 32]}
            loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
            isFetching={isFetching}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTableRatePlan;
