import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  Image,
  FormLabel,
  useToast,
  Grid,
  Stack,
  Box,
  FormControl,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SearchTable2 from "components/Tables/SearchTable2";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { columnsDataAssignSeatingPlanShowSchedule } from "variables/columnsData";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { totalRow } from "state/roles-permissions";
import { useBookingActions } from "hooks/useBookingActions";
import { checkForbidden } from "helpers/checkForbidden";
import { Select as ReactSelect } from "chakra-react-select";
import {
  showsScheduleBookingDetailAtom,
  listUnitAtom,
  listChannelAtom,
  showsScheduleDetailAtom,
} from "state/show";
import { useParams, useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import moment from "moment";

function DataTableAssignSeatingPlanShowSchedule() {
  const chakraStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      p: 0,
      w: "20px",
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: "150px",
    }),
  };
  const { seatingPlan_id } = useParams();
  const textColor = useColorModeValue("gray.700", "white");
  const listAssignSeatingPlan = useRecoilValue(showsScheduleBookingDetailAtom);
  const listAssignSeatingPlanTable = useRecoilValue(showsScheduleDetailAtom);
  const listUnit = useRecoilValue(listUnitAtom);
  const listChannel = useRecoilValue(listChannelAtom);
  const totalRows = useRecoilValue(totalRow);
  const bookingActions = useBookingActions();
  const [legendMap, setLegendMap] = useState();
  const [seatingsLock, setSeatingsLock] = useState();
  const [desktopMap, setDesktopMap] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [newAssignSeatingPlans, setNewAssignSeatingPlans] = useState([]);
  const [ticketPrices, setTicketPrices] = useState([]);
  const [isAssign, setIsAssign] = useState(false);
  const [selectedSeat, setSelectedSeat] = useState([]);
  const [seatingPlanAssignInfo, setSeatingPlanAssignInfo] = useState();
  const [lockByAdmin, setLockByAdmin] = useState("");
  const colorBackGround = (zoneID) => {
    switch (zoneID) {
      case 1:
        return "#FCB515";
      case 2:
        return "#59BC67";
      case 3:
        return "#5E2F8A";
      default:
        break;
    }
  };
  const validationSchema = Yup.object().shape({
    channel: Yup.object().required("This field is required"),
    unit: Yup.object().required("This field is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState,
    reset,
    control,
    setValue,
    watch,
  } = useForm(formOptions);

  const { errors, isSubmitting } = formState;
  const toast = useToast();
  const history = useHistory();
  const channel = watch("channel");
  const unit = watch("unit");
  useEffect(async () => {
    const seatingPlanAssign = JSON.parse(
      localStorage.getItem("seatingPlanAssign")
    );
    bookingActions.getListChannel({ status: 1 }, 0, "");
    const {
      data: { data: ticketPrices },
    } = await bookingActions.getTicketPrices({
      show_id: seatingPlanAssign.show_id,
      venue_id: seatingPlanAssign.venue_id,
      seatingplan_id: seatingPlanAssign.seatingplan_id,
    });
    if (seatingPlanAssign) {
      setDesktopMap(`${seatingPlanAssign.desktop_maps}?type=svg`);
      setLegendMap(`${seatingPlanAssign.legend_desktop_maps}`);
    }
    setTicketPrices(ticketPrices);
    recallApi();
    setSeatingPlanAssignInfo({
      show_name: seatingPlanAssign.show_name,
      show_time: seatingPlanAssign.show_time,
      show_date: seatingPlanAssign.show_date,
    });
  }, []);
  useEffect(() => {
    if (channel) {
      setValue("unit", null);
      bookingActions.getListUnits({ channel_id: channel.id, status: 1 }, 0, "");
    }
  }, [channel]);

  useEffect(async () => {
    if (unit) {
      const data = await bookingActions.getAssignSeatByShowScheduleDetail(
        { id: seatingPlan_id, unit_id: unit?.id },
        0,
        ""
      );
      if (data) {
        const seats = data.map((i) => `lune_seat_${i.seat_number}_${i.floor}`);
        setSelectedSeat(seats);
      }
    }
  }, [unit]);
  useEffect(() => {
    if (listAssignSeatingPlan) {
      const newAssignListSeatingPlan = listAssignSeatingPlanTable.map((i) => ({
        ...i,
      }));
      setNewAssignSeatingPlans(newAssignListSeatingPlan);
      const locks = listAssignSeatingPlan.map((i) => ({
        seat: `lune_seat_${i.seat}_${i.floor}`,
        status: i.status,
      }));
      setSeatingsLock(locks);
    }
  }, [listAssignSeatingPlan]);
  useEffect(() => {
    const locksAdmin = listAssignSeatingPlanTable.map(
      (item) => `lune_seat_${item.seat_number}_${item.floor}`
    )
    setLockByAdmin(locksAdmin)

  },[listAssignSeatingPlanTable])
  const recallApi = async () => {
    setIsFetching(true);
    await bookingActions.getListBookingDetails(
      { show_schedule_detail_id: seatingPlan_id },
      0,
      ""
    );
    await bookingActions.getAssignSeatByShowScheduleDetail(
      { status: 1, id: seatingPlan_id },
      0,
      ""
    );
    setIsFetching(false);
  };
  const onSubmit = async ({ channel, unit }) => {
    const seats = selectedSeat.map((seat) => {
      const part = seat.split("_");
      return {
        seat_number: part[2],
        floor: parseInt(part[3]),
      };
    });
    const payload = {
      unit_id: unit.id,
      seats,
    };
    await bookingActions.updateAssignSeatByShowScheduleDetail(
      payload,
      seatingPlan_id
    );
    setIsAssign(false);
    reset();
    setSelectedSeat([]);
    await bookingActions.getListBookingDetails(
      { show_schedule_detail_id: seatingPlan_id },
      0,
      ""
    );
    await bookingActions.getAssignSeatByShowScheduleDetail(
      { id: seatingPlan_id, status: 1 },
      0,
      ""
    );
  };
  const onSeatClick = (event) => {
    const seatNumber = event.target.getAttribute("id");
    setSelectedSeat((preState) => [...preState, seatNumber]);
    if (selectedSeat.includes(seatNumber)) {
      setSelectedSeat((preState) => preState.filter((i) => i !== seatNumber));
    }
  };

  const onSeatClickOutSide = (event) => {
    const seatNumber = event.target.getAttribute("id");
    const seat = listAssignSeatingPlan.find(
      (i) => `lune_seat_${i.seat}_${i.floor}` === seatNumber
    );
    if (seat) {
      window.open(`#/admin/booking/create/detail/${seat?.booking_id}`, '_blank')
    }
  };
  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex direction="column" display="block">
            <Text
              float="left"
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              mb="6px"
            >
              Assign Seating Plan By Show Schedule -{" "}
              {seatingPlanAssignInfo?.show_name} -{" "}
              {moment(seatingPlanAssignInfo?.show_date)?.format("DD/MM/YYYY")} - 
              {seatingPlanAssignInfo?.show_time?.slice(0, -3)}
            </Text>
            <Stack float="right" direction="row" spacing={4}>
              {checkForbidden(42) && !isAssign && (
                <Button
                  size="sm"
                  onClick={() => {
                    setIsAssign(true);
                  }}
                  colorScheme="green"
                  variant="outline"
                >
                  Assign Seat
                </Button>
              )}
            </Stack>
          </Flex>
        </CardHeader>
        <CardBody pl={"22px"}>
          {isAssign ? (
            <>
              <Flex
                direction={{ sm: "column", lg: "row" }}
                justifyContent="space-between"
                gap={2}
              >
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormControl isRequired>
                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                      Channel
                    </FormLabel>
                    <Controller
                      name="channel"
                      control={control}
                      render={({ field }) => (
                        <ReactSelect
                          {...field}
                          focusBorderColor="blue.500"
                          chakraStyles={chakraStyles}
                          options={listChannel}
                          placeholder="Select Channel"
                          getOptionLabel={(i) => i.name}
                          getOptionValue={(option) => option.id}
                          size="sm"
                          closeMenuOnSelect={true}
                        />
                      )}
                    />
                    <Text color="red" fontSize={14} mb="10px" ml="5px">
                      {errors.channel?.message}
                    </Text>
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                      Unit
                    </FormLabel>
                    <Controller
                      name="unit"
                      control={control}
                      render={({ field }) => (
                        <ReactSelect
                          {...field}
                          focusBorderColor="blue.500"
                          chakraStyles={chakraStyles}
                          options={listUnit}
                          placeholder="Select Unit"
                          getOptionLabel={(i) => i.name}
                          getOptionValue={(option) => option.id}
                          size="sm"
                          closeMenuOnSelect={true}
                        />
                      )}
                    />
                    <Text color="red" fontSize={14} mb="10px" ml="5px">
                      {errors.channel?.message}
                    </Text>
                  </FormControl>
                  <Button
                    size="sm"
                    minW="110px"
                    colorScheme="blue"
                    ml={{ lg: 4, sm: 1 }}
                    fontSize="small"
                    type="submit"
                  >
                    Save
                  </Button>
                  <Button
                    size="sm"
                    minW="110px"
                    colorScheme="blue"
                    ml={{ lg: 4, sm: 1 }}
                    fontSize="small"
                    onClick={async () => {
                      setIsAssign(false);
                      reset();
                      setSelectedSeat([]);
                    }}
                  >
                    Cancel
                  </Button>
                </form>
              </Flex>
              {unit && (
                <ReactSVG
                  src={desktopMap}
                  afterInjection={(svg) => {
                    const seats = svg.querySelectorAll("circle");
                    seats.forEach((seat) => {
                      seat.addEventListener("click", onSeatClick);
                      if (selectedSeat.includes(seat.getAttribute("id"))) {
                        seat.classList.add("booked_929497");
                      }
                    });
                  }}
                />
              )}
            </>
          ) : (
            <>
              <SearchTable2
                tableData={listAssignSeatingPlanTable}
                columnsData={columnsDataAssignSeatingPlanShowSchedule}
                totalRow={totalRows.assignSeatingPlans}
                listPermissions={[43]}
                isFetching={isFetching}
                manualPagination={false}
                enableRowActions={false}
                manualSorting={false}
              />
              <Flex position="relative">
                <Stack
                  position="absolute"
                  p={"5px 10px"}
                  backgroundColor={"white"}
                  left="0"
                  direction="column"
                >
                  <Text fontWeight={"bold"} color="#939496" textAlign="center">
                    LEGEND IN ZONES
                  </Text>
                  <Grid
                    templateColumns="repeat(3, auto)"
                    gap="6px"
                    fontSize="10px"
                    color="white"
                    textAlign="center"
                  >
                    <Text color="#939496">ZONE</Text>
                    <Text color="#939496">SEATS</Text>
                    <Text color="#939496">OFFICIAL RATE (VND)</Text>
                    {ticketPrices.map((i, index) => (
                      <>
                        <Text
                          key={index}
                          textAlign="center"
                          backgroundColor={colorBackGround(i.zone_id)}
                          px={3}
                          fontSize={"12px"}
                          fontWeight={"bold"}
                          py={2}
                        >
                          {i.zone.name}
                        </Text>
                        <Text
                          textAlign="center"
                          backgroundColor={colorBackGround(i.zone_id)}
                          px={3}
                          fontSize={"12px"}
                          fontWeight={"bold"}
                          py={2}
                        >
                          {i?.total_seat}
                        </Text>
                        <Text
                          px={3}
                          fontSize={"12px"}
                          fontWeight={"bold"}
                          py={2}
                          textAlign="center"
                          backgroundColor={colorBackGround(i.zone_id)}
                        >
                          {i.official_price.toLocaleString("en-US")}
                        </Text>
                      </>
                    ))}
                  </Grid>
                  <Flex
                    p={"2px 3px"}
                    border="2px solid #939496"
                    alignItems="center"
                  >
                    <Box
                      backgroundColor="black"
                      rounded={"50%"}
                      w="16px"
                      h="16px"
                    ></Box>
                    <Text mt="2px" fontSize="14px" ml={1} color={"#939496"}>
                      Column
                    </Text>
                  </Flex>
                </Stack>
                <Flex
                  position="absolute"
                  right="10px"
                  backgroundColor="white"
                  padding="10px"
                >
                  {legendMap && <Image maxW="300px" src={legendMap} />}
                </Flex>
              </Flex>
              <ReactSVG
                src={desktopMap}
                afterInjection={(svg) => {
                  const seats = svg.querySelectorAll("circle");
                  seats.forEach((seat) => {
                    seat.addEventListener("click", onSeatClickOutSide);
                    if (selectedSeat.includes(seat.getAttribute("id"))) {
                      seat.classList.add("selected");
                    }
                    const finds = seatingsLock.find(
                      (i) => i.seat === seat.getAttribute("id")
                    );
                    if (lockByAdmin.includes(seat.getAttribute("id"))) {
                      seat.classList.add("lockadmin");
                    }
                    if (finds) {
                      if (finds.status === 0) {
                        seat.classList.add("booked_929497");
                      } else if (finds.status === 1) {
                        seat.classList.add("booked_FBED21");
                      } else {
                        seat.classList.add("booked_FF8D8D");
                      }
                    }
                  });
                }}
              />
            </>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTableAssignSeatingPlanShowSchedule;
