const PanelContainer = {
  baseStyle: {
    padding: "30px 30px",
    paddingRight: "15px",
    minHeight: "100vh",
  },
};

export const PanelContainerComponent = {
  components: {
    PanelContainer,
  },
};
