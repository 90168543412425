import {
  Button,
  useColorModeValue,
  Text,
  Flex,
  CircularProgress,
  CircularProgressLabel,
} from "@chakra-ui/react";
import { useBookingActions } from "hooks/useBookingActions";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export default function CountDown(props) {
  const { expTime, ...rest } = props;
  let bgButton = useColorModeValue("white", "gray.600");
  const bookingActions = useBookingActions();
  const [timeLeft, setTimeLeft] = useState({
    minutes: "",
    seconds: "",
  });
  const history = useHistory();
  useEffect(() => {
    const interval = setInterval(() => {
      const duration = moment.duration(moment(expTime).diff(moment()));
      setTimeLeft({
        minutes: duration.minutes(),
        seconds: duration.seconds(),
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  useEffect(async () => {
    if (parseInt(timeLeft.seconds) < 0) {
      history.push("/admin/booking/create");
      await bookingActions.cancelBooking();
    }
  }, [timeLeft]);
  return (
    <>
      <Button
        h="60px"
        w="60px"
        bg={bgButton}
        position="fixed"
        left={document.documentElement.dir === "rtl" ? "35px" : ""}
        right={document.documentElement.dir === "rtl" ? "" : "105px"}
        bottom="30px"
        borderRadius="50px"
        boxShadow="0 2px 12px 0 rgb(0 0 0 / 16%)"
        display="flex"
      >
        <CircularProgress
          value={timeLeft.minutes}
          max={14}
          size="70px"
          thickness="4px"
          color="green"
        >
          <CircularProgressLabel fontSize="sm">
            <Flex direction="column">
              <Text fontSize="23px" color={"green.400"}>
                {timeLeft.minutes}
              </Text>
              <Text fontSize="10px">Minutes</Text>
            </Flex>
          </CircularProgressLabel>
        </CircularProgress>
      </Button>
      <Button
        h="60px"
        w="60px"
        bg={bgButton}
        position="fixed"
        variant="no-effects"
        left={document.documentElement.dir === "rtl" ? "35px" : ""}
        right={document.documentElement.dir === "rtl" ? "" : "35px"}
        bottom="30px"
        borderRadius="50px"
        boxShadow="0 2px 12px 0 rgb(0 0 0 / 16%)"
      >
        <CircularProgress
          value={timeLeft.seconds}
          max={60}
          size="70px"
          thickness="4px"
          color="green"
        >
          <CircularProgressLabel fontSize="sm">
            <Flex direction="column">
              <Text fontSize="23px" color={"green.400"}>
                {timeLeft.seconds}
              </Text>
              <Text fontSize="10px">Seconds</Text>
            </Flex>
          </CircularProgressLabel>
        </CircularProgress>
      </Button>
    </>
  );
}
