import {
  Flex,
  Text,
  useToast,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Switch,
  Stack,
  Box,
  Select,
} from "@chakra-ui/react";
import { NumericFormat } from "react-number-format";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { columnsDataVoucherTemplate } from "variables/columnsData";
import { totalRow } from "state/roles-permissions";
import { useForm, Controller } from "react-hook-form";
import { useBookingActions } from "hooks/useBookingActions";
import { listShowAtom, venueListAtom } from "state/show";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useVoucherActions } from "hooks/useVoucherActions";
import { vouchersTemplateAtom } from "state/vouchers";
import ListShow from "components/ListShow/ListShow";
import { AddIcon } from "@chakra-ui/icons";
import { checkForbidden } from "helpers/checkForbidden";
import { RxReset } from "react-icons/rx";
import { BsSearch } from "react-icons/bs";
import SearchTable2 from "components/Tables/SearchTable2";

function DataTableTemplateVoucher() {
  const bookingActions = useBookingActions();
  const totalRows = useRecoilValue(totalRow);
  const listShow = useRecoilValue(listShowAtom);
  const venueList = useRecoilValue(venueListAtom);
  const voucherActions = useVoucherActions();
  const vouchersTemplate = useRecoilValue(vouchersTemplateAtom);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCreate, setIsCreate] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [newVouchersTemplate, setNewVouchersTemplate] = useState([]);
  const [listShowForEdit, setListShowForEdit] = useState([]);
  const [listZoneForEdit, setListZoneForEdit] = useState([]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    amount: Yup.string().required("This field is required"),
    program_applied_vi: Yup.string()
      .required("This field is required")
      .max(255),
    program_applied: Yup.string().required("This field is required").max(255),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    setError,
    formState,
    reset,
    control,
    watch,
    setValue,
  } = useForm(formOptions);
  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    watch: watchSearch,
    control: controlSearch,
    reset: resetSearch,
    setValue: setValueSearch,
  } = useForm();
  const { errors, isSubmitting } = formState;
  const listShowChoose = watch("listShow");
  const listVenue = watch("listVenue");
  const voucher = watch("voucher");
  //search form
  const nameSearch = watchSearch("nameSearch");
  const statusSearch = watchSearch("statusSearch");
  const venueSearch = watchSearch("venueSearch");
  const unitSearch = watchSearch("unitSearch");
  const channelSearch = watchSearch("channelSearch");
  const showSearch = watchSearch("showSearch");
  const voucherTypeSearch = watchSearch("voucherTypeSearch");
  const toolTypeSearch = watchSearch("toolTypeSearch");
  const toast = useToast();
  useEffect(() => {
    bookingActions.getListChannel({ status: 1 }, 0, "");
    bookingActions.getListShow({ status: 1 }, 0, "");
    bookingActions.getListVenue({ status: 1 }, 0, "");
    bookingActions.getListZones({ status: 1 }, 0, "");
  }, []);
  const recallApi = async (fetchDataOptions) => {
    await voucherActions.getAllVouchersTemplate(
      {
        name: nameSearch,
        status: statusSearch ? statusSearch : 1,
        show_id: showSearch,
        venue_id: venueSearch,
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
  };
  async function showDetail(ticketPriceID) {
    const {
      data: { data },
    } = await voucherActions.getVoucherTemplateDetail(ticketPriceID);
    setIsCreate(false);
    onOpen();
    setValue("name", data.name);
    setValue("program_applied", data.program_applied);
    setValue("program_applied_vi", data.program_applied_vi);
    setValue("amount", data.amount);

    let shows = [];
    let venues = [];
    let displayVenues = [];
    let showID = -1;
    let preShow;
    let showVenueID = "";
    let index = 1;

    if (data.voucher_configs) {
      data.voucher_configs.map((item) => {
        //first row || (the same show and not including in displayVenue) => add current venue
        if (
          showID == -1 ||
          (showID == item.show_id &&
            displayVenues.indexOf(
              item.Show.id.toString() + "_" + item.Venue.id.toString()
            ) == -1)
        ) {
          venues.push(item.Venue);
          showVenueID =
            item.Show.id.toString() + "_" + item.Venue.id.toString();
          displayVenues.push(showVenueID);
        }

        //---case 1: last item and n show
        if (
          index == data.voucher_configs.length &&
          showID != -1 &&
          showID != item.show_id
        ) {
          //1.1 push preShow
          shows.push({
            id: preShow.id.toString(),
            name: preShow.name.toString(),
            venue: venues,
            displayVenue: displayVenues,
          });
          venues = [];
          displayVenues = [];
          //1.2.1 add current venue
          venues.push(item.Venue);
          showVenueID =
            item.Show.id.toString() + "_" + item.Venue.id.toString();
          displayVenues.push(showVenueID);
          //1.2.2 push current show
          shows.push({
            id: item.Show.id.toString(),
            name: item.Show.name.toString(),
            venue: venues,
            displayVenue: displayVenues,
          });
        } else if (
          index == data.voucher_configs.length ||
          (showID != -1 && showID != item.show_id)
        ) {
          //---case 2: one row(one show) || (n row && n show)

          //2.1.0: one row(one show) => push current show
          let sID = item.Show.id.toString();
          let sName = item.Show.name.toString();
          //2.2.1: n show => push preShow
          if (showID != -1 && showID != item.show_id) {
            sID = preShow.id.toString();
            sName = preShow.name.toString();
          }

          shows.push({
            id: sID,
            name: sName,
            venue: venues,
            displayVenue: displayVenues,
          });
          venues = [];
          displayVenues = [];
          //2.2.2: n show => add current venue
          if (showID != -1 && showID != item.show_id) {
            venues.push(item.Venue);
            showVenueID =
              item.Show.id.toString() + "_" + item.Venue.id.toString();
            displayVenues.push(showVenueID);
          }
        }

        showID = item.show_id;
        preShow = item.Show;
        index = index + 1;
      });
    }
    setListShowForEdit(shows);
    const zones = data.voucher_configs ? data.voucher_configs : [];
    setListZoneForEdit(zones);
  }
  useEffect(() => {
    if (vouchersTemplate) {
      const newVouchersTemplate = vouchersTemplate.map((i) => ({
        ...i,
        typeSwitch: "voucherTemplate",
        filters: {
          name: nameSearch,
          status: statusSearch ? statusSearch : 1,
          show_id: showSearch,
          venue_id: venueSearch,
        },
      }));
      setNewVouchersTemplate(newVouchersTemplate);
    }
  }, [vouchersTemplate]);

  async function onSubmitSearch({
    nameSearch,
    statusSearch,
    showSearch,
    venueSearch,
  }) {
    setIsFetching(true);

    await voucherActions.getAllVouchersTemplate(
      {
        name: nameSearch,
        status: statusSearch,
        show_id: showSearch,
        venue_id: venueSearch,
      },
      0,
      localStorage && localStorage.getItem("currentPageSizeStore")
        ? localStorage.getItem("currentPageSizeStore")
        : 10
    );
    setIsFetching(false);
  }
  async function onSubmit({
    name,
    status,
    amount,
    voucher,
    program_applied,
    program_applied_vi,
  }) {
    if (!voucher || voucher?.length < 1) {
      setError("apiError", { message: "Please select at least one zone" });
      return;
    }
    try {
      let voucher_configs = [];
      if (voucher) {
        voucher_configs = voucher.map((i) => {
          const [piece1, piece2, piece3] = i.split("_");
          return {
            show_id: parseInt(piece1),
            venue_id: parseInt(piece2),
            zone_id: parseInt(piece3),
            amount: parseInt(
              watch(`salePrice[${piece1}_${piece2}_${piece3}]`)?.replace(
                /,/g,
                ""
              ) || 0
            ),
          };
        });
      }
      let payload = {
        name,
        status: status ? 1 : 2,
        voucher_configs,
        amount: 0,
        program_applied,
        program_applied_vi,
      };
      if (amount && parseInt(amount) !== 0) {
        payload.amount = parseInt(amount.replace(/^0+|,/g, ""));
      }
      const res = await voucherActions.createVoucherTemplate(payload);
      if (res.status === 200) {
        toast({
          description: `Create Voucher Template successful`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (err) {
      console.warn(err, "err");
    } finally {
      await voucherActions.getAllVouchersTemplate(
        {
          name: nameSearch,
          status: statusSearch,
          show_id: showSearch,
          venue_id: venueSearch,
          channel_id: channelSearch,
          unit_id: unitSearch,
          tool_type_id: toolTypeSearch,
          voucher_type_id: voucherTypeSearch
            ? parseInt(voucherTypeSearch.id)
            : 0,
        },
        0,
        localStorage && localStorage.getItem("currentPageSizeStore")
          ? localStorage.getItem("currentPageSizeStore")
          : 10
      );
      onClose();
      reset();
    }
  }

  const VoucherIssueStatus = [
    { Id: "0", Name: "All" },
    { Id: "1", Name: "Active" },
    { Id: "2", Name: "InActive" },
  ];

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px">
          <Flex direction="column" display="block">
            <Text float="left" fontSize="lg" fontWeight="bold" mb="6px">
              Voucher Template
            </Text>
            <Stack float="right" direction="row" spacing={4}>
              {checkForbidden(128) && (
                <Button
                  size="sm"
                  onClick={() => {
                    onOpen();
                    setIsCreate(true);
                    setValue("min_los", 1);
                    setValue("max_los", 1);
                  }}
                  leftIcon={<AddIcon />}
                  colorScheme="green"
                  variant="outline"
                >
                  Add Voucher Template
                </Button>
              )}
            </Stack>
            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpen}
              onClose={() => {
                onClose();
                reset();
              }}
              size="3xl"
            >
              <ModalOverlay />
              <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                  <ModalHeader>
                    {isCreate ? "Create Voucher Template" : "Detail Voucher"}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <Grid gap="16px" templateColumns="repeat(2, 1fr)" mb={3}>
                      <Flex direction="column">
                        <FormControl isRequired>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Name
                          </FormLabel>
                          <Input
                            fontSize="sm"
                            ms="4px"
                            type="text"
                            placeholder="Name"
                            maxLength={250}
                            size="md"
                            name="name"
                            {...register("name")}
                            isInvalid={errors.name}
                            errorBorderColor="red.300"
                            isDisabled={!isCreate}
                          />
                          <Text color="red" fontSize={14} mb="10px" ml="5px">
                            {errors.name?.message}
                          </Text>
                        </FormControl>
                      </Flex>
                    </Grid>
                    <Grid gap="16px" templateColumns="repeat(2, 1fr)" mb={3}>
                      <Flex direction="column">
                        <FormControl isRequired>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Program Applied
                          </FormLabel>
                          <Input
                            fontSize="sm"
                            ms="4px"
                            type="text"
                            placeholder="Program applied"
                            size="sm"
                            isDisabled={!isCreate}
                            name="program_applied"
                            {...register("program_applied")}
                            isInvalid={errors.program_applied}
                            errorBorderColor="red.300"
                          />
                          <Text color="red" fontSize={14} mb="10px" ml="5px">
                            {errors.program_applied?.message}
                          </Text>
                        </FormControl>
                      </Flex>
                      <Flex direction="column">
                        <FormControl isRequired>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Program Applied (Vietnamese)
                          </FormLabel>
                          <Input
                            fontSize="sm"
                            ms="4px"
                            type="text"
                            placeholder="Program applied VI"
                            size="sm"
                            isDisabled={!isCreate}
                            name="program_applied_vi"
                            {...register("program_applied_vi")}
                            isInvalid={errors.program_applied_vi}
                            errorBorderColor="red.300"
                          />
                          <Text color="red" fontSize={14} mb="10px" ml="5px">
                            {errors.program_applied_vi?.message}
                          </Text>
                        </FormControl>
                      </Flex>
                    </Grid>
                    {isCreate &&
                      listShow &&
                      listShow.map((item) => (
                        <ListShow
                          key={item.id}
                          register={register}
                          control={control}
                          item={item}
                          listShowChoose={listShowChoose}
                          isCheck={
                            listShowChoose &&
                            listShowChoose.includes(item.id.toString())
                          }
                          listVenue={listVenue}
                          voucher={voucher}
                        />
                      ))}
                    {!isCreate &&
                      listShowForEdit &&
                      listShowForEdit.map((item) => (
                        <ListShow
                          key={item.id}
                          register={register}
                          control={control}
                          item={item}
                          listShowChoose={listShowChoose}
                          isCheck={true}
                          disabledAndChecked
                          listVenue={item.venue}
                          listCheckedVenue={item.displayVenue}
                          voucher={voucher}
                          zoneDisplay={listZoneForEdit}
                        />
                      ))}
                    <Text color="red" ml="5px">
                      {errors.apiError && errors.apiError?.message}
                    </Text>
                    <Grid gap="16px" templateColumns="repeat(2, 1fr)" mb={3}>
                      <Flex direction="column">
                        <FormControl>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Sale Price
                          </FormLabel>
                          <Controller
                            control={control}
                            name="amount"
                            render={({ field }) => (
                              <NumericFormat
                                customInput={Input}
                                thousandSeparator=","
                                fontSize="sm"
                                ms="4px"
                                placeholder="Sale Price"
                                size="sm"
                                {...field}
                                disabled={!isCreate}
                              />
                            )}
                          />
                          <Text color="red" fontSize={14} mb="10px" ml="5px">
                            {errors.amount?.message}
                          </Text>
                        </FormControl>
                      </Flex>
                    </Grid>
                    <Flex direction="column">
                      <FormControl>
                        <Switch
                          disabled={!isCreate}
                          id="isChecked"
                          defaultChecked={true}
                          name="status"
                          {...register("status")}
                          size="sm"
                          ms="4px"
                        >
                          Active
                        </Switch>
                      </FormControl>
                    </Flex>
                  </ModalBody>
                  {isCreate && (
                    <ModalFooter pt="0">
                      <Button
                        colorScheme="blue"
                        mr={3}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Create
                      </Button>
                      <Button
                        onClick={() => {
                          onClose();
                          reset();
                        }}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  )}
                </ModalContent>
              </form>
            </Modal>
          </Flex>
        </CardHeader>
        <CardBody>
          <Card>
            <form
              id="issue-voucher-search-form"
              onSubmit={handleSubmitSearch(onSubmitSearch)}
            >
              <Flex
                direction="row"
                alignItems="center"
                mb={3}
                gap={4}
                flexWrap={"wrap"}
              >
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                    Voucher Template Name
                  </FormLabel>
                  <Input
                    _placeholder={{ color: "gray.400" }}
                    variant="main"
                    type="text"
                    placeholder="Voucher Template Name"
                    minW="80px"
                    width="200px"
                    size="sm"
                    fontSize="sm"
                    id="nameSearch"
                    name="nameSearch"
                    {...registerSearch("nameSearch")}
                    _focus={{ borderColor: "blue.500" }}
                  />
                </Box>

                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Show
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    defaultValue="0"
                    size="sm"
                    name="showSearch"
                    {...registerSearch("showSearch")}
                    width="200px"
                  >
                    <option value="">All Show</option>
                    {listShow.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Venue
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    size="sm"
                    id={"venueSearch"}
                    name="venueSearch"
                    {...registerSearch("venueSearch")}
                  >
                    <option value="">All Venue</option>
                    {venueList.map((i) => (
                      <option key={i.id} value={i.id}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </Box>

                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Status
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    defaultValue="1"
                    size="sm"
                    id="statusSearch"
                    name="statusSearch"
                    width="200px"
                    {...registerSearch("statusSearch")}
                  >
                    {VoucherIssueStatus.map((item, index) => (
                      <option key={index} value={item.Id}>
                        {item.Name}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Flex>
              <Flex justify="center" align="center" gap="8px" mt={3}>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<RxReset />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    onClick={() => {
                      resetSearch();
                      setValueSearch("unitSearch", null);
                    }}
                  >
                    Reset
                  </Button>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<BsSearch />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    type="submit"
                  >
                    Search
                  </Button>
                </Box>
              </Flex>
            </form>
          </Card>
          <SearchTable2
            tableData={newVouchersTemplate}
            columnsData={columnsDataVoucherTemplate}
            totalRow={totalRows.vouchersTemplate}
            listPermissions={[129]}
            isFetching={isFetching}
            actionsActive={["detail"]}
            showDetail={showDetail}
            loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTableTemplateVoucher;
