import { useRecoilState } from "recoil";
import { authAtom, rolesAtom, permissionsAtom } from "state/recoil-root";
import instance from "helpers/instance";
import { totalRow } from "state/roles-permissions";

const useRolePermissionActions = () => {
  const [auth, setAuth] = useRecoilState(authAtom);
  const [roles, setRoles] = useRecoilState(rolesAtom);
  const [totalRows, setTotalRows] = useRecoilState(totalRow);
  const [permissions, setPermissions] = useRecoilState(permissionsAtom);

  const headers = {
    Authorization: `Bearer ${auth?.access_token}`,
  };
  return {
    getAllRoles,
    getPermissions,
    getPermissionsByRole,
    updateRole,
    createRole,
    updateRoleStatus,
    getRoleDetail,
  };

  function getAllRoles(filter, page = 0, pageSize = 10) {
    const offset = page * pageSize;
    const params = {
      ...filter,
      offset,
      limit: pageSize,
    };
    return instance({
      url: "usermanagement/v1/roles",
      method: "GET",
      params,
      headers,
    })
      .then((res) => {
        if (res.status === 200) {
          const roles = res.data.data.filter(i => i.id !== 1)
          setTotalRows((preState) => ({
            ...preState,
            roles: res.data.meta.total || 0,
          }));
          setRoles(roles);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getPermissions() {
    return instance({
      url: "usermanagement/v1/permissions",
      method: "GET",
      headers,
    })
      .then((res) => {
        if (res.status === 200) {
          setPermissions(res.data.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function updateRoleStatus(data, userID) {
    return instance({
      url: `usermanagement/v1/roles/${userID}/status`,
      method: "PUT",
      headers,
      data: {
        status: data,
      },
    });
  }

  function getRoleDetail(roleID) {
    return instance({
      url: `usermanagement/v1/roles/${roleID}`,
      method: "GET",
      headers,
    });
  }

  function getPermissionsByRole(roleId) {
    // Not used
    return instance({
      url: `usermanagement/v1/roles/${roleId}/permissions`,
      method: "GET",
      headers,
    })
      .then((res) => {
        if (res.status === 200) {
          return res.data.data;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function createRole(data) {
    return instance({
      url: "usermanagement/v1/roles",
      method: "POST",
      data,
      headers,
    });
  }

  function updateRole(data, roleID) {
    return instance({
      url: `usermanagement/v1/roles/${roleID}`,
      method: "PUT",
      headers,
      data,
    });
  }
};
export { useRolePermissionActions };
