import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist({ key: "totalRow" });
const rolesAtom = atom({
  key: "roles",
  default: [],
});

const permissionsAtom = atom({
  key: "permissions",
  default: [],
});

const totalRow = atom({
  key: "totalRow",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export { rolesAtom, permissionsAtom, totalRow };
