import React from "react";
import { Flex, Text, Checkbox } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { zoneListAtom } from "state/show";
import ZoneItem from "components/ZoneItem/ZoneItem";

export default function ListVenue({
  isCheck,
  item,
  register,
  showItemChoose,
  voucher,
  control,
  disabledAndChecked,
  zoneDisplay,
}) {
  const zoneList = useRecoilValue(zoneListAtom);
  const checkedZones = disabledAndChecked ? zoneDisplay : zoneList;
  return (
    <>
      <Flex direction="row">
        <Checkbox
          disabled={disabledAndChecked && true}
          defaultChecked={disabledAndChecked && true}
          value={`${showItemChoose.id}_${item.id}`}
          {...register("listVenue")}
        />
        <Text style={{ fontWeight: "bold" }} ml={1}>
          {item.name}
        </Text>
      </Flex>
      {isCheck && (
        <Flex direction="column" ml="12px">
          {checkedZones.map((zone) =>
            disabledAndChecked ? (
              zone.show_id.toString() === showItemChoose.id &&
              zone.venue_id.toString() === item.id.toString() && (
                <Flex direction="row" mb={4} mt={3}>
                  <ZoneItem
                    key={zone.name}
                    item={zone}
                    register={register}
                    name="voucher"
                    showItemChoose={showItemChoose}
                    venueItemChoose={item}
                    control={control}
                    isChecked={
                      voucher &&
                      voucher.includes(
                        `${showItemChoose.id.toString()}_${item.id.toString()}_${zone.id.toString()}`
                      )
                    }
                    disabledAndChecked={disabledAndChecked}
                  />
                </Flex>
              )
            ) : (
              <Flex direction="row" mb={4} mt={3}>
                <ZoneItem
                  key={zone.name}
                  item={zone}
                  register={register}
                  name="voucher"
                  showItemChoose={showItemChoose}
                  venueItemChoose={item}
                  control={control}
                  isChecked={
                    voucher &&
                    voucher.includes(
                      `${showItemChoose.id.toString()}_${item.id.toString()}_${zone.id.toString()}`
                    )
                  }
                  disabledAndChecked={disabledAndChecked}
                />
              </Flex>
            )
          )}
        </Flex>
      )}
    </>
  );
}
