import { useEffect, useMemo, useState } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import {
  MdDelete,
  MdPublish,
  MdPrint,
  MdPassword,
  MdManageAccounts,
  MdAdd,
} from "react-icons/md";
import { useRecoilValue } from "recoil";
import { FaPencilAlt, FaCopy, FaClone } from "react-icons/fa";
import { TfiViewListAlt } from "react-icons/tfi";
import { TbListDetails } from "react-icons/tb";
import {
  Button,
  Flex,
  Icon,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { authAtom } from "state/auth";
import moment from "moment";
import { useHistory } from "react-router-dom";
import "./table.css";

const SearchTable2 = ({
  columnsData,
  tableData,
  totalRow,
  loadData,
  enablePagination = true,
  enableRowSelection = false,
  enableRowActions = true,
  listPermissions = [],
  actionsActive = [],
  isFetching = false,
  enableExpanding = false,
  refreshPagingDefault = false,
  edit,
  resetPassWord,
  showDetail,
  copy,
  manage,
  showDetailManage,
  create,
  showCode,
  publishRow,
  updateCheckbox,
  showColumnFilters = true,
  clearAllCheckBox = false,
  enableRowNumbers = false,
  conditionShowSelect = false,
  manualPagination = true,
  enableSorting = true,
  enableDensityToggle = false,
  enableHiding = false,
  enableColumnFilters = false,
  enableFullScreenToggle = false,
  manualSorting = true,
  deleteRow,
}) => {
  const history = useHistory();
  const auth = useRecoilValue(authAtom);
  const rowCount = useMemo(() => totalRow, [totalRow]);
  const columns = useMemo(() => columnsData, []);
  //data and fetching state
  const [isError, setIsError] = useState(false);
  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const currentPageSizeStore =
    localStorage && localStorage.getItem("currentPageSizeStore");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: currentPageSizeStore ? currentPageSizeStore : 10,
  });
  useEffect(() => {
    if (pagination?.pageSize) {
      localStorage.setItem("currentPageSizeStore", pagination.pageSize);
    }
  }, [pagination?.pageSize]);
  const textColor = useColorModeValue("blue.500", "white");
  function showBookingCode(bookingId) {
    history.push("/admin/booking/create/print-theater-pass/" + bookingId);
  }
  useEffect(() => {
    if (enableRowSelection) {
      updateCheckbox(rowSelection);
    }
  }, [rowSelection]);
  useEffect(() => {
    if (clearAllCheckBox) {
      setRowSelection({});
    }
  }, [clearAllCheckBox]);
  function isDisplayCloneIcon(bookingDetail) {
    let dateShowDetail = moment(bookingDetail?.show_schedule_detail?.date);
    let monthShow = moment().month() + 1;
    let stringMonth = monthShow > 10 ? "" + monthShow : "0" + monthShow;
    let stringDate =
      moment().date() > 10 ? "" + moment().date() : "0" + moment().date();
    let currentDate = moment(
      moment().year() + "-" + stringMonth + "-" + stringDate
    );
    return dateShowDetail.diff(currentDate) < 0 ? false : true;
  }
  useEffect(() => {
    if (refreshPagingDefault) {
      setPagination({
        pageIndex: 0,
        pageSize: 10,
      });
    }
  }, [refreshPagingDefault]);
  useEffect(() => {
    if (manualPagination) {
      const fetchData = async () => {
        try {
          const fetchDataOptions = {
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize,
            sort_type:
              sorting && sorting.length > 0
                ? sorting[0].desc
                  ? "desc"
                  : "asc"
                : "",
            sort_field: sorting && sorting.length > 0 ? sorting[0].id : "",
          };
          await loadData(fetchDataOptions);
        } catch (error) {
          setIsError(true);
          console.error(error);
          return;
        }
        setIsError(false);
      };
      fetchData();
    }
  }, [
    // columnFilters, //refetch when column filters change
    // globalFilter, //refetch when global filter changes
    pagination.pageIndex, //refetch when page index changes
    pagination.pageSize, //refetch when page size changes
    sorting, //refetch when sorting changes
  ]);
  const table = useMantineReactTable({
    columns,
    data: tableData,
    enableRowSelection: conditionShowSelect
      ? eval(conditionShowSelect)
      : enableRowSelection,
    getRowId: (row) => row.id,
    enableRowActions: enableRowActions,
    positionActionsColumn: "last",
    initialState: {
      showColumnFilters: showColumnFilters,
      density: "xs",
      expanded: true,
    },
    positionToolbarAlertBanner: "none",
    manualFiltering: false,
    manualPagination: manualPagination,
    manualSorting: manualSorting,
    enableColumnActions: false,
    enableRowNumbers: enableRowNumbers,
    enablePagination: enablePagination,
    enableGlobalFilter: false,
    enableColumnResizing: false,
    enableSorting: enableSorting,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    columnResizeMode: "onChange",
    enableExpanding: enableExpanding,
    enableExpandAll: true,
    enableFullScreenToggle: enableFullScreenToggle,
    enableColumnFilters: enableColumnFilters,
    enableHiding: enableHiding,
    enableDensityToggle: enableDensityToggle,
    filterFromLeafRows: true,
    displayColumnDefOptions: {
      // "mrt-row-actions": {
      //   size: 100, //make actions column wider
      // },
      "mrt-row-expand": {
        size: 60,
      },
      "mrt-row-select": {
        maxSize: 35,
      },
    },
    mantineSelectCheckboxProps: {
      size: "sm",
    },
    mantineTableProps: {
      fontSize: "xs",
      withColumnBorders: true,
    },
    mantinePaginationProps: {
      rowsPerPageOptions: ["10", "20", "30", "50", "100"],
    },
    mantineTableBodyCellProps: ({ row }) => {
      return {
        bg: `${
          row.depth === 0 && enableExpanding ? "rgb(229 231 235)" : "#fff"
        }`,
      };
    },
    state: {
      columnFilters,
      globalFilter,
      isLoading: isFetching,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      sorting,
      rowSelection,
    },
    renderRowActions: ({ row }) => (
      <Flex gap={2} opacity={`${row.depth === 0 ? 1 : 0}`}>
        {actionsActive.includes("resetPassword") && (
          <Tooltip hasArrow label="Reset Password" bg="gray.400">
            <Button
              className="icon-btn-no-shadow"
              p="0px"
              variant="no-effects"
              onClick={() => resetPassWord(row.original.id)}
            >
              <Flex color={textColor} cursor="pointer" align="center" p="0px">
                <Icon as={MdPassword} me="0px" />
              </Flex>
            </Button>
          </Tooltip>
        )}
        {actionsActive.includes("detail") && (
          <Tooltip hasArrow label="Detail" bg="gray.400">
            <Button
              className="icon-btn-no-shadow"
              p="0px"
              variant="no-effects"
              onClick={() => showDetail(row.original.id)}
            >
              <Flex color={textColor} cursor="pointer" align="center" p="0px">
                <Icon as={TbListDetails} me="0px" />
              </Flex>
            </Button>
          </Tooltip>
        )}
        {auth.permission.some((e) => listPermissions.includes(e)) &&
          actionsActive.includes("edit") && (
            <Tooltip hasArrow label="Edit" bg="gray.400">
              <Button
                className="icon-btn-no-shadow"
                p="0px"
                variant="no-effects"
                onClick={() => edit(row.original.id)}
              >
                <Flex color={textColor} cursor="pointer" align="center" p="0px">
                  <Icon as={FaPencilAlt} me="0px" />
                </Flex>
              </Button>
            </Tooltip>
          )}
        {auth.permission.some((e) => listPermissions.includes(e)) &&
          actionsActive.includes("copy") && (
            <Tooltip hasArrow label="Copy" bg="gray.400">
              <Button
                className="icon-btn-no-shadow"
                p="0px"
                variant="no-effects"
                onClick={() => copy(row.original.id)}
              >
                <Flex color={textColor} cursor="pointer" align="center" p="0px">
                  <Icon as={FaCopy} me="0px" />
                </Flex>
              </Button>
            </Tooltip>
          )}
        {auth.permission.some((e) => listPermissions.includes(e)) &&
          actionsActive.includes("showCode") && (
            <Tooltip hasArrow label="Show Code" bg="gray.400">
              <Button
                className="icon-btn-no-shadow"
                p="0px"
                variant="no-effects"
                onClick={() => showCode(row.original.id)}
              >
                <Flex color={textColor} cursor="pointer" align="center" p="0px">
                  <Icon as={TfiViewListAlt} me="0px" />
                </Flex>
              </Button>
            </Tooltip>
          )}
        {auth.permission.some((e) => listPermissions.includes(e)) &&
          actionsActive.includes("delete") && (
            <Tooltip hasArrow label="Delete" bg="gray.400">
              <Button
                className="icon-btn-no-shadow"
                p="0px"
                onClick={() => deleteRow(row.original.id)}
                variant="no-effects"
              >
                <Flex color={textColor} cursor="pointer" align="center" p="0px">
                  <Icon as={MdDelete} me="0px" />
                </Flex>
              </Button>
            </Tooltip>
          )}
        {auth.permission.some((e) => listPermissions.includes(e)) &&
          actionsActive.includes("threatPass") && (
            <Tooltip hasArrow label="Print Theater Pass" bg="gray.400">
              <Button
                className="icon-btn-no-shadow"
                p="0px"
                variant="no-effects"
                onClick={() => showBookingCode(row.original.id)}
              >
                <Flex color={textColor} cursor="pointer" align="center" p="0px">
                  <Icon as={TfiViewListAlt} me="0px" />
                </Flex>
              </Button>
            </Tooltip>
          )}
        {auth.permission.some((e) => listPermissions.includes(e)) &&
          actionsActive.includes("print") && (
            <Tooltip hasArrow label="Print" bg="gray.400">
              <Button
                className="icon-btn-no-shadow"
                p="0px"
                onClick={() => deleteRow(row.original.id)}
                variant="no-effects"
              >
                <Flex color={textColor} cursor="pointer" align="center" p="0px">
                  <Icon as={MdPrint} me="0px" />
                </Flex>
              </Button>
            </Tooltip>
          )}
        {auth.permission.some((e) => listPermissions.includes(e)) &&
          isDisplayCloneIcon(row.original) &&
          actionsActive.includes("clone") && (
            <Tooltip hasArrow label="Clone" bg="gray.400">
              <Button
                className="icon-btn-no-shadow"
                p="0px"
                onClick={() => {
                  cloneBooking(row.original.show_schedule_detail_id);
                }}
                variant="no-effects"
              >
                <Flex color={textColor} cursor="pointer" align="center" p="0px">
                  <Icon as={FaClone} me="0px" />
                </Flex>
              </Button>
            </Tooltip>
          )}
        {auth.permission.some((e) => listPermissions.includes(e)) &&
          row.original.invoice_status === 0 &&
          actionsActive.includes("publish") && (
            <Tooltip hasArrow label="Publish" bg="gray.400">
              <Button
                className="icon-btn-no-shadow"
                p="0px"
                onClick={() => publishRow(row.original)}
                variant="no-effects"
              >
                <Flex color={textColor} cursor="pointer" align="center" p="0px">
                  <Icon as={MdPublish} me="0px" />
                </Flex>
              </Button>
            </Tooltip>
          )}
        {actionsActive.includes("manage") && (
          <Tooltip hasArrow label="Assign seat quota for unit" bg="gray.400">
            <Button
              className="icon-btn-no-shadow"
              p="0px"
              variant="no-effects"
              onClick={() => manage(row.original)}
            >
              <Flex color={textColor} cursor="pointer" align="center" p="0px">
                <Icon as={MdManageAccounts} me="0px" />
              </Flex>
            </Button>
          </Tooltip>
        )}
        {actionsActive.includes("showDetailManage") && (
          <Tooltip hasArrow label="Show Detail Manage" bg="gray.400">
            <Button
              className="icon-btn-no-shadow"
              p="0px"
              variant="no-effects"
              onClick={() => showDetailManage(row.original)}
            >
              <Flex color={textColor} cursor="pointer" align="center" p="0px">
                <Icon as={MdManageAccounts} me="0px" />
              </Flex>
            </Button>
          </Tooltip>
        )}
        {actionsActive.includes("create") && (
          <Tooltip hasArrow label="Create new booking" bg="gray.400">
            <Button
              className="icon-btn-no-shadow"
              p="0px"
              variant="no-effects"
              onClick={() => create(row.original.id)}
            >
              <Flex color={textColor} cursor="pointer" align="center" p="0px">
                <Icon as={MdAdd} me="0px" />
              </Flex>
            </Button>
          </Tooltip>
        )}
      </Flex>
    ),
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Error loading data" }
      : undefined,
  });

  return (
    <div style={{ width: "100%" }}>
      <MantineReactTable table={table} />
    </div>
  );
};

export default SearchTable2;
