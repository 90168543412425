import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Switch,
  Stack,
  Grid,
  Tab,
  TabList,
  Tabs,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SearchTable2 from "components/Tables/SearchTable2";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { totalRow } from "state/roles-permissions";
import { AddIcon } from "@chakra-ui/icons";
import { checkForbidden } from "helpers/checkForbidden";
import { useCMSActions } from "hooks/useCMS";
import { columnsDataNewsGroup } from "variables/columnsData";
import Editor from "components/Editor/Editor2";
import { newGroupsAtom } from "state/cms";

function DataTableNewsGroup() {
  const textColor = useColorModeValue("gray.700", "white");
  const listNewsGroup = useRecoilValue(newGroupsAtom);
  const totalRows = useRecoilValue(totalRow);
  const cmsActions = useCMSActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCreate, setIsCreate] = useState(true);
  const [currentNewsGroupID, setCurrentNewsGroupID] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [newListNewsGroup, setNewListNewsGroup] = useState([]);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    control,
    watch,
  } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const toast = useToast();

  useEffect(() => {
    if (listNewsGroup) {
      const toolTypes = listNewsGroup.map((i) => ({
        ...i,
        filters: {},
        typeSwitch: "newsGroup",
      }));
      setNewListNewsGroup(toolTypes);
    }
  }, [listNewsGroup]);
  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    await cmsActions.getListNewsGroup(
      {
        name: "",
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
    setIsFetching(false);
  };
  async function onSubmit({
    name,
    description,
    slug,
    status,
    meta_description,
    meta_keywords,
    meta_title,
    og_type,
    og_url,
    og_title,
    og_description,
    og_image,
    isActive,
  }) {
    const listKeys = [
      "name",
      "description",
      "meta_description",
      "meta_keywords",
      "meta_title",
      "og_type",
      "og_url",
      "og_title",
      "og_description",
      "og_image",
    ];
    const languages = listKeys
      .map((i) => {
        if (watch(`${i}_vn`)) {
          return {
            code: "vi",
            locale_key: i,
            locale_value: watch(`${i}_vn`),
          };
        } else {
          return undefined;
        }
      })
      .filter(Boolean);
    const params = {
      name,
      description,
      slug,
      status,
      meta_description,
      meta_keywords,
      meta_title,
      og_type,
      og_url,
      og_title,
      og_description,
      og_image,
      status: isActive ? 1 : 2,
      languages: [],
    };
    params.languages = languages;
    try {
      let res;
      if (isCreate) {
        res = await cmsActions.createNewsGroup(params);
      } else {
        res = await cmsActions.updateNewsGroup(params, currentNewsGroupID);
      }

      if (res.status === 200) {
        onClose();
        reset();
        const message = isCreate
          ? "Create News Group success"
          : "Update News Group success";
        toast({
          description: message,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        await cmsActions.getListNewsGroup(
          {
            name: "",
          },
          0,
          localStorage && localStorage.getItem("currentPageSizeStore")
        );
      }
    } catch (err) {
      console.warn(err);
      toast({
        description: err.response,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  async function editNews(newsGroupID) {
    const newsGroupData = await cmsActions.getNewsDetailGroup(newsGroupID);
    if (newsGroupData) {
      const {
        name,
        description,
        slug,
        id,
        status,
        meta_description,
        meta_keywords,
        meta_title,
        og_type,
        og_url,
        og_title,
        og_description,
        og_image,
        languages,
      } = newsGroupData.data.data;
      setValue("name", name);
      setValue("description", description);
      setValue("slug", slug);
      setValue("meta_description", meta_description);
      setValue("meta_keywords", meta_keywords);
      setValue("meta_title", meta_title);
      setValue("og_type", og_type);
      setValue("og_url", og_url);
      setValue("og_title", og_title);
      setValue("og_description", og_description);
      setValue("og_image", og_image);
      setValue("isActive", status === 1 ? true : false);
      setCurrentNewsGroupID(id);
      if (languages) {
        languages.forEach((e) => {
          setValue(`${e.locale_key}_vn`, e.locale_value);
        });
      }
    }
    setIsCreate(false);
    onOpen();
  }

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex direction="column" display="block">
            <Text
              float="left"
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              mb="6px"
            >
              News Group Management
            </Text>
            <Stack float="right" direction="row" spacing={4}>
              {checkForbidden(42) && (
                <Button
                  size="sm"
                  onClick={() => {
                    onOpen();
                    setIsCreate(true);
                    setValue("description", "");
                    setValue("description_vn", "");
                  }}
                  leftIcon={<AddIcon />}
                  colorScheme="green"
                  variant="outline"
                >
                  Add News Group
                </Button>
              )}
            </Stack>
            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpen}
              onClose={() => {
                onClose();
                reset();
              }}
              size="6xl"
            >
              <ModalOverlay />
              <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                  <ModalHeader>
                    {isCreate ? "Add News Group" : "Edit News Group"}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <Tabs isFitted variant="enclosed">
                      <TabList>
                        <Tab
                          _focus={{ outline: "none" }}
                          _selected={{ color: "white", bg: "blue.500" }}
                          fontWeight={"bold"}
                          fontSize={"18px"}
                        >
                          English
                        </Tab>
                        <Tab
                          _focus={{ outline: "none" }}
                          _selected={{ color: "white", bg: "blue.500" }}
                          fontWeight={"bold"}
                          fontSize={"18px"}
                        >
                          Viet Nam
                        </Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl isRequired>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Name
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Name"
                                maxLength={250}
                                size="md"
                                name="name"
                                {...register("name")}
                              />
                            </FormControl>
                            <FormControl isRequired>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Slug
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Slug"
                                maxLength={250}
                                size="md"
                                name="slug"
                                {...register("slug")}
                                disabled={!isCreate}
                              />
                            </FormControl>
                          </Grid>
                          <FormControl mt={3}>
                            <FormLabel
                              color={textColor}
                              fontWeight="bold"
                              fontSize="xs"
                            >
                              Description
                            </FormLabel>
                            <Editor name="description" control={control} />
                          </FormControl>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Meta Description
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Meta Description"
                                maxLength={250}
                                size="md"
                                name="meta_description"
                                {...register("meta_description")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Meta KeyWord
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Meta KeyWord"
                                maxLength={250}
                                size="md"
                                name="meta_keywords"
                                {...register("meta_keywords")}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Meta Title
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Meta Title"
                                maxLength={250}
                                size="md"
                                name="meta_title"
                                {...register("meta_title")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG Type
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG Type"
                                maxLength={250}
                                size="md"
                                name="og_type"
                                {...register("og_type")}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG URL
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG URL"
                                maxLength={250}
                                size="md"
                                name="og_url"
                                {...register("og_url")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG Title
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG Title"
                                maxLength={250}
                                size="md"
                                name="og_title"
                                {...register("og_title")}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG Description
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG Description"
                                maxLength={250}
                                size="md"
                                name="og_description"
                                {...register("og_description")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG Image
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG Image"
                                maxLength={250}
                                size="md"
                                name="og_image"
                                {...register("og_image")}
                              />
                            </FormControl>
                          </Grid>
                        </TabPanel>
                        <TabPanel>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Name
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Name"
                                maxLength={250}
                                size="md"
                                name="name_vn"
                                {...register("name_vn")}
                              />
                            </FormControl>
                          </Grid>
                          <FormControl mt={3}>
                            <FormLabel
                              color={textColor}
                              fontWeight="bold"
                              fontSize="xs"
                            >
                              Description
                            </FormLabel>
                            <Editor name="description_vn" control={control} />
                          </FormControl>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Meta Description
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Meta Description"
                                maxLength={250}
                                size="md"
                                name="meta_description_vn"
                                {...register("meta_description_vn")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Meta KeyWord
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Meta KeyWord"
                                maxLength={250}
                                size="md"
                                name="meta_keywords_vn"
                                {...register("meta_keywords_vn")}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                Meta Title
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="Meta Title"
                                maxLength={250}
                                size="md"
                                name="meta_title_vn"
                                {...register("meta_title_vn")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG Type
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG Type"
                                maxLength={250}
                                size="md"
                                name="og_type_vn"
                                {...register("og_type_vn")}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG URL
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG URL"
                                maxLength={250}
                                size="md"
                                name="og_url_vn"
                                {...register("og_url_vn")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG Title
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG Title"
                                maxLength={250}
                                size="md"
                                name="og_title_vn"
                                {...register("og_title_vn")}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            templateColumns={{
                              sm: "1fr 1fr",
                              lg: "repeat(2, 1fr)",
                            }}
                            gap="30px"
                            mt={2}
                          >
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG Description
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG Description"
                                maxLength={250}
                                size="md"
                                name="og_description_vn"
                                {...register("og_description_vn")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="normal"
                              >
                                OG Image
                              </FormLabel>
                              <Input
                                fontSize="sm"
                                ms="4px"
                                type="text"
                                placeholder="OG Image"
                                maxLength={250}
                                size="md"
                                name="og_image_vn"
                                {...register("og_image_vn")}
                              />
                            </FormControl>
                          </Grid>
                        </TabPanel>
                      </TabPanels>
                    </Tabs>

                    <FormControl>
                      <Switch
                        id="isChecked"
                        defaultChecked={isCreate && true}
                        name="isActive"
                        {...register("isActive")}
                        size="sm"
                        ms="4px"
                      >
                        Active
                      </Switch>
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      {isCreate ? "Create" : "Update"}
                    </Button>
                    <Button
                      onClick={() => {
                        onClose();
                        reset();
                      }}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </form>
            </Modal>
          </Flex>
        </CardHeader>
        <CardBody>
          <SearchTable2
            tableData={newListNewsGroup}
            columnsData={columnsDataNewsGroup}
            totalRow={totalRows.newsGroup}
            edit={editNews}
            actionsActive={["edit"]}
            listPermissions={[43]}
            isFetching={isFetching}
            loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTableNewsGroup;
