import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Switch,
  Stack,
  Box,
  Select,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { totalRow } from "state/roles-permissions";
import { AddIcon } from "@chakra-ui/icons";
import { checkForbidden } from "helpers/checkForbidden";
import { BsSearch } from "react-icons/bs";
import { RxReset } from "react-icons/rx";
import { useBookingActions } from "hooks/useBookingActions";
import { columnsDataZone } from "variables/columnsData";
import { zoneListAtom } from "state/show";
import SearchTable2 from "components/Tables/SearchTable2";
import { STATUS } from "variables/general";

function DataTableZones() {
  const textColor = useColorModeValue("gray.700", "white");
  const listZones = useRecoilValue(zoneListAtom);
  const totalRows = useRecoilValue(totalRow);
  const bookingActions = useBookingActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCreate, setIsCreate] = useState(true);
  const [currentZoneID, setCurrentZoneID] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [newListZones, setNewListZones] = useState([]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    code: Yup.string().required("This field is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset, setValue } = useForm(
    formOptions
  );
  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    watch: watchSearch,
    reset: resetSearch,
  } = useForm();
  const { errors, isSubmitting } = formState;
  const toast = useToast();
  const nameSearch = watchSearch("nameSearch");
  const statusSearch = watchSearch("statusSearch");
  const codeSearch = watchSearch("codeSearch");
  useEffect(() => {
    if (listZones) {
      const newListZone = listZones.map((i) => ({
        ...i,
        typeSwitch: "zone",
        filters: {
          name: nameSearch,
          status: statusSearch ? statusSearch : 1,
          code: codeSearch,
        },
      }));
      setNewListZones(newListZone);
    }
  }, [listZones]);
  async function onSubmitSearch({ nameSearch, statusSearch, codeSearch }) {
    setIsFetching(true);
    await bookingActions.getListZones(
      { name: nameSearch, status: statusSearch, code: codeSearch },
      0,
      localStorage && localStorage.getItem("currentPageSizeStore")
        ? localStorage.getItem("currentPageSizeStore")
        : 10
    );
    setIsFetching(false);
  }
  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    await bookingActions.getListZones(
      {
        name: nameSearch,
        status: statusSearch ? statusSearch : 1,
        code: codeSearch,
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
    setIsFetching(false);
  };
  async function onSubmit({ name, code, isActive }) {
    const params = {
      name,
      code,
      status: isActive ? 1 : 2,
    };
    try {
      let res;
      if (isCreate) {
        res = await bookingActions.createZone(params);
      } else {
        res = await bookingActions.updateZone(params, currentZoneID);
      }

      if (res.status === 200) {
        onClose();
        reset();
        const message = isCreate
          ? "Create Zone success"
          : "Update Zone success";
        toast({
          description: message,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        await bookingActions.getListZones(
          {
            name: nameSearch,
            status: statusSearch ? statusSearch : 1,
            code: codeSearch,
          },
          0,
          localStorage && localStorage.getItem("currentPageSizeStore")
        );
      }
    } catch (err) {
      console.warn(err);
      toast({
        description: err?.response?.data?.meta?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  async function editZone(zoneID) {
    const zoneDetail = await bookingActions.getZoneDetail(zoneID);
    if (zoneDetail) {
      const { name, code, id, status } = zoneDetail.data.data;
      setValue("name", name);
      setValue("code", code);
      setValue("isActive", status === 1 ? true : false);
      setCurrentZoneID(id);
    }
    setIsCreate(false);
    onOpen();
  }

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex direction="column" display="block">
            <Text
              float="left"
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              mb="6px"
            >
              Zone Management
            </Text>
            <Stack float="right" direction="row" spacing={4}>
              {checkForbidden(123) && (
                <Button
                  size="sm"
                  onClick={() => {
                    onOpen();
                    setIsCreate(true);
                  }}
                  leftIcon={<AddIcon />}
                  colorScheme="green"
                  variant="outline"
                >
                  Add Zone
                </Button>
              )}
            </Stack>
            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpen}
              onClose={() => {
                onClose();
                reset();
              }}
              size="2xl"
            >
              <ModalOverlay />
              <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                  <ModalHeader>
                    {isCreate ? "Add New Zone" : "Edit Zone"}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <FormControl isRequired>
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Name Zone
                      </FormLabel>
                      <Input
                        fontSize="sm"
                        ms="4px"
                        type="text"
                        placeholder="Name"
                        maxLength={250}
                        mb={
                          errors.name?.message ? "5px" : { lg: "24px", sm: "0" }
                        }
                        size="md"
                        name="name"
                        {...register("name")}
                        isInvalid={errors.name}
                        errorBorderColor="red.300"
                      />
                      <Text color="red" fontSize={14} mb="10px" ml="5px">
                        {errors.name?.message}
                      </Text>
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Code
                      </FormLabel>
                      <Input
                        fontSize="sm"
                        ms="4px"
                        type="text"
                        placeholder="Code"
                        mb={
                          errors.code?.message ? "5px" : { lg: "24px", sm: "0" }
                        }
                        size="md"
                        name="code"
                        {...register("code")}
                        isInvalid={errors.code}
                        errorBorderColor="red.300"
                      />
                      <Text color="red" fontSize={14} mb="10px" ml="5px">
                        {errors.code?.message}
                      </Text>
                    </FormControl>
                    <FormControl>
                      <Switch
                        id="isChecked"
                        defaultChecked={isCreate && true}
                        name="isActive"
                        {...register("isActive")}
                        size="sm"
                        ms="4px"
                      >
                        Active
                      </Switch>
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      {isCreate ? "Create" : "Update"}
                    </Button>
                    <Button
                      onClick={() => {
                        onClose();
                        reset();
                      }}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </form>
            </Modal>
          </Flex>
        </CardHeader>
        <CardBody>
          <Card>
            <form
              id="user-search-form"
              onSubmit={handleSubmitSearch(onSubmitSearch)}
            >
              <Flex
                direction="row"
                alignItems="center"
                mb={3}
                gap={4}
                flexWrap={"wrap"}
              >
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                    Zone Name
                  </FormLabel>
                  <Input
                    _placeholder={{ color: "gray.400" }}
                    variant="main"
                    type="text"
                    placeholder="Name"
                    minW="80px"
                    maxW="185px"
                    size="sm"
                    fontSize="sm"
                    name="nameSearch"
                    {...registerSearch("nameSearch")}
                    _focus={{ borderColor: "blue.500" }}
                  />
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel ms="4px" fontSize="xs" fontWeight="semibold">
                    Code
                  </FormLabel>
                  <Input
                    _placeholder={{ color: "gray.400" }}
                    variant="main"
                    type="text"
                    placeholder="Code"
                    minW="80px"
                    maxW="185px"
                    size="sm"
                    fontSize="sm"
                    name="codeSearch"
                    {...registerSearch("codeSearch")}
                    _focus={{ borderColor: "blue.500" }}
                  />
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                    Status
                  </FormLabel>
                  <Select
                    ms="4px"
                    type="text"
                    defaultValue="1"
                    size="sm"
                    name="statusSearch"
                    {...registerSearch("statusSearch")}
                    width="100px"
                  >
                    {STATUS.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Flex>
              <Flex justify="center" align="center" gap="8px" mt={3}>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<RxReset />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    onClick={() => {
                      resetSearch();
                    }}
                  >
                    Reset
                  </Button>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<BsSearch />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    type="submit"
                  >
                    Search
                  </Button>
                </Box>
              </Flex>
            </form>
          </Card>
          <SearchTable2
            tableData={newListZones}
            columnsData={columnsDataZone}
            totalRow={totalRows.zones}
            edit={editZone}
            actionsActive={["edit"]}
            listPermissions={[124]}
            loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
            isFetching={isFetching}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTableZones;
