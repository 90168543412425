import { Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { showTextFloor } from "variables/columnsData";

function TablesTableRow({ row, isLast }) {
  const {
    channel,
    unit,
    program_apply,
    seat_number,
    floor,
    zone,
    official_price,
    amount,
  } = row;
  const textColor = useColorModeValue("gray.500", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Tr>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="sm" color={textColor}>
          {channel}
        </Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="sm" color={textColor}>
          {unit}
        </Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="sm" color={textColor}>
          {program_apply}
        </Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="sm" color={textColor}>
          {seat_number}
        </Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="sm" color={textColor}>
          {showTextFloor(floor)}
        </Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="sm" color={textColor}>
          {zone}
        </Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text
          fontSize="sm"
          color={textColor}
          fontWeight="bold"
          textAlign="center"
        >
          {official_price.toLocaleString("en-US")}
        </Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text
          fontSize="sm"
          color={textColor}
          fontWeight="bold"
          textAlign="center"
        >
          {amount.toLocaleString("en-US")}
        </Text>
      </Td>
    </Tr>
  );
}

export default TablesTableRow;
