import ReactDatePicker from "react-datepicker";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import "react-datepicker/dist/react-datepicker.css";
import React, { forwardRef } from "react";
import MaskedInput from "react-input-mask";

const icon = <CalendarIcon fontSize="sm" />;
const DatePickerInput = ({
  selectedDate,
  onChange,
  placeholder,
  disabled,
  showTimeSelect,
  showTimeSelectOnly,
  minDate,
  mask = "99/99/9999 99:99",
  ...props
}) => {
  const customDateInput = (
    { value, onClick, onChange, isdisabled, mask },
    ref
  ) => (
    <>
      <MaskedInput
        mask={mask}
        value={value}
        onChange={onChange}
        disabled={isdisabled}
      >
        {(inputProps) => (
          <Input
            {...inputProps}
            autoComplete="off"
            background="white"
            onClick={onClick}
            onChange={onChange}
            ref={ref}
            disabled={isdisabled}
          />
        )}
      </MaskedInput>
      {!isdisabled && (
        <InputRightElement color="gray.500" children={icon} onClick={onClick} />
      )}
    </>
  );
  customDateInput.displayName = "DateInput";
  const CustomInput = forwardRef(customDateInput);
  return (
    <InputGroup className="dark-theme">
      <ReactDatePicker
        selected={selectedDate}
        onChange={onChange}
        className="react-datapicker__input-text"
        customInput={<CustomInput isdisabled={disabled} mask={mask} />}
        dateFormat={
          !showTimeSelect
            ? "dd/MM/yyyy"
            : showTimeSelectOnly
            ? "HH:mm"
            : "dd/MM/yyyy HH:mm a"
        }
        {...props}
        showTimeSelect={showTimeSelect}
        timeIntervals={15}
        minDate={minDate || new Date()}
        showTimeSelectOnly={showTimeSelectOnly}
        yearDropdownItemNumber={5}
        showYearDropdown
      />
    </InputGroup>
  );
};

export default DatePickerInput;
