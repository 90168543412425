import { useRecoilState } from "recoil";
import {
  authAtom,
  unitsUserAtom,
  usersAtom,
  subAccountAtom,
} from "state/recoil-root";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import instance from "helpers/instance";
import { totalRow } from "state/roles-permissions";
import { toast } from "@chakra-ui/react";

const useUserActions = () => {
  const [auth, setAuth] = useRecoilState(authAtom);
  const [unitsUser, setUnitsUser] = useRecoilState(unitsUserAtom);
  const [users, setUsers] = useRecoilState(usersAtom);
  const [subAccounts, setSubAccounts] = useRecoilState(subAccountAtom);
  const [totalRows, setTotalRows] = useRecoilState(totalRow);
  const history = useHistory();
  const headers = {
    Authorization: `Bearer ${auth?.access_token}`,
  };
  return {
    login,
    logout,
    refreshToken,
    getAllUsers,
    createUser,
    getRolesByUser,
    updateUser,
    updateUserStatus,
    resetPassword,
    changePassword,
    getUserById,
    recoverPassword,
    forgotPassword,
    getUnitsOfUser,
    updateUserUnit,
    createUserUnit,
    updateUserUnitStatus,
    getListSubAccount,
    createSubAccount,
    getSubAccountById,
    updateSubAccount,
    updateSubAccountStatus,
  };

  function login(email, password) {
    return instance({
      url: "usermanagement/v1/auth/login",
      data: { email, password },
      method: "POST",
    })
      .then((res) => {
        if (res.status === 200) {
          const decoded = jwt_decode(res.data.data.access_token);
          getRolesByUser({
            Authorization: `Bearer ${res.data.data.access_token}`,
          }).then((response) => {
            if (response.status === 200) {
              const user = {
                refresh_token: res.data.data.refresh_token,
                access_token: res.data.data.access_token,
                roles: response.data.data[0],
                ...decoded,
              };
              localStorage.setItem("user", JSON.stringify(user));
              setAuth(user);
              const { from } = history.location.state || {
                from: { pathname: "/admin/setting/profile" },
              };
              history.push(from);
            }
          });
        }
      })
      .catch((err) => {
        toast({
          description: err,
          status: "error",
          duration: 4000,
          position: "top-right",
        });
      });
  }

  function logout() {
    localStorage.removeItem("user");
    setAuth(null);
    history.push("/auth/authentication/sign-in");
  }

   function refreshToken() {
    return instance({
      url: "usermanagement/v1/auth/refreshtoken",
      method: "POST",
      data: { refresh_token: auth.refresh_token },
    })
      .then((res) => {
        if (res.status === 200) {
          let currentAuth = JSON.parse(localStorage.getItem("user"));
          currentAuth["refresh_token"] = res.data.data.refresh_token;
          currentAuth["access_token"] = res.data.data.access_token;
          localStorage.setItem("user", JSON.stringify(currentAuth));
          setAuth(currentAuth);
        }
      })
      .catch((err) => {
        logout()
        return err
      });
  }

  function getAllUsers(filters, page = 0, pageSize = 10) {
    const offset = page * pageSize;
    const params = {
      offset,
      limit: pageSize,
      ...filters,
    };
    return instance({
      url: "usermanagement/v1/users",
      method: "GET",
      params,
      headers,
    })
      .then((res) => {
        if (res.status === 200) {
          setUsers(res.data.data);
          setTotalRows((preState) => ({
            ...preState,
            users: res.data.meta.total,
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function createUser(data) {
    return instance({
      url: "usermanagement/v1/users",
      method: "POST",
      data,
      headers,
    });
  }

  function getUserById(userID) {
    return instance({
      url: `usermanagement/v1/users/${userID}`,
      method: "GET",
      headers,
    });
  }

  function getRolesByUser(headers) {
    return instance({
      url: "usermanagement/v1/users/permissions",
      method: "GET",
      headers,
    });
  }

  function updateUser(data, userID) {
    return instance({
      url: `usermanagement/v1/users/${userID}`,
      method: "PUT",
      headers,
      data,
    });
  }

  function updateUserStatus(data, userID) {
    return instance({
      url: `usermanagement/v1/users/${userID}/status`,
      method: "PUT",
      headers,
      data: {
        status: data,
      },
    });
  }

  function resetPassword(id) {
    return instance({
      url: `usermanagement/v1/users/${id}/password`,
      method: "PUT",
      headers,
    });
  }

  function changePassword(data) {
    return instance({
      url: "usermanagement/v1/users/change-password",
      method: "POST",
      data,
      headers,
    });
  }

  function forgotPassword(data) {
    return instance({
      url: "usermanagement/v1/auth/forgot-password",
      method: "POST",
      data,
      headers,
    })
      .then((res) => {
        if (res.status === 200) {
          history.push("/auth/authentication/change-password");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function recoverPassword(data) {
    return instance({
      url: "usermanagement/v1/auth/recover-password",
      method: "POST",
      data,
      headers,
    });
  }

  function getUnitsOfUser(filter, userId, page = 0, pageSize = 10) {
    const offset = page * pageSize;
    const params = {
      ...filter,
      offset,
      limit: pageSize,
    };
    return instance({
      url: `usermanagement/v1/users/${userId}/person-in-charge`,
      method: "GET",
      params,
      headers,
    }).then((res) => {
      if (res.status === 200) {
        setUnitsUser(res.data.data || []);
        setTotalRows((preState) => ({
          ...preState,
          unitsUser: res.data.meta.total,
        }));
      }
    });
  }

  function updateUserUnit(data, userID) {
    return instance({
      url: `usermanagement/v1/users/${userID}/person-in-charge`,
      method: "PUT",
      headers,
      data,
    });
  }

  function createUserUnit(data, userID) {
    return instance({
      url: `usermanagement/v1/users/${userID}/person-in-charge`,
      method: "POST",
      data,
      headers,
    });
  }

  function updateUserUnitStatus(status, userId, rest) {
    const filter = {
      status,
    };
    if (Array.isArray(rest.unit_id)) {
      filter.ids = rest.unit_id;
    } else {
      filter.ids = [userId];
    }
    return instance({
      url: `usermanagement/v1/users/${rest.userID}/person-in-charge/status`,
      method: "PUT",
      data: filter,
      headers,
    });
  }

  function getListSubAccount(filters, page = 0, pageSize = 10) {
    const offset = page * pageSize;
    const params = {
      offset,
      limit: pageSize,
      ...filters,
    };
    return instance({
      url: "management/v1/sub-accounts",
      method: "GET",
      params,
      headers,
    })
      .then((res) => {
        if (res.status === 200) {
          setSubAccounts(res.data.data);
          setTotalRows((preState) => ({
            ...preState,
            subAccounts: res.data.meta.total,
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function createSubAccount(data) {
    return instance({
      url: "management/v1/sub-accounts",
      method: "POST",
      data,
      headers,
    });
  }

  function getSubAccountById(subAccountID) {
    return instance({
      url: `management/v1/sub-accounts/${subAccountID}`,
      method: "GET",
      headers,
    });
  }

  function updateSubAccount(data, subAccountID) {
    return instance({
      url: `management/v1/sub-accounts/${subAccountID}`,
      method: "PUT",
      headers,
      data,
    });
  }

  function updateSubAccountStatus(data, subAccountID) {
    return instance({
      url: `management/v1/sub-accounts/${subAccountID}/status`,
      method: "PUT",
      headers,
      data: {
        status: data,
      },
    });
  }
};

export { useUserActions };
