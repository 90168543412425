import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useState, useEffect } from "react";
import { IoDocumentText } from "react-icons/io5";
import { Element, Link } from "react-scroll";
import { authAtom } from "state/recoil-root";
import { useRecoilValue } from "recoil";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUserActions } from "hooks/useUserActions";
import * as Yup from "yup";
import moment from "moment";

function Settings() {
  const toast = useToast();
  const validationSchema = Yup.object().shape({
    old_password: Yup.string().required("This field is required"),
    new_password: Yup.string().required("This field is required"),
    confirm_password: Yup.string()
      .required("This field is required")
      .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
  });
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("user")));
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, setError, formState, watch } = useForm(
    formOptions
  );
  const { errors, isSubmitting } = formState;
  const [userDetail, setUserDetail] = useState({});
  const textColor = useColorModeValue("gray.400", "white");
  const user = useRecoilValue(authAtom);
  const userActions = useUserActions();
  useEffect(() => {
    userActions.getUserById(user.user_id).then((res) => {
      if (res.status === 200) {
        setUserDetail(res.data.data);
      }
    });
  }, []);
  function onSubmit({ old_password, new_password, confirm_password }) {
    try {
      userActions
        .changePassword({
          old_password,
          new_password,
          confirm_password,
        })
        .then((res) => {
          if (res.status === 200) {
            toast({
              description: "Change Password Success",
              status: "success",
              duration: 4000,
              isClosable: true,
              position: "top-right",
            });
            userActions.logout();
          }
        })
        .catch((err) => {
          toast({
            description: "Change Password Not Success",
            status: "error",
            duration: 4000,
            isClosable: true,
            position: "top-right",
          });
        });
    } catch (error) {
      setError("apiError", { message: "Something wrong" });
    }
  }

  return (
    <Flex direction="column" pt={{ sm: "120px", lg: "75px" }}>
      <Stack
        direction="column"
        spacing="24px"
        align={{ lg: "center" }}
        justify={{ lg: "flex-end" }}
        w="100%"
      >
        <Card
          w={{ sm: "100%", lg: "70%" }}
          alignSelf="center"
          justifySelf="flex-end"
        >
          <Element id="info" name="info">
            <CardHeader mb="40px">
              <Text color="gray.800" fontSize="lg" fontWeight="bold">
                Account overview
              </Text>
            </CardHeader>
            <CardBody px="5px">
              <Flex direction="column">
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} me="10px">
                    E-mail:{" "}
                  </Text>
                  <Text fontSize="md" color="gray.800" fontWeight="400">
                    {userDetail.email}
                  </Text>
                </Flex>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} me="10px">
                    Full name:{" "}
                  </Text>
                  <Text fontSize="md" color="gray.800" fontWeight="400">
                    {userDetail.fullname}
                  </Text>
                </Flex>
                {userDetail.password_change_at && (
                  <Flex align="center" mb="18px">
                    <Text fontSize="md" color={textColor} me="10px">
                      Password changed:{" "}
                    </Text>
                    <Text fontSize="md" color="gray.800" fontWeight="400">
                      {moment(userDetail.password_change_at).format(
                        "DD/MM/YYYY"
                      )}
                    </Text>
                  </Flex>
                )}
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} me="10px">
                    Phone:{" "}
                  </Text>
                  <Text fontSize="md" color="gray.800" fontWeight="400">
                    {userDetail.phone}
                  </Text>
                </Flex>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} me="10px">
                    Roles:{" "}
                  </Text>
                  <Text fontSize="md" color="gray.800" fontWeight="400">
                    {userDetail.role &&
                      userDetail?.role.reduce((accumulator, user) => {
                        if (accumulator === "") {
                          return user.name;
                        } else {
                          return `${accumulator}, ${user.name}`;
                        }
                      }, "")}
                  </Text>
                </Flex>
              </Flex>
            </CardBody>
          </Element>
        </Card>
        <Card
          w={{ sm: "100%", lg: "70%" }}
          alignSelf="center"
          justifySelf="flex-end"
        >
          <Element id="change-password" name="change-password">
            <form onSubmit={handleSubmit(onSubmit)}>
              <CardHeader mb="40px">
                <Text color="gray.800" fontSize="lg" fontWeight="semibold">
                  Change Password
                </Text>
              </CardHeader>
              <CardBody>
                <Stack direction="column" spacing="20px" w="100%">
                  <FormControl>
                    <FormLabel fontWeight="semibold" fontSize="xs" mb="10px">
                      Current Password
                    </FormLabel>
                    <Input
                      placeholder="Current Password"
                      fontSize="xs"
                      type="password"
                      id="old_password"
                      name="old_password"
                      {...register("old_password")}
                    />
                    <Text color="red" fontSize={14} mb="10px" ml="5px">
                      {errors.old_password?.message}
                    </Text>

                    <FormLabel fontWeight="semibold" fontSize="xs" mb="10px">
                      New Password
                    </FormLabel>
                    <Input
                      placeholder="New Password"
                      fontSize="xs"
                      name="new_password"
                      type="password"
                      id="new_password"
                      {...register("new_password")}
                    />
                    <Text color="red" fontSize={14} mb="10px" ml="5px">
                      {errors.new_password?.message}
                    </Text>

                    <FormLabel fontWeight="semibold" fontSize="xs" mb="10px">
                      Confirm New Password
                    </FormLabel>
                    <Input
                      placeholder="Confirm New Password"
                      fontSize="xs"
                      name="confirm_password"
                      type="password"
                      id="confirm_password"
                      {...register("confirm_password")}
                    />
                    <Text color="red" fontSize={14} mb="10px" ml="5px">
                      {errors.confirm_password?.message}
                    </Text>

                    <Button
                      variant="primary"
                      w="150px"
                      h="35px"
                      isLoading={isSubmitting}
                      type="submit"
                    >
                      CHANGE PASSWORD
                    </Button>
                  </FormControl>
                </Stack>
              </CardBody>
            </form>
          </Element>
        </Card>
      </Stack>
    </Flex>
  );
}

export default Settings;
