import { useRecoilState } from "recoil";
import { authAtom, ratePlansAtom } from "state/recoil-root";
import instance from "helpers/instance";
import { totalRow } from "state/roles-permissions";
import { ratePlansConfigAtom, ratePlansUnitAtom } from "state/ratePlans";

const useRatePlanActions = () => {
  const [auth, setAuth] = useRecoilState(authAtom);
  const [ratePlans, setRatePlans] = useRecoilState(ratePlansAtom);
  const [totalRows, setTotalRows] = useRecoilState(totalRow);
  const [ratePlanConfigs, setRatePlanConfigs] = useRecoilState(
    ratePlansConfigAtom
  );
  const [ratePlanUnits, setRatePlanUnits] = useRecoilState(ratePlansUnitAtom);

  const headers = {
    Authorization: `Bearer ${auth?.access_token}`,
  };
  return {
    getAllRatePlans,
    exportRatePlanList,
    updateRatePlan,
    createRatePlan,
    updateRatePlanStatus,
    getRatePlanConfigs,
    createRatePlanConfig,
    updateRatePlanConfig,
    updateUnitsStatus,
    getRatePlanCombo,
    getRatePlanBestPrice,
    getListRatePlanConfigs,
    updateRatePlanConfigStatus,
    getListRatePlansByShowTime,
    getListComboByShowTime,
    createRatePlanShowTime,
    createRatePlanUnits,
    uploadImageRatePlanConfig,
    getRatePlanDetail,
    updateRatePlanShowTime,
    updateRatePlanUnits,
    getListRatePlansUnits,
    deleteRatePlanUnits,
    getListSeatingPlanDetail,
    updateInactiveRateplanUnits,
    updateActiveRateplanUnits,
  };

  function getAllRatePlans(filter, page = 0, pageSize = 10) {
    const offset = page * pageSize;
    const params = {
      ...filter,
      offset,
      limit: pageSize,
    };
    return instance({
      url: "rateplan/v1/rateplans",
      method: "GET",
      params,
      headers,
    })
      .then((res) => {
        if (res.status === 200) {
          setRatePlans(res.data.data);
          setTotalRows((preState) => ({
            ...preState,
            ratePlans: res.data.meta.total || 0,
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function exportRatePlanList(filter, page = 0, pageSize = 10) {
    const offset = page * pageSize;
    const params = {
      ...filter,
      offset,
      limit: pageSize,
    };
    headers.responseType = "blob";
    return instance({
      url: "rateplan/v1/rateplans/export",
      method: "GET",
      params,
      responseType: "blob",
      headers,
    });
  }

  function createRatePlan(data) {
    return instance({
      url: "rateplan/v1/rateplans",
      method: "POST",
      data,
      headers,
    });
  }

  function updateRatePlan(data, rateplanId) {
    return instance({
      url: `rateplan/v1/rateplans/${rateplanId}`,
      method: "PUT",
      headers,
      data,
    })
      .then((res) => {
        if (res.status === 200) {
          return res.data.data;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function updateRatePlanStatus(data, rateplanID) {
    return instance({
      url: `rateplan/v1/rateplans/${rateplanID}/status`,
      method: "PUT",
      headers,
      data: {
        status: data,
      },
    });
  }

  function updateUnitsStatus(status, unitID, rateplanID) {
    return instance({
      url: `rateplan/v1/rateplans/${rateplanID.rateplan_id}/units/${unitID}/status`,
      method: "PUT",
      headers,
      data: {
        status,
      },
    });
  }

  function getRatePlanConfigs(
    params = { offset: 0, limit: 20, sort_type: "desc", sort_field: "id" }
  ) {
    return instance({
      url: "rateplan/v1/rateplanconfigs",
      method: "GET",
      params,
      headers,
    });
  }

  function updateRatePlanConfig(data, ratePlanID) {
    return instance({
      url: `rateplan/v1/rateplans/${ratePlanID}/configs`,
      method: "PUT",
      headers,
      data,
    });
  }
  function createRatePlanConfig(data, ratePlanID) {
    return instance({
      url: `rateplan/v1/rateplans/${ratePlanID}/configs`,
      method: "POST",
      data,
      headers,
    });
  }
  function createRatePlanShowTime(data, ratePlanID) {
    return instance({
      url: `rateplan/v1/rateplans/${ratePlanID}/showtimes`,
      method: "POST",
      data,
      headers,
    });
  }
  function createRatePlanUnits(data, ratePlanID) {
    return instance({
      url: `rateplan/v1/rateplans/${ratePlanID}/units`,
      method: "POST",
      data,
      headers,
    });
  }
  function getRatePlanCombo(params) {
    return instance({
      url: "rateplan/v1/rateplans/combo-by-showtime",
      method: "GET",
      params,
      headers,
    });
  }
  function getRatePlanBestPrice(params) {
    return instance({
      url: "rateplan/v1/rateplans/best-price",
      method: "GET",
      params,
      headers,
    });
  }
  function getRatePlanDetail(ratePlanID) {
    return instance({
      url: `rateplan/v1/rateplans/${ratePlanID}`,
      method: "GET",
      headers,
    });
  }
  function getListRatePlanConfigs(filter, ratePlanID, page = 0, pageSize = 10) {
    const offset = page * pageSize;
    const params = {
      offset,
      limit: pageSize,
      sort_field: "rateplan_id",
      sort_type: "desc",
      ...filter,
    };
    return instance({
      url: `rateplan/v1/rateplans/${ratePlanID}/configs`,
      method: "GET",
      params,
      headers,
    }).then((res) => {
      if (res.status === 200) {
        setRatePlanConfigs(res.data.data);
        setTotalRows((preState) => ({
          ...preState,
          ratePlanConfigs: res.data.meta.total || 0,
        }));
      }
    });
  }
  function updateRatePlanConfigStatus(data, ratePlanID, ratePlanConfigID) {
    return instance({
      url: `rateplan/v1/rateplans/${ratePlanID}/configs/${ratePlanConfigID}/status`,
      method: "PUT",
      data,
      headers,
    });
  }
  function updateRatePlanShowTime(data, ratePlanID) {
    return instance({
      url: `rateplan/v1/rateplans/${ratePlanID}/showtimes`,
      method: "PUT",
      data,
      headers,
    });
  }
  function updateRatePlanUnits(data, ratePlanID) {
    return instance({
      url: `rateplan/v1/rateplans/${ratePlanID}/units`,
      method: "PUT",
      data,
      headers,
    });
  }
  function getListRatePlansByShowTime(params) {
    return instance({
      url: `rateplan/v1/rateplans/rateplans-by-showtime`,
      method: "GET",
      params,
      headers,
    });
  }
  function getListRatePlansUnits(filter, rateplanID, page = 0, pageSize = 10) {
    const offset = page * pageSize;
    const params = {
      offset,
      limit: pageSize,
      sort_field: "rateplan_id",
      sort_type: "desc",
      ...filter,
    };

    return instance({
      url: `rateplan/v1/rateplans/${rateplanID}/units`,
      method: "GET",
      params,
      headers,
    }).then((res) => {
      if (res.status === 200) {
        setRatePlanUnits(res.data.data);
        setTotalRows((preState) => ({
          ...preState,
          ratePlanUnits: res.data.meta.total || 0,
        }));
      }
    });
  }
  function getListSeatingPlanDetail(params) {
    return instance({
      url: "management/v1/seatingplan/detail",
      method: "GET",
      params,
      headers,
    });
  }
  function getListComboByShowTime(params) {
    return instance({
      url: `rateplan/v1/rateplans/combo-by-showtime`,
      method: "GET",
      params,
      headers,
    });
  }
  function uploadImageRatePlanConfig(data) {
    return instance({
      url: `media/v1/files`,
      method: "POST",
      data,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${auth?.access_token}`,
      },
    });
  }
  function deleteRatePlanUnits(data, ratePlanID) {
    return instance({
      url: `rateplan/v1/rateplans/${ratePlanID}/units?${data}`,
      method: "DELETE",
      headers,
    });
  }
  function updateInactiveRateplanUnits(data, ratePlanID) {
    return instance({
      url: `rateplan/v1/rateplans/${ratePlanID}/inactive-units`,
      method: "PUT",
      data,
      headers,
    });
  }
  function updateActiveRateplanUnits(data, ratePlanID) {
    return instance({
      url: `rateplan/v1/rateplans/${ratePlanID}/active-units`,
      method: "PUT",
      data,
      headers,
    });
  }
};
export { useRatePlanActions };
