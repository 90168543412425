import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  useToast,
  Select,
  Stack,
  Box,
  Grid,
  Input,
  Checkbox,
  CheckboxGroup,
  Textarea,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SearchTable2 from "components/Tables/SearchTable2";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  columnsDataShowScheduleDetail,
  columnsDataShowScheduleDateTime,
} from "variables/columnsData";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { totalRow } from "state/roles-permissions";
import { AddIcon } from "@chakra-ui/icons";
import { checkForbidden } from "helpers/checkForbidden";
import { useBookingActions } from "hooks/useBookingActions";
import {
  showsScheduleListAtom,
  showTimeListAtom,
  selectedShowAtom,
} from "state/show";
import { BsSearch } from "react-icons/bs";
import { RxReset } from "react-icons/rx";
import { useHistory, useParams } from "react-router-dom";
import DatePickerInput from "components/CustomDateInput/CustomDateInput";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
function DataTableShowScheduleDetail() {
  const { schedule_id } = useParams();

  const chakraStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      p: 0,
      w: "20px",
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: "200px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 20,
    }),
  };

  const history = useHistory();
  const textColor = useColorModeValue("gray.700", "white");
  const listShowSchedule = useRecoilValue(showsScheduleListAtom);
  const totalRows = useRecoilValue(totalRow);
  const showTimeList = useRecoilValue(showTimeListAtom);
  const bookingActions = useBookingActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isFetching, setIsFetching] = useState(false);
  const [newShowScheduleDetail, setNewShowScheduleDetail] = useState([]);
  const [currentShow, setCurrentShow] = useState({});
  const [dateTimeSelected, setDateTimeSelected] = useState([]);
  const [currentShowtime, setCurrentShowtime] = useState();
  const [selectedShow, setSelectedShow] = useRecoilState(selectedShowAtom);
  const [isCreate, setIsCreate] = useState(true);
  const validationSchema = Yup.object().shape({
    // name: Yup.string().required("This field is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    control,
    watch,
    setError,
    clearErrors,
  } = useForm(formOptions);
  const {
    handleSubmit: handleSubmitSearch,
    watch: watchSearch,
    reset: resetSearch,
    control: controlSearch,
  } = useForm();
  const { errors, isSubmitting } = formState;
  const toast = useToast();
  const statusSearch = watchSearch("statusSearch");

  useEffect(() => {
    bookingActions.getListShowTime({
      status: 1,
    });
    if (localStorage.getItem("currentShow")) {
      setCurrentShow(JSON.parse(localStorage.getItem("currentShow")));
    }
  }, []);
  useEffect(() => {
    if (listShowSchedule) {
      const newListShowSchedule = listShowSchedule.map((i) => ({
        ...i,
        typeSwitch: "showsScheduleDetail",
      }));
      setNewShowScheduleDetail(newListShowSchedule);
    }
  }, [listShowSchedule]);
  async function onSubmitSearch({
    nameSearch,
    statusSearch,
    from_date,
    to_date,
  }) {
    setIsFetching(true);
    await bookingActions.getListShowScheduleDetail(
      {
        name: nameSearch,
        status: statusSearch,
        from_date: from_date
          ? new Date(from_date).toISOString()
          : new Date().toISOString(),
        to_date: to_date
          ? new Date(to_date).toISOString()
          : from_date
            ? new Date(from_date).toISOString()
            : new Date().toISOString(),
        status: 1,
        show_schedule_id: schedule_id,
      },
      0,
      localStorage && localStorage.getItem("currentPageSizeStore")
        ? localStorage.getItem("currentPageSizeStore")
        : 10
    );
    setIsFetching(false);
  }
  const recallApi = async (fetchDataOptions) => {
    setIsFetching(true);
    await bookingActions.getListShowScheduleDetail(
      {
        status: statusSearch ? statusSearch : 1,
        show_schedule_id: schedule_id,
        sort_type: fetchDataOptions.sort_type,
        sort_field: fetchDataOptions.sort_field,
      },
      fetchDataOptions.pageIndex,
      fetchDataOptions.pageSize
    );
    setIsFetching(false);
  };

  async function onSubmit({ status, note }) {
    if (dateTimeSelected.length < 1 && isCreate) {
      setError("dateTimeSelected");
      return;
    }
    clearErrors("dateTimeSelected");
    let res;
    try {
      if (!isCreate) {
        const params = {
          show_times: {
            status: parseInt(status),
            note,
          },
        };
        res = await bookingActions.updateShowTimeSchedule(
          params,
          schedule_id,
          currentShowtime.id
        );
      } else {
        const show_times = dateTimeSelected.map((i) => ({
          date: moment(i.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
          time: i.times,
          status: parseInt(status),
        }));
        const params = {
          show_times,
        };
        res = await bookingActions.createShowTimeSchedule(params, schedule_id);
      }
      if (res.status === 200) {
        onClose();
        reset();
        const message = isCreate
          ? "Create Show time success"
          : "Update Show time success";
        toast({
          description: message,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        setDateTimeSelected([]);
      }
      await bookingActions.getListShowScheduleDetail(
        { show_schedule_id: schedule_id },
        0,
        10
      );
    } catch (err) {
      toast({
        description: err?.response?.data?.meta?.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  const editShowTime = async (item) => {
    const {
      data: { data: dataShow },
    } = await bookingActions.getShowScheduleDetail(item);
    if (dataShow) {
      setValue("isActive", dataShow.status === 1 ? true : false);
      setValue("note", dataShow.note);
      setValue("status", dataShow.status);
      setCurrentShowtime(dataShow);
      setIsCreate(false);
      onOpen();
    }
  };

  const createBooking = (id) => {
    bookingActions.selectShow({ id: parseInt(id) }).then((res) => {
      if (res.status === 200) {
        setSelectedShow(res.data.data);
        history.push("/admin/booking/create/information");
      }
    });
  };
  const confirmAdd = async () => {
    const selectedTimeIds = watch("time");
    const selectedTimes = showTimeList
      .filter((time) => selectedTimeIds.includes(time.id.toString()))
      .map((time) => time.name);

    const selectedDateTime = selectedTimes.map((time, index) => ({
      date: moment(watch("date")).format("DD/MM/YYYY"),
      times: time,
    }));
    let fail = [];
    let pass = [];
    await Promise.all(
      selectedDateTime.map(async (i) => {
        const params = {
          show_time: {
            date: moment(watch("date")).format("YYYY-MM-DD"),
            time: i.times,
          },
        };
        const res = await bookingActions.validateShowTime(schedule_id, params);
        if (res !== 200) {
          fail.push(i);
        } else {
          pass.push(i);
        }
      })
    );
    const cloneDateTimeSelected = [...dateTimeSelected];
    if (pass.length > 0) {
      addUniqueObjectsToArray(cloneDateTimeSelected, pass);
      setDateTimeSelected(cloneDateTimeSelected);
      setValue(
        "date",
        new Date(watch("date")).setDate(new Date(watch("date")).getDate() + 1)
      );
      clearErrors("dateTimeError");
    } else {
      setError("dateTimeError");
    }
  };
  const deleteDateTime = (item) => {
    const newItem = dateTimeSelected.filter((i) => i.id !== item);
    setDateTimeSelected(newItem);
  };

  function haveSameValue(obj1, obj2) {
    // Check if both objects have the same number of properties
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }
    // Check if all properties and values match
    for (const key in obj1) {
      if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      } else {
        return false; // One object is missing a property from the other
      }
    }

    return true;
  }

  function addUniqueObjectsToArray(originalArray, arrayToAdd) {
    for (const obj1 of arrayToAdd) {
      if (!originalArray.some((obj2) => haveSameValue(obj1, obj2))) {
        originalArray.push(obj1);
      }
    }
  }

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex direction="column" display="block">
            <Text
              float="left"
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              mb="6px"
            >
              Show Schedule Detail Management
            </Text>
            <Stack float="right" direction="row" spacing={4}>
              {checkForbidden(92) && (
                <Button
                  size="sm"
                  onClick={() => {
                    onOpen();
                    setIsCreate(true);
                  }}
                  leftIcon={<AddIcon />}
                  colorScheme="green"
                  variant="outline"
                >
                  Add Show Time
                </Button>
              )}
            </Stack>
            <Modal
              closeOnOverlayClick={false}
              isOpen={isOpen}
              onClose={() => {
                onClose();
                reset();
                setCurrentShowtime(undefined);
                setDateTimeSelected([]);
              }}
              size="2xl"
            >
              <ModalOverlay />
              <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                  <ModalHeader>
                    {!isCreate ? "Update Show Time" : "Add New Show Time"}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <Box direction="row" alignItems="center" mb={1}>
                      <span style={{ fontWeight: "bold" }}>The Show:</span>{" "}
                      {currentShow?.showName}
                    </Box>
                    <Box direction="row" alignItems="center" mb={1}>
                      <span style={{ fontWeight: "bold" }}>Venue:</span>{" "}
                      {currentShow?.venueName}
                    </Box>
                    <Box direction="row" alignItems="center">
                      <span style={{ fontWeight: "bold" }}>Seating Plan:</span>{" "}
                      {currentShow?.seatingPlanName}
                    </Box>
                    <Flex
                      flexDirection={!currentShowtime ? "row" : "column"}
                      alignItems={!currentShowtime ? "center" : "flex-start"}
                      gap={!currentShowtime ? 10 : 2}
                      mt={3}
                    >
                      {!currentShowtime ? (
                        <FormControl maxW="400px" width="auto" isRequired>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Date
                          </FormLabel>

                          <Controller
                            name="date"
                            control={control}
                            render={({ field }) => (
                              <ReactDatePicker
                                customInput={<Input />}
                                className="booking-date-range-search"
                                onChange={(value) => field.onChange(value)}
                                selected={field?.value}
                                dateFormat="dd/MM/yyyy"
                                showYearDropdown
                                minDate={new Date()}
                              />
                            )}
                          />
                        </FormControl>
                      ) : (
                        <Box direction="row" alignItems="center" mb={1}>
                          <span style={{ fontWeight: "bold" }}>Date: </span>
                          {moment(currentShowtime.date).format("DD/MM/YYYY")}
                        </Box>
                      )}
                      {!currentShowtime ? (
                        <FormControl maxW="400px">
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Time
                          </FormLabel>

                          <Controller
                            name="time"
                            control={control}
                            render={({ field }) => (
                              <CheckboxGroup {...field}>
                                <Flex flexWrap={"wrap"} gap={2}>
                                  {showTimeList.map((i) => (
                                    <Checkbox
                                      value={i.id.toString()}
                                      key={i.id}
                                    >
                                      {i.name}
                                    </Checkbox>
                                  ))}
                                </Flex>
                              </CheckboxGroup>
                            )}
                          />
                        </FormControl>
                      ) : (
                        <Box direction="row" alignItems="center" mb={1}>
                          <span style={{ fontWeight: "bold" }}>Time:</span>{" "}
                          {currentShowtime.time}
                        </Box>
                      )}
                    </Flex>
                    {!currentShowtime && (
                      <Box mt={2} mb={2}>
                        <Button
                          variant="primary"
                          h="33px"
                          onClick={() => confirmAdd()}
                        >
                          Save and Add more
                        </Button>
                      </Box>
                    )}
                    <Text color="red" ml="5px">
                      {errors.dateTimeSelected && "Please add date time"}
                    </Text>
                    <Text color="red" ml="5px">
                      {errors.dateTimeError && "Please check show time select"}
                    </Text>
                    {currentShowtime && (
                      <FormControl>
                        <FormLabel ms="4px" fontSize="md" fontWeight="bold">
                          Note
                        </FormLabel>
                        <Textarea
                          rows={4}
                          {...register("note")}
                          maxLength={1000}
                          name="note"
                          mb={
                            errors.note?.message
                              ? "5px"
                              : { lg: "24px", sm: "0" }
                          }
                        />
                        <Text color="red" ml="5px">
                          {errors.apiError && errors.apiError?.message}
                        </Text>
                      </FormControl>
                    )}
                    {!currentShowtime && dateTimeSelected?.length > 0 && (
                      <SearchTable2
                        tableData={dateTimeSelected}
                        columnsData={columnsDataShowScheduleDateTime}
                        totalRow={dateTimeSelected.length}
                        actionsActive={["delete"]}
                        deleteRow={(i) => deleteDateTime(i)}
                        manualPagination={false}
                        listPermissions={[43]}
                        manualSorting={false}
                      />
                    )}
                    <FormControl>
                      <Box direction="row" alignItems="center" display="block">
                        <FormLabel fontWeight="semibold" fontSize="xs" ms="4px">
                          Status
                        </FormLabel>
                        <Select
                          ms="4px"
                          type="text"
                          defaultValue="1"
                          size="sm"
                          name="status"
                          {...register("status")}
                          width="100px"
                        >
                          <option value="1">Active</option>
                          <option value="2">InActive</option>
                          <option value="3">Cancel</option>
                        </Select>
                      </Box>
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      {!isCreate ? "Update" : "Create"}
                    </Button>
                    <Button
                      onClick={() => {
                        onClose();
                        reset();
                        setCurrentShowtime(undefined);
                        setDateTimeSelected([]);
                      }}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </form>
            </Modal>
          </Flex>
        </CardHeader>
        <CardBody>
          <Card pt={0}>
            <form
              id="user-search-form"
              onSubmit={handleSubmitSearch(onSubmitSearch)}
            >
              <Flex direction="column" mb={3} gap={4} flexWrap={"wrap"}>
                <Box direction="row" alignItems="center">
                  The Show: {currentShow?.showName}
                </Box>
                <Box direction="row" alignItems="center">
                  Venue: {currentShow?.venueName}
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <Grid maxW="40%" gap="8px" templateColumns="repeat(2, 1fr)">
                    <Flex direction="column">
                      <FormControl>
                        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                          Start Date
                        </FormLabel>
                        <Controller
                          name="from_date"
                          control={controlSearch}
                          render={({ field }) => (
                            <DatePickerInput
                              onChange={(value) => field.onChange(value)}
                              selected={watchSearch("from_date")}
                              startDate={watchSearch("from_date")}
                              selectsStart
                              endDate={watchSearch("to_date")}
                              mask="99/99/9999"
                              minDate={new Date("01/01/2020")}
                            />
                          )}
                        />
                        <Text color="red" fontSize={14} mb="10px" ml="5px">
                          {errors.from_date?.message}
                        </Text>
                      </FormControl>
                    </Flex>
                    <Flex direction="column">
                      <FormControl>
                        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                          End Date
                        </FormLabel>
                        <Controller
                          name="to_date"
                          control={controlSearch}
                          render={({ field }) => (
                            <DatePickerInput
                              onChange={(value) => field.onChange(value)}
                              selected={watchSearch("to_date")}
                              selectsEnd
                              startDate={watchSearch("from_date")}
                              endDate={watchSearch("to_date")}
                              minDate={watchSearch("from_date")}
                              mask="99/99/9999"
                            />
                          )}
                        />
                        <Text color="red" fontSize={14} mb="10px" ml="5px">
                          {errors.to_date?.message}
                        </Text>
                      </FormControl>
                    </Flex>
                  </Grid>
                </Box>
              </Flex>
              <Flex justify="center" align="center" gap="8px">
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<RxReset />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    onClick={() => {
                      resetSearch();
                    }}
                  >
                    Reset
                  </Button>
                </Box>
                <Box direction="row" alignItems="center" display="block">
                  <Button
                    leftIcon={<BsSearch />}
                    variant="primary"
                    w="88px"
                    h="33px"
                    type="submit"
                  >
                    Search
                  </Button>
                </Box>
              </Flex>
            </form>
          </Card>
          <SearchTable2
            tableData={newShowScheduleDetail}
            columnsData={columnsDataShowScheduleDetail}
            totalRow={totalRows.showsScheduleDetail}
            actionsActive={["edit", "manage", "create"]}
            edit={editShowTime}
            manage={(item) => {
              console.log(item, "item");
              localStorage.setItem(
                "seatingPlanAssign",
                JSON.stringify({
                  desktop_maps: item.show_schedule.seatingplan.desktop_maps,
                  legend_desktop_maps:
                    item.show_schedule.seatingplan.legend_desktop_maps,
                  venue_id: item.show_schedule.venue_id,
                  show_id: item.show_schedule.show.id,
                  seatingplan_id: item.show_schedule.seatingplan_id,
                  show_name: item.show_schedule.show.name,
                  show_time: item.time,
                  show_date: item.date,
                })
              );
              history.push(`/admin/management/schedule-show/assign/${item.id}`);
            }}
            create={createBooking}
            listPermissions={[92]}
            isFetching={isFetching}
            loadData={(fetchDataOptions) => recallApi(fetchDataOptions)}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTableShowScheduleDetail;
