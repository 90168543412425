import { atom } from "recoil";

const ratePlansAtom = atom({
  key: "ratePlans",
  default: [],
});
const ratePlansConfigAtom = atom({
  key: "ratePlansConfig",
  default: [],
});

const ratePlansUnitAtom = atom({
  key: "ratePlansUnit",
  default: [],
});

export { ratePlansAtom, ratePlansConfigAtom, ratePlansUnitAtom };
